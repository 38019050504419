import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './BuyerDashboardNew.css';
import './BuyerDashboard.css';
import './DashboardGlobal.css';
import close from '../../assets/design/pricing/closing-x.png';
import { getCurrentUser, refresh } from '../../services/auth.service';
import BuyerDemos from './BuyerDemos';
import BuyerDemosAvailable from './BuyerDemos_Available';
import BuyerDemosUpcoming from './BuyerDemos_Upcoming';
import BuyerDemosIncomplete from './BuyerDemos_Incomplete';
import BuyerDemosCompleted from './BuyerDemos_Completed';
import BuyerDemosRescheduled from './BuyerDemos_Rescheduled';
import BuyerDemosPending from './BuyerDemos_Pending';
import {
  getBuyerDashboardEarnings,
  resendEmailVerification,
  withdraw,
  isVerified,
  getUserEmail,
  getUserProfile,
  refreshToken,
  getUserCompany,
  getBuyerUnreadMessages,
  getActiveCharity,
  withdrawToCharity,
  addUserEventTracking,
  getSiteNotification,
  updateUserProfile,
  updateUser,
  getBuyerDeclinedReasons,
  getDemoCheckIn,
  addDemoCheckIn,
  getBuyerWorkspace,
  getBuyerObjections,
  getUpcomingEvents
} from '../../utils/api';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';
import { Link } from 'react-router-dom';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaRegCheckCircle, FaRegComments, FaEnvelopeOpen, FaTimes, FaExclamationTriangle, FaCheck, FaBuilding, FaYoutube, FaGooglePlay, FaAppleAlt, FaHeadset, FaExclamationCircle, FaBars, FaCheckCircle, FaRegMoneyBillAlt, FaCoins, FaHandHoldingUsd, FaRegHourglass } from "react-icons/fa";
import ScrollToTop from '../../components/layout/ScrollToTop';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import imgcoins from '../../assets/rewards/lpcoins.jpg';
import iconCoin from '../../assets/rewards/lp-coin.png';

const BuyerDashboardNew = ({refreshPoints}) => {
  let user = getCurrentUser();
  const [demoStatus, setDemoStatus] = useState('available');
  const [pendingStatus, setPendingStatus] = useState('requested');
  const [buyerEarnings, setBuyerEarnings] = useState({});
  const [showWithdrawRequested, setShowWithdrawRequested] = useState(false);
  const [showWithdrawConfirmed, setShowWithdrawConfirmed] = useState(false);
  const [incompleteDemosCount, setIncompleteDemosCount] = useState(0);
  const [upcomingDemosCount, setUpcomingDemosCount] = useState(0);
  const [availableDemosCount, setAvailableDemosCount] = useState(0);
  const [rescheduledDemosCount, setRescheduledDemosCount] = useState(0);
  const [completedDemosCount, setCompletedDemosCount] = useState(0);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(true);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [hasPhone, setHasPhone] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [onboardingLoaded, setOnboardingLoaded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [hasCompany, setHasCompany] = useState(false);
  const [companyLoaded, setCompanyLoaded] = useState(false);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [charity, setCharity] = useState(null);
  const [showCharity, setShowCharity] = useState(false);
  const [showCharityConfirm, setShowCharityConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [workEmailVerified, setWorkEmailVerified] = useState(false);
  const [showUnread, setShowUnread] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showPh, setShowPh] = useState(false);
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);
  const [demosToday, setDemosToday] = useState([]);
  const [showCheckIn, setShowCheckIn] = useState(false);
  const [showCheckInYes, setShowCheckInYes] = useState(false);
  const [showCheckInNo, setShowCheckInNo] = useState(false);
  const [showCheckInNoConfirm, setShowCheckInNoConfirm] = useState(false);
  const [declinedReasons, setDeclineReasons] = useState([]);
  const [checkin, setCheckin] = useState(null);
  const [checkinLoaded, setCheckinLoaded] = useState(false);
  const [declineReasonId, setDeclineReasonId] = useState(null);
  const [selectedCharity, setSelectedCharity] = useState(0);
  const [showDonation, setShowDonation] = useState(false);
  const [submittingWithdraw, setSubmittingWithdraw] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [company, setCompany] = useState(null);
  const [buyerDeclinedReasons, setBuyerDeclinedReasons] = useState([]);
  const [buyerObjections, setBuyerObjections] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [eventsLoaded, setEventsLoaded] = useState(false);
  const currentDate = new Date();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchBuyerObjections() {
      let data = await getBuyerObjections();
      if (data.success) {
        setBuyerObjections(data.data);
      }
    }

    async function loadUpcomingEvents(){
      let res = await getUpcomingEvents();

      if (res.success){
        setUpcomingEvents(res.data);
        setEventsLoaded(true);
      }
    }

    async function fetchBuyerDeclinedReasons() {
      let data = await getBuyerDeclinedReasons();
      if (data.success) {
        setBuyerDeclinedReasons(data.data);
      }
    }
    
    fetchBuyerObjections();
    fetchBuyerDeclinedReasons();
    loadUpcomingEvents();
    //setLoaded(true);
  }, []);

  async function checkSiteNotification(){
    let res = await getSiteNotification('buyer_welcome');

    if (res.success){
      if (res.data){
        setShowWelcomeBanner(false);
      }

      else{
        setShowWelcomeBanner(true);
      }
    }

    else if (res.status === 403){
      await refresh();
      let res = await getSiteNotification('buyer_welcome');

      if (res.success){
        if (res.data){
          setShowWelcomeBanner(false);
        }

        else{
          setShowWelcomeBanner(true);
        }
      }
    }
  }

  async function fetchBuyerDeclinedReasons() {
    let data = await getBuyerDeclinedReasons();
    if (data.success) {
      setBuyerDeclinedReasons(data.data);
    }
  }

  async function loadWorkspace(){
    let res = await getBuyerWorkspace();

    if (res.success){
      setWorkspace(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getBuyerWorkspace();

      if (res.success){
        setWorkspace(res.data);
      }
    }
  }

  useEffect(async () => {
    if (onboardingLoaded === true && showOnboarding === false){
      await checkSiteNotification();
    }
  }, [onboardingLoaded, showOnboarding]);
  
  useEffect(async () => {
    async function loadUnreadMessages() {
      let res = await getBuyerUnreadMessages();

      if (res.success) {
        if (res.data.length > 0) {
          setHasUnreadMessages(true);
          setUnreadMessages(res.data);
          setShowUnread(true);
        }

        setMessagesLoaded(true);
      } else if (res.status === 403) {
          await refresh();

          let res = await getBuyerUnreadMessages();

          if (res.success) {
            if (res.data.length > 0) {
              setHasUnreadMessages(true);
              setUnreadMessages(res.data);
              setShowUnread(true);
            }

            setMessagesLoaded(true);
          }
      } else {
        setMessagesLoaded(true);
      }
    }

    async function checkTimezone(timezone){
      if (timezone == null || timezone == undefined || timezone == ''){
        let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (l_timezone){
          let res = await updateUser({timezone: l_timezone});

          if (res.status === 403){
            await refresh();
            let res = await updateUser({timezone: l_timezone});
          }
        }
      }
    }

    async function loadUserProfile() {
      let res = await getUserProfile();

      if (res.success) {
        setProfileLoaded(true);
        setUserProfile(res.data);
        setWorkEmailVerified(res.data.work_email_verified);
        await checkTimezone(res.data.tz);

        if (!res.data.phone_number || res.data.phone_number === '') {
          setHasPhone(false);
        } else {
          setHasPhone(true);
        }
      } else if (res.status === 403) {
          await refresh();
          let res = await getUserProfile();

          if (res.success) {
            setProfileLoaded(true);
            setUserProfile(res.data);
            setWorkEmailVerified(res.data.work_email_verified);
            await checkTimezone(res.data.tz);

            if (!res.data.phone_number || res.data.phone_number === '') {
              setHasPhone(false);
            } else {
              setHasPhone(true);
            }
          }

          else{
            navigate('/login');
          }
      }
    }

    async function loadUserCompany() {
      let res = await getUserCompany();

      if (res.success) {
        setCompanyLoaded(true);
        setHasCompany(true);
        setCompany(res.data);
      } else if (res.status === 403) {

          await refresh();
          let res = await getUserCompany();

          if (res.success) {
            setCompanyLoaded(true);
            setHasCompany(true);
            setCompany(res.data);
          } else {
            setCompanyLoaded(true);
            setHasCompany(false);
          }
      } else {
        setCompanyLoaded(true);
        setHasCompany(false);
      }
    }

    async function loadDeclineReasons(){
      let res = await getBuyerDeclinedReasons();

      if (res.success){
        setDeclineReasons(res.data);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getBuyerDeclinedReasons();

        if (res.success){
          setDeclineReasons(res.data);
        }
      }
    }

    async function loadCheckIn(){
      let res = await getDemoCheckIn();

      if (res.success){
        setCheckinLoaded(true);

        if (res.data.length > 0){
          setCheckin(res.data[0]);
          setShowCheckIn(true);
        }
      }

      else if (res.status === 403){
        await refresh();

        let res = await getDemoCheckIn();

        if (res.success){
          setCheckinLoaded(true);

          if (res.data.length > 0){
            setCheckin(res.data[0]);
            setShowCheckIn(true);
          }
        }
      }
    }

    await loadUnreadMessages();
    //await loadIntercom();
    await loadUserProfile();
    await loadUserCompany();
    //await loadCharity();
    await loadDeclineReasons();
    await loadCheckIn();
    await loadWorkspace();
  }, []);

  useEffect(() => {
    if (companyLoaded === true && profileLoaded === true && userProfile) {
      setOnboardingLoaded(true);

      if (hasCompany === false) {
        setShowOnboarding(true);
      } else {
        setShowOnboarding(false);
      }
    }
  }, [companyLoaded, profileLoaded, hasCompany, userProfile]);

  useEffect(async () => {
    await getDashboardEarnings();

    async function checkIsVerified() {
      let res = await isVerified();

      if (res.success) {
        if (res.data === true) setShowVerificationError(false);
        else setShowVerificationError(true);
        setLoaded(true);
      }
    }

    checkIsVerified();
  }, []);

  async function getDashboardEarnings() {
    let data = await getBuyerDashboardEarnings();
    if (data.success) {
      if (data.data.availablebalance > 0) {
        setShowWithdraw(true);
      } else {
        setShowWithdraw(false);
      }
      setBuyerEarnings(data.data);
    } else if (data.status === 403) {
      let _refresh = await refreshToken();

      if (_refresh.success) {
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let data = await getBuyerDashboardEarnings();
        if (data.success) {
          if (data.data.availablebalance > 0) {
            setShowWithdraw(true);
          } else {
            setShowWithdraw(false);
          }
          setBuyerEarnings(data.data);
        }
      }
    }
  }

  async function withdrawClickHandle() {
    if (buyerEarnings.availablebalance > 0) {
      setSubmittingWithdraw(true);
      let donation = 0;

      if (showDonation){
        if (selectedCharity === 1){
          donation = 75 * .10;
        }

        else if (selectedCharity === 2){
          donation = 75 * .15;
        }

        else if (selectedCharity === 3){
          donation = 75 * .20;
        }
      }

      let res = await withdraw(donation);

      if (res.success) {
        setShowWithdrawRequested(false);
        setShowWithdrawConfirmed(true);
        setShowWithdraw(false);
        setSubmittingWithdraw(false);
      }

      else if (res.status === 403){
        await refresh();

        let res = await withdraw(donation);

        if (res.success) {
          setShowWithdrawRequested(false);
          setShowWithdrawConfirmed(true);
          setShowWithdraw(false);
          setSubmittingWithdraw(false);
        }
      }
    }
  }

  async function donateClickHandle() {
    if (buyerEarnings.availablebalance > 0) {
      setSubmitting(true);
      let charity_campaign_id = charity.charity_campaign_id;
      let res = await withdrawToCharity(charity_campaign_id);

      if (res.success) {
        setSubmitting(false);
        setShowCharity(false);
        setShowCharityConfirm(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await withdrawToCharity(charity_campaign_id);

        if (res.success) {
          setSubmitting(false);
          setShowCharity(false);
          setShowCharityConfirm(true);
        }
      }
    }
  }

  function closingClick() {
    setShowWithdrawRequested(false);
    setShowWithdrawConfirmed(false);
  }

  function setStatus(status) {
    setDemoStatus(status);
  }

  async function handleVerificationClick() {
    let _res = await resendEmailVerification();

    if (_res.success) {
      setVerificationSuccess(true);
    }
  }

  async function phClick(){
    let res = await addUserEventTracking('Buyer Product Hunt Click');
  }

  async function handleCheckInYes(){
    let res = await addDemoCheckIn(checkin.demo_id, 'Yes', null);

    if (res.success){
      setShowCheckIn(false);
      setShowCheckInYes(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await addDemoCheckIn(checkin.demo_id, 'Yes', null);

      if (res.success){
        setShowCheckIn(false);
        setShowCheckInYes(true);
      }
    }
  }

  async function handleCheckInNo(){
    setShowCheckIn(false);
    setShowCheckInNo(true);
  }

  async function handleSubmitCheckInNo(){
    let res = await addDemoCheckIn(checkin.demo_id, 'No', declineReasonId);

    if (res.success){
      setShowCheckIn(false);
      setShowCheckInNoConfirm(true);
      //setShowCheckInNo(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await addDemoCheckIn(checkin.demo_id, 'No', declineReasonId);

      if (res.success){
        setShowCheckIn(false);
        setShowCheckInNoConfirm(true);
       // setShowCheckInNo(false);
      }
    }
  }

  async function handleCheckInMaybe(){
    let res = await addDemoCheckIn(checkin.demo_id, 'Later', null);

    if (res.success){
      setShowCheckIn(false);
      //setShowCheckInYes(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await addDemoCheckIn(checkin.demo_id, 'Later', null);

      if (res.success){
        setShowCheckIn(false);
       // setShowCheckInYes(true);
      }
    }
  }

  async function setSelectedDeclineReason(item){
    setDeclineReasonId(item);
  }

  async function handleCheckInNoBack(){
    setShowCheckInNo(false);
    setShowCheckIn(true);
  }

  return (
    <div className='buyerdashboardnew'>
      <ScrollToTop />

      <Helmet>
        <title>LeadrPro - My Dashboard</title>
      </Helmet>
      {(onboardingLoaded === false && (
        <Spinner />
      ))}

      <div className=''>
        
        {showOnboarding === false && onboardingLoaded === true && (
          <>
          {showPh && (
              <FadeIn delay={500} duration={500}>
                <div className='account__product-hunt'>
                  <strong>LeadrPro will be launching our mobile app on Product Hunt on <a href='https://www.producthunt.com/products/leadrpro' target='_blank' onClick={() => phClick()}>August 16, 2023</a>. Please support us and the LeadrPro community and get notified once we go live by going to our teaser page <a href='https://www.producthunt.com/products/leadrpro' target='_blank' onClick={() => phClick()}>here</a>.</strong>
                </div>
              </FadeIn>
            )}

            {/* <button className="btn mr-30 sellerdashboard__btn-links" onClick={() => setShowWelcomeBanner(true)}><FaBars /> Quick Links</button> */}

            <h1 className='buyerdashboard__h1'>Dashboard</h1>

            {showVerificationError && loaded === true && onboardingLoaded && showOnboarding === false && (
            <>
              <div className='dashboard__verification-card'>
                {verificationSuccess === false && (
                  <div style={{display: 'flex', alignItems: 'center'}} onClick={() => handleVerificationClick()}>
                    <span>
                    <span style={{verticalAlign: "middle"}}><FaExclamationTriangle /></span>
                      <span style={{ verticalAlign: 'middle', marginLeft: '10px' }}>
                        Verify your email address to unlock all features.
                      </span>
                    </span>{' '}

                    <span className='ml-20' style={{display: 'flex', background: 'rgba(255, 191, 26, 0.1)', alignItems: 'center', padding: '2px 10px', borderRadius: '5px', border: 'solid 2px orange'}}>
                      <img src={iconCoin} alt='' style={{height: '20px'}} /> +1
                    </span>

                    <span
                      className='link ml-20'>
                      Resend verification email
                    </span>
                  </div>
                )}

                {verificationSuccess === true && (
                  <>
                    Please check your inbox and follow instructions in
                    verification email.
                  </>
                )}
              </div>
          </>
        )}

        <div className='buyerdashboard__header-earn'>
          <div>
            <h2>Learn and earn rewards with LeadrPro</h2>

            <p className='buyerdashboard__header-earn-copy'>
            Top companies want to meet with you! Make money participating in paid meetings and find the B2B solutions of tomorrow. Participating is fun and easy—give your feedback on your meeting experience and we'll send you more reward opportunities!
            </p>
          </div>

          <div>
            <img src={imgcoins} alt='' />
          </div>
        </div>

            <div className='dashboard__notifications'>
            {hasUnreadMessages && messagesLoaded && onboardingLoaded && (
              <>
                <div className='dashboard__notification-card'>
                  <span><FaRegEnvelopeOpen /> You have unread messages in your inbox.</span>

                  <span>
                    <Link
                      to={'/dashboard/inbox'}
                      className='link'>
                      Go to your messages
                    </Link>
                  </span>
                </div>
              </>
            )}

              {loaded == true && onboardingLoaded && showOnboarding == false && workspace && parseInt(workspace.upcoming_demos) > 0 && (
                <div className='dashboard__notification-card' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => {
                  setStatus('upcoming')
                }}>
              <span className='dashboard__notification-card-count'>
                <FaRegCalendarAlt /> 
                <span className='ml-10'>{workspace.upcoming_demos}</span>
              </span>

              <span className='ml-20' style={{marginLeft: '20px', borderLeft: 'solid 1px #ccc', paddingLeft: '20px'}}>
                {`Upcoming demo${parseInt(workspace.upcoming_demos) > 1 ? 's': ''}`}
              </span>
            </div>
              )}

            {loaded == true && onboardingLoaded && showOnboarding == false && workspace && workspace.needs_feedback != '0' && (
                <div className='dashboard__notification-card' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => {
                  setStatus('incomplete')
                }}>
              <span className='dashboard__notification-card-count'>
                <FaRegEdit /> <span className='ml-10'>{workspace.needs_feedback}</span>
                </span>

              <span style={{marginLeft: '20px', borderLeft: 'solid 1px #ccc', paddingLeft: '20px'}}>
                {`Completed (Need feedback)`}
              </span>
            </div>
              )}

            {/* {loaded == true && onboardingLoaded && showOnboarding == false && workspace && workspace.completed_demos != '0' && (
                <div className='dashboard__notification-card' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => {
                  setStatus('completed')
                }}>
              <span className='dashboard__notification-card-count'>{workspace.completed_demos_with_feedback}</span>

              <span style={{marginLeft: '20px', borderLeft: 'solid 1px #ccc', paddingLeft: '20px'}}>
                {`Completed`}
              </span>
            </div>
              )} */}

            {loaded == true && onboardingLoaded && showOnboarding == false && workspace && workspace.rescheduled_demos != '0' && (
            <>
              <div className='dashboard__notification-card' onClick={() => setStatus('rescheduled')}>
                <span>
                  <span><FaRegCalendarTimes /></span><span> You have a reschedule request pending!</span>
                </span>
                {' '}<span className='link'>Submit new times</span>
              </div>
            </>
          )}
        </div>

            {/* {buyerEarnings.availablebalance > 0 && showWithdraw === true &&
          showOnboarding === false && (
            <FadeIn delay={150} duration={200}>
              <div className='buyerdashboard__withdraw'>
                <span>
                  <span style={{verticalAlign: "middle"}}>
                    <FaRegCheckCircle />
                  </span>
                  <span style={{ verticalAlign: 'middle', marginLeft: '20px' }}>
                    Looks like you have funds available for withdraw ($
                    {buyerEarnings.availablebalance}).
                  </span>
                </span>

                {buyerEarnings.hasPayoutMethod === true && (
                  <div>
                    <span
                      className='btn btn--primary btn--withdraw'
                      onClick={() => setShowWithdrawRequested(true)}>
                      Request withdraw
                    </span>
                  </div>
                )}

                {buyerEarnings.hasPayoutMethod === false && workEmailVerified && (
                  <span>
                    <a href='/account/payout' className='btn btn--withdraw'>
                      Add Payout Method
                    </a>
                  </span>
                )}

                {buyerEarnings.hasPayoutMethod === false && workEmailVerified == false && (
                  <span>
                    <a href='/account/work-email' className='link'>
                      Must verify work email
                    </a>
                  </span>
                )}
              </div>
            </FadeIn>
          )} */}
  
          {demosToday.length > 0 && (
          <div>
              <div><strong>Demos Scheduled For Today</strong></div>

              {demosToday.map(item => (
              <div className='dashboarddemo__today'>
                <div className='dashboarddemo__today-logo'>
                  <div className='dashboarddemo__company-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>

                  <div style={{marginLeft: '10px'}}>
                    Demo with {item.company_name}
                  </div>
                </div>

                {item.demo_date && (
                  <div className='dashboarddemo__today-date'>
                    <strong>Scheduled for:</strong>
                    <div style={{ marginBottom: '10px' }}>
                        {new Date(
                          item.demo_date
                        ).toLocaleDateString()}{' '}
                        at{' '}
                        {new Date(
                          item.demo_date
                        ).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}

                        <div>{moment.tz.guess()}</div>
                      </div>
                  </div>
                )}

                <div className='dashboarddemo__today-status'>
                  {item.demo_status === 'Pending' && (
                    <a target='_blank' href={`https://meetings.leadrpro.com/demo?refid=${item.reference_id}`}>
                    <span className='btn btn--primary'><span style={{verticalAlign: 'middle', display: 'inline-block'}}><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512" style={{fill: 'white'}}><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg></span>{' '}<span style={{verticalAlign: 'middle', display: 'inline-block'}}>Join</span></span>
                    </a>
                  )}

                  {item.demo_status === 'Completed' && (
                    <>
                    <span className='dashboarddemo__status-completed'>Completed</span>
                    <br/>
                    <a target='_blank' className='link' href={`https://meetings.leadrpro.com/demo?refid=${item.reference_id}`}>
                    <span>Need to rejoin?</span>
                    </a>
                    </>
                  )}
                </div>
              </div>
              ))}
            </div>
          )}

          <div>
            <div>
            <div className='dashboard__demo-options-container' style={{marginTop: '20px'}}>
                <div className={demoStatus === 'available'
                      ? 'dashboard__demo-option--active'
                      : 'dashboard__demo-option'
                  }
                  onClick={() => setStatus('available')}>
                  <span className='dashboard__demo-option-icon'><FaRegComments /></span> <span>Available demos</span>

                  {availableDemosCount > 0 && (
                    <span className='dashboarddemo__flag-incomplete'></span>
                  )}
                </div>

              {loaded && workspace && workspace.upcoming_demos != '0' && (
              <div
                className={
                  demoStatus === 'upcoming'
                    ? 'dashboard__demo-option--active'
                    : 'dashboard__demo-option'
                }
                onClick={() => setStatus('upcoming')}>
                <span className='dashboard__demo-option-icon'><FaRegCalendarAlt /></span> <span>Upcoming Demos</span>

                {upcomingDemosCount > 0 && (
                  <span className='dashboarddemo__flag-incomplete'></span>
                )}
              </div>
              )}

              {loaded && workspace && workspace.rescheduled_demos != '0' && (
                <div className={demoStatus === 'rescheduled'
                      ? 'dashboard__demo-option--active'
                      : 'dashboard__demo-option'}
                  onClick={() => setStatus('rescheduled')}>
                  <span className='dashboard__demo-option-icon'><FaRegCalendarTimes /></span> <span>Rescheduling</span>

                  {rescheduledDemosCount > 0 && (
                    <span className='dashboarddemo__flag-incomplete'></span>
                  )}
                </div>
              )}

                {loaded && workspace && workspace.needs_feedback != '0' && (
                  <div className={demoStatus === 'incomplete'
                        ? 'dashboard__demo-option--active'
                        : 'dashboard__demo-option'}
                    onClick={() => setStatus('incomplete')}>
                    <span className='dashboard__demo-option-icon'><FaRegEdit /></span> <span>Completed {'(Needs Feedback)'}</span>

                    {incompleteDemosCount > 0 && (
                      <span className='dashboarddemo__flag-incomplete'></span>
                    )}
                  </div>
                )}

              {loaded && workspace && workspace.completed_demos != '0' && (
                <div className={
                    demoStatus === 'completed'
                      ? 'dashboard__demo-option--active'
                      : 'dashboard__demo-option'
                  }
                  onClick={() => setStatus('completed')}>
                  <span className='dashboard__demo-option-icon'><FaRegCalendarCheck /></span> <span>Completed</span>
                </div>
              )}

            {loaded && workspace && workspace.pending_demos != '0' && (
                <div className={demoStatus === 'pending'
                      ? 'dashboard__demo-option--active'
                      : 'dashboard__demo-option'
                  }
                  onClick={() => setStatus('pending')}>
                  <span className='dashboard__demo-option-icon'><FaRegHourglass /></span> <span>Pending</span>
                </div>
              )}
            </div>

            <div className='dashboard__left-right-wrapper'>
              <div className='dashboard__left-side'>
      {loaded && demoStatus === 'upcoming' && (
        <FadeIn duration={300} delay={150}>
        <BuyerDemosUpcoming buyerDeclinedReasons={buyerDeclinedReasons} />
        </FadeIn>
      )}

    {loaded && demoStatus === 'rescheduled' && (
        <FadeIn duration={300} delay={150}>
        <BuyerDemosRescheduled buyerDeclinedReasons={buyerDeclinedReasons} />
        </FadeIn>
      )}

        {demoStatus === 'available' && (
          <FadeIn duration={300} delay={150}>
          <BuyerDemosAvailable buyerDeclinedReasons={buyerDeclinedReasons} refreshPoints={refreshPoints} />
          </FadeIn>
        )}

          {loaded && demoStatus === 'incomplete' && (
            <FadeIn duration={300} delay={150}>
              <BuyerDemosIncomplete buyerObjections={buyerObjections} />
            </FadeIn>
          )}

      {loaded && demoStatus === 'completed' && (
        <FadeIn duration={300} delay={150}>
          <BuyerDemosCompleted />
        </FadeIn>
      )}

      {loaded && demoStatus === 'pending' && (
        <FadeIn duration={300} delay={150}>
          <BuyerDemosPending />
        </FadeIn>
      )}
      </div>

      
    {/* <div className='dashboard__right-side'>
      <div className='dashboard__events mt-10'>
        <h2>Upcoming Webinars</h2>
        {upcomingEvents.map(x => (
          <div className='dashboard__event'>
            {x.image_url && (
                  <div className='dashboard__event-thumbnail'>
                    <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}><img src={x.image_url} />
                    </Link>
                    </div>
                )}

                {(x.image_url == null || x.image_url.length === 0) && (
                  <div className="dashboard__event-thumbnail">
                    <Link to={`/event/${x.event_id}/${x.canonical_url_slug}`}>
                      <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                    </Link>
                  </div>
                )}
            
                <div className='dashboard__event-name'>{x.name}</div>

                {x.start_date && (
                  <div className="dashboard__event-date">
                    <strong>Event date:</strong> {new Date(x.start_date).toLocaleDateString()} {new Date(x.start_date).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                  </div>
                )}

                <div className='dashboard__event-btn mt-10'>
                  <Link className='btn' to={`/event/${x.event_id}/${x.canonical_url_slug}`}>Register</Link>
                </div>
          </div>
        ))}
      </div>
    </div> */}
    </div>

    </div> 

    </div>

            {/* <BuyerDemos
              status={demoStatus}
              setStatus={setDemoStatus}
              setDemosToday={setDemosToday}
              pendingStatus={pendingStatus}
              incompleteDemosCount={incompleteDemosCount}
              setIncompleteDemosCount={setIncompleteDemosCount}
              setUpcomingDemosCount={setUpcomingDemosCount}
              setAvailableDemosCount={setAvailableDemosCount}
              setCompletedDemosCount={setCompletedDemosCount}
              setRescheduledDemosCount={setRescheduledDemosCount}
            /> */}
          </>
        )}
      </div>

      {showOnboarding === true && onboardingLoaded === true && (
        <>
          <FadeIn delay={200} duration={200}>
            <div className='sellerdashboard__onboarding'>
              <h2>One last step before you can book demos</h2>

              <p className='sellerdashboard__onboarding-subtext'>
                Be sure to complete all of the following steps before your
                account goes live!
              </p>

              <div>
                
                  {hasCompany === false && (
                    <>
                    <div className='sellerdashboard__onboarding-item'>
                      <div>
                        <div><strong>Complete your company profile</strong></div>
                        <div className='sellerdashboard__onboarding-description'>
                          Your company name, description, website, etc
                        </div>
                      </div>

                      <span>
                        <a
                          href='/account/company'
                          className='sellerdashboard__onboarding-btn'>
                          Start
                        </a>
                      </span>
                      </div>
                    </>
                  )}
              </div>

              <div>
                <div className='sellerdashboard__onboarding-item'>
                  {workEmailVerified === false && (
                    <>
                      <div>
                        <div><strong>Verify your work email {'(1-2 min)'}</strong></div>

                        <div className='sellerdashboard__onboarding-description'>
                          A 1-time step needed to verify the email used for your work.
                        </div>
                      </div>

                      <span>
                        <Link
                          to='/account/work-email'
                          className='sellerdashboard__onboarding-btn'>
                          Start
                        </Link>
                      </span>
                    </>
                  )}

                  {workEmailVerified && (
                    <>
                      <div>
                        <span>Verify your work email</span>
                        <div className='sellerdashboard__onboarding-description'>
                          Enter a valid work email in order to participate in a demos
                        </div>
                      </div>
                      <span className='sellerdashboard__onboarding-done'>
                        Done
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </FadeIn>
        </>
      )}

      {showCharity && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2 className='modal__h2'>Donate to charity</h2>

            <p>
              This month you can donate your earnings to the following charity.
            </p>

            <p
              className=''
              style={{
                marginTop: '20px',
                fontSize: '16px',
                fontWeight: 'bold',
              }}>
              {charity.name}
            </p>

            <p style={{ marginTop: '10px', fontSize: '12px', color: '#999' }}>
              {charity.description}
            </p>

            <p style={{ marginTop: '20px' }}>
              <a href='/charity' className='link'>
                Read more about the charity of the month
              </a>
            </p>

            {submitting === false && (
              <div className='center' style={{ marginTop: '30px' }}>
                <button
                  className='btn btn--primary'
                  onClick={() => donateClickHandle()}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    viewBox='0 0 576 512'
                    style={{
                      height: '15px',
                      fill: 'white',
                      marginRight: '10px',
                    }}>
                    <path d='M312 24V34.5c6.4 1.2 12.6 2.7 18.2 4.2c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17c-10.9-2.9-21.1-4.9-30.2-5c-7.3-.1-14.7 1.7-19.4 4.4c-2.1 1.3-3.1 2.4-3.5 3c-.3 .5-.7 1.2-.7 2.8c0 .3 0 .5 0 .6c.2 .2 .9 1.2 3.3 2.6c5.8 3.5 14.4 6.2 27.4 10.1l.9 .3 0 0c11.1 3.3 25.9 7.8 37.9 15.3c13.7 8.6 26.1 22.9 26.4 44.9c.3 22.5-11.4 38.9-26.7 48.5c-6.7 4.1-13.9 7-21.3 8.8V232c0 13.3-10.7 24-24 24s-24-10.7-24-24V220.6c-9.5-2.3-18.2-5.3-25.6-7.8c-2.1-.7-4.1-1.4-6-2c-12.6-4.2-19.4-17.8-15.2-30.4s17.8-19.4 30.4-15.2c2.6 .9 5 1.7 7.3 2.5c13.6 4.6 23.4 7.9 33.9 8.3c8 .3 15.1-1.6 19.2-4.1c1.9-1.2 2.8-2.2 3.2-2.9c.4-.6 .9-1.8 .8-4.1l0-.2c0-1 0-2.1-4-4.6c-5.7-3.6-14.3-6.4-27.1-10.3l-1.9-.6c-10.8-3.2-25-7.5-36.4-14.4c-13.5-8.1-26.5-22-26.6-44.1c-.1-22.9 12.9-38.6 27.7-47.4c6.4-3.8 13.3-6.4 20.2-8.2V24c0-13.3 10.7-24 24-24s24 10.7 24 24zM568.2 336.3c13.1 17.8 9.3 42.8-8.5 55.9L433.1 485.5c-23.4 17.2-51.6 26.5-80.7 26.5H192 32c-17.7 0-32-14.3-32-32V416c0-17.7 14.3-32 32-32H68.8l44.9-36c22.7-18.2 50.9-28 80-28H272h16 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H288 272c-8.8 0-16 7.2-16 16s7.2 16 16 16H392.6l119.7-88.2c17.8-13.1 42.8-9.3 55.9 8.5zM193.6 384l0 0-.9 0c.3 0 .6 0 .9 0z' />
                  </svg>
                  Donate
                </button>

                <div>
                  <span
                    className='link link--cancel'
                    onClick={() => setShowCharity(false)}>
                    Go back
                  </span>
                </div>
              </div>
            )}

            {submitting === true && <Spinner />}
          </div>
        </>
      )}

      {showCharityConfirm && submitting === false && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2 className='modal__h2'>Thank you!</h2>

            <p>
              Your donation has been recorded and we will notify you once all
              funds have been transferred to this months charity of choice!
            </p>

            <div className='center'>
              <button
                className='btn btn--primary'
                onClick={() => (window.location = window.location)}>
                Close
              </button>
            </div>
          </div>
        </>
      )}

{/* {hasUnreadMessages && messagesLoaded && showOnboarding === false && onboardingLoaded && showUnread && (
        <FadeIn delay={200} duration={200}>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowUnread(false)}>
              <FaTimes />
            </div>

            <div>
                <div className='center' style={{fontSize: '40px'}}><FaRegEnvelopeOpen /></div>

              <div className='center'>
                You have <span>{unreadMessages.length}</span> unread message{'(s)'} in your inbox.
                <br/>

                <div className='mt-20'>
                  <Link to='/dashboard/inbox' className='btn'>Go to my inbox</Link>
                </div>
              </div>
            </div>
          </div>
        </FadeIn>
      )} */}

      {showRescheduleModal && onboardingLoaded && showOnboarding == false && showCheckIn === false && showCheckInNo === false && (
        <FadeIn delay={200} duration={300}>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowRescheduleModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Reschedule Request</h2>
            <div>
              <div className='mt-20'>
                You have a reschedule request pending. <span className='link' onClick={() => {
                  setShowRescheduleModal(false);
                  setStatus('rescheduled')
                }}>Click here</span> to submit new times.
              </div>
            </div>
          </div>
        </FadeIn>
      )}

      {onboardingLoaded && showOnboarding === false && showWelcomeBanner && (
        <FadeIn delay={100} duration={300}>
        <div className='overlay'></div>
        <div className='modal sellerdashboard__welcome-modal'>
          <div className='modal__close' onClick={() => setShowWelcomeBanner(false)}>
            <FaTimes />
          </div>
          <h2 className='modal__h2'>
            Welcome to Your Dashboard
          </h2>

          <div className='sellerdashboard__welcome-modal-body'>
            <div style={{marginBottom: '10px'}}>
              This is where you'll manage all of your scheduled demos as well as:
            </div>

            <div>
            <ul style={{listStyleType: 'disc', fontWeight: 'bold', marginLeft: '20px'}}>
                  <li><FaRegEnvelopeOpen /> Send/Reply to messages</li>
                  <li><FaRegEdit /> Submit feedback upon completing a demo</li>
                  <li><FaHandHoldingUsd /> Request a withdraw once you have funds available</li>
                </ul>
            </div>

            <div className='sellerdashboard__welcome-quick-start-wrapper'>
            <p className='sellerdashboard__welcome-modal-header'><FaCheckCircle style={{color: 'mediumseagreen'}} /> Checklist - Keep everything up to date</p>

              <div className='sellerdashboard__welcome-checklist'>
                <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
                  <div className='sellerdashboard__welcome-quick-start-icon'>
                  {(company.logo == null || company.logo.length == 0) && (
                    <FaExclamationCircle />
                  )}

                  {(company.logo != null && company.logo.length > 0) && (
                    <div className='sellerdashboard__welcome-company-logo'>
                      <img
                    src={
                      imageUrl +
                      'tr:di-@@companylogos@@default-buyer.png/' +
                      company.logo
                    }
                  />
                    </div>
                  )}
                  </div>

                  <div className='sellerdashboard__welcome-quick-start-name'>Company Logo
                    <div className='sellerdashboard__welcome-quick-start-time'>Make your profile stand out</div>
                  </div>

                  {(company.logo == null || company.logo.length == 0) && (
                    <div className="sellerdashboard__welcome-checkmark-pending">
                      <FaExclamationCircle />
                    </div>
                  )}

                  {(company.logo != null && company.logo.length > 0) && (
                    <div className="sellerdashboard__welcome-checkmark">
                      <FaCheck />
                    </div>
                  )}
                </div>

                {/* <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
                  <div className='sellerdashboard__welcome-quick-start-icon'><FaBuilding /></div>
                  <div className='sellerdashboard__welcome-quick-start-name'>Company Profile
                    <div className='sellerdashboard__welcome-quick-start-time'>Configure</div>
                  </div>

                  {profileLoaded == false && (
                    <div className="sellerdashboard__welcome-checkmark-pending">
                      <FaCheck />
                    </div>
                  )}

                  {profileLoaded == true && (
                    <div className="sellerdashboard__welcome-checkmark">
                      <FaCheck />
                    </div>
                  )}
                </div> */}

                <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/workhours')}>
                  <div className='sellerdashboard__welcome-quick-start-icon'><FaClock /></div>
                  <div className='sellerdashboard__welcome-quick-start-name'>Your Work Hours
                    <div className='sellerdashboard__welcome-quick-start-time'>Update</div>
                  </div>

                  {/* {hasWorkhours && (
                    <div className='sellerdashboard__welcome-checkmark'>
                      <FaCheck />
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            <div className='sellerdashboard__welcome-quick-start-wrapper'>
            <p className='sellerdashboard__welcome-modal-header'>Useful links</p>

            <div className='sellerdashboard__welcome-quick-start'>
              {/* <a href="https://www.youtube.com/embed/jtYfrmvsGRs?si=fAj1gd39wzk6L0ob" target="_blank" className='sellerdashboard__welcome-quick-start-item'>
                <div className='sellerdashboard__welcome-quick-start-icon'><FaYoutube /></div>
                <div className='sellerdashboard__welcome-quick-start-name'>Product Overview
                  <div className='sellerdashboard__welcome-quick-start-time'>3:00 min</div>
                </div>
              </a> */}

              <a href="https://play.google.com/store/apps/details?id=com.leadrpro.mobile" target="_blank" className='sellerdashboard__welcome-quick-start-item'>
                <div className='sellerdashboard__welcome-quick-start-icon'><FaGooglePlay /></div>
                <div className='sellerdashboard__welcome-quick-start-name'>Google Play Store
                  <div className='sellerdashboard__welcome-quick-start-time'>Download</div>
                </div>
              </a>

              <a href="https://apps.apple.com/us/app/leadrpro/id6449265881" className='sellerdashboard__welcome-quick-start-item' target="_blank">
                <div className='sellerdashboard__welcome-quick-start-icon'><FaAppleAlt /></div>
                <div className='sellerdashboard__welcome-quick-start-name'>iOS Store
                  <div className='sellerdashboard__welcome-quick-start-time'>Download</div>
                </div>
              </a>

              <Link to="/leave-feedback" className='sellerdashboard__welcome-quick-start-item'>
                <div className='sellerdashboard__welcome-quick-start-icon'><FaHeadset /></div>
                <div className='sellerdashboard__welcome-quick-start-name'>Support
                  <div className='sellerdashboard__welcome-quick-start-time'>Need assistance?</div>
                </div>
              </Link>
            </div>
            </div>
          </div>
        </div>
      </FadeIn>
      )}

      {showWithdrawConfirmed === true && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <img src={close} className='modal__close' onClick={closingClick} />

            <h2 className='modal__h2'>Success!</h2>

            <p className='mar20--top'>
              Your request has been received. You will be notified by email once your payment has been successfully transferred over to you.
            </p>

            <div className='mar20--top'>
              If you do not receive payment by the Friday following the 3-5 day window, please reach out to support.
            </div>
          </div>
        </>
      )}

      {showWithdrawRequested === true && (
        <>
          <div className='overlay'></div>
          <div className='modal center'>
            <img src={close} className='modal__close' onClick={closingClick} />

            <h2 className='modal__h2'>Request Withdraw?</h2>

            <p className='mar20--top'>
              Transfer ${buyerEarnings.availablebalance} to your account? Payments are sent within 3-5 business days on average. You will receive an email with your payment link once it is ready.
            </p>

            {/* <div className='mar20--top'>
              <input type="checkbox" name="enableDonation" id='enableDonation' checked={showDonation} value={showDonation} onClick={(e) => setShowDonation(e.target.checked)} /> <label htmlFor='enableDonation'>Donate a portion to charity</label>
            </div> */}
            
            {showDonation && (
              <FadeIn delay={100} duration={400}>
              <div className='mar20--top'>
                <h3><strong>Donate to charity</strong></h3>

                <div>
                  Donate a portion of your payout to our monthly charity. <Link className='link' to='/charity'>Read more</Link>
                </div>

                <div className='mar20--top buyerdashboard__donate-options' style={{display: 'flex'}}>
                  <div className={selectedCharity === 1 ? 'selected': ''} onClick={() => setSelectedCharity(1)}>10%</div>
                  <div className={selectedCharity === 2 ? 'selected': ''} onClick={() => setSelectedCharity(2)}>15%</div>
                  <div className={selectedCharity === 3 ? 'selected': ''} onClick={() => setSelectedCharity(3)}>20%</div>
                </div>
              </div>
              </FadeIn>
            )}

            <div className='mar20--top'>
              {submittingWithdraw === false && (
              <button onClick={() => withdrawClickHandle()} className='btn btn--primary'>
                Confirm
              </button>
              )}

              {submittingWithdraw && (
                <Spinner />
              )}
            </div>
          </div>
        </>
      )}

      {showCheckIn && (
        <FadeIn delay={100} duration={100}>
          <div className='overlay'></div>
          <div className='modal'>

            <div className=''>
              <h2 className='modal__h2'>Just Checking In!</h2>

              <p style={{marginTop: '20px'}}>
                You recently met with <strong>{checkin.company_name}</strong> and we wanted to check in and see how things were going. Did you end up implementing/purchashing this product?
              </p>

              <div style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                <div>
                  <span className='btn btn--primary btn--small' onClick={() => handleCheckInYes()}>Yes</span>
                </div>

                <div style={{marginLeft: '10px'}}><span className='btn btn--small btn--secondary btn--cancel' style={{backgroundColor:'#eee', color: 'black'}} onClick={() => handleCheckInNo()}>No, I did not</span></div>
              </div>

              <div className='center mt--30 mt-30' style={{marginTop: '20px'}}>
                  <span className='link link--cancel' onClick={() => handleCheckInMaybe()}>No, but still evaluating</span>
                </div>
            </div>
          </div>
        </FadeIn>
      )}

      {showCheckInYes && (
        <FadeIn delay={400} duration={400}>
          <div className='overlay'></div>

          <div className='modal'>
            <h2 className='modal__h2'>That's great!</h2>

            <p style={{marginTop: '20px'}}>
              We'll use this information to improve your software recommendations in the future.
            </p>

            <p style={{marginTop: '20px'}}>
              <button className='btn btn--primary btn--small' onClick={() => setShowCheckInYes(false)}>Done</button>
            </p>
          </div>
        </FadeIn>
      )}

      {showCheckInNo && (
        <FadeIn delay={100} duration={400}>
          <div className='overlay'></div>

          <div className='modal'>
            <h2 className='modal__h2'>Please select the reason</h2>

            <p style={{marginTop: '20px'}}>
              We will use this information to improve your future matches for recommended software.
            </p>

            <p style={{marginTop: '20px'}}>
              <select
                className='input select input_box dropdown-font'
                onChange={(e) => setSelectedDeclineReason(e.target.value)}
                name='choose reason'>
                <option value='0'>Select a reason</option>
                  <>
                    {declinedReasons.length > 0 &&
                      declinedReasons.map((item) => (
                        <option value={item.decline_reason_id}>
                          {item.reason}
                        </option>
                      ))}
                  </>   
              </select>
            </p>

            {showCheckInNoConfirm === false && (
              <p style={{marginTop: '20px'}}>
                <button className='btn btn--primary btn--small' onClick={() => handleSubmitCheckInNo()}>Submit</button>

                <span className='link' style={{marginLeft: '20px'}} onClick={() => handleCheckInNoBack()}>Back</span>
              </p>
            )}

            {showCheckInNoConfirm && (
              <div style={{marginTop: '20px'}}>
                <p style={{color: 'green', fontSize: '12px'}}>Saved!</p>
                <p style={{marginTop:'10px'}}>
                  <button className='btn btn--secondary btn--cancel' onClick={() => {setShowCheckInNo(false); setShowCheckIn(false)}}>Done</button>
                </p>
              </div>
            )}
          </div>
        </FadeIn>
      )}

      {loaded && showOnboarding === false && onboardingLoaded === true && (userProfile.job_title == '' || userProfile.first_name == '') && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2 className='modal__h2'>Update your profile</h2>

            <p className='mt-10'>
              Your profile is currently missing one or more fields {'(name, job title, seniority...)'}.
            </p>

            <p className='mt-20 center'>
              <Link to='/account/profile' className='btn btn--primary'>Update profile</Link>
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default BuyerDashboardNew;
