import './CompanyInfo.css';
import {useEffect, useState} from 'react';
import { getCompanySizes, getUserCompany, updateCompany, createCompany, getCompanyIndustries, getAllIndustries, hRefreshToken, refreshToken, searchCompaniesDropdown } from '../../utils/api';
import { refresh } from '../../services/auth.service';
import React from 'react';
import FadeIn from '../../utils/fadein';
import {Helmet} from "react-helmet";
import Spinner from '../../components/layout/Spinner';
import { Routes, Route, Link, Outlet } from 'react-router-dom';
import { FaAsterisk } from 'react-icons/fa';

const CompanyInfo = ({userType}) => {
  const [company, setCompany] = useState({});
  const [companySizes, setCompanySizes] = useState([]);
  const [selectedSize, setSelectedSize] = useState({});
  const [selectedCompanySize, setSelectedCompanySize] = useState(0);
  const [selectedCompanySizeName, setSelectedCompanySizeName] = useState('');
  const [selectedCompanyStage, setSelectedCompanyStage]= useState(0);
  const [selectedIndustry, setSelectedIndustry] = useState(0);
  const [industryName, setIndustryName] = useState('');
  const [newCompanyName, setNewCompanyName] = useState('');
  const [industries, setIndustries] = useState([]);
  const [selectedIndustryId, setSelectedIndustryId] = useState(0);
  const [newCompanyWebsite, setNewCompanyWebsite] = useState('');
  const [showCompanyName, setShowCompanyName] = useState(false);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [query, setQuery] = useState('');
  const [showDropdown, setShowDropdown] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [hasCompany, setHasCompany] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(0);
  const [hasSellerRole, setHasSellerRole] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  let refSize = React.createRef();

  useEffect(() => {
    async function loadData(){
      await loadCompanySizes();

      let result = await getUserCompany();

      if (result.success){
        let {company_stage_id, name, industry, industry_id} = result.data;

        setCompany(result.data);
        setNewCompanyName(name);
        setNewCompanyWebsite(result.data.website);
        setSelectedCompanySize(result.data.company_size_id);
        setSelectedSize(companySizes.find(item => item.company_size_id === result.data.company_size_id));
        setSelectedCompanyStage(company_stage_id);
        setSelectedIndustry(industry_id);
        setSelectedIndustryId(industry_id);
        setIndustryName(industry);
        setHasSellerRole(result.data.has_seller_role);
        setIsSeller(result.data.is_seller);
        setLoaded(true);
        setHasCompany(true);
      }

      else if (result.status === 403){
        await refresh();

          let result = await getUserCompany();

          if (result.success){
            let {company_stage_id, name, industry, industry_id} = result.data;

            setCompany(result.data);
            setNewCompanyName(name);
            setNewCompanyWebsite(result.data.website);
            setSelectedCompanySize(result.data.company_size_id);
            setSelectedSize(companySizes.find(item => item.company_size_id === result.data.company_size_id));
            setSelectedCompanyStage(company_stage_id);
            setSelectedIndustry(industry_id);
            setSelectedIndustryId(industry_id);
            setIndustryName(industry);
            setHasSellerRole(result.data.has_seller_role);
            setIsSeller(result.data.is_seller);
            setLoaded(true);
            setHasCompany(true);
          }

          else{
            setHasCompany(false);
            setLoaded(true);
          }
      }

      else{
        // no company found
        setHasCompany(false);
        setLoaded(true);
      }
    }

    async function loadCompanySizes() {
      let data = await getCompanySizes();
      if (data.data){
          setCompanySizes(data.data);
      }
    }

    async function loadIndustries(){
      let data = await getAllIndustries();

      if (data.success){
        setIndustries(data.data);
      }
    }

    loadCompanySizes();
    loadData();
    loadIndustries();
  }, []);

  useEffect(() => {
    let found = companySizes.find(item => {
      return item.company_size_id === selectedCompanySize
    });

    if (found){
      setSelectedCompanySizeName(found.value);
      setSelectedSize(found);
    }

  }, [selectedCompanySize]);

  async function handleSaveCompanyName(){
    let res = await updateCompany({name: newCompanyName});

    if (res.success){
      setShowCompanyName(false);
      setCompany({...company, name: newCompanyName});
      
      await hRefreshToken();
      loadSaveBanner();
    }
  }

  async function companySearch(query){
    let data = await searchCompaniesDropdown(query);

    if (data.data){
      setCompanies(data.data);
    }
  }

  async function handleNameChange(e){
    setShowDropdown(true);
    setCompanies([]);
    setSelectedCompany(0);

    setQuery(e.target.value);
    await companySearch(e.target.value);
  }

  async function handleNameClick(id, name){
    setSelectedCompany(id);
    setShowDropdown(false);
    setQuery(name);
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleSaveCompanyWebsite(){
    let res = await updateCompany({website: newCompanyWebsite});

    if (res.success){
      setCompany({...company, website: newCompanyWebsite});
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await updateCompany({website: newCompanyWebsite});

      if (res.success){
        setCompany({...company, website: newCompanyWebsite});
        loadSaveBanner();
      }
    }
  }

  async function handleSaveCompanyIndustry(){
    let res = await updateCompany({industry_id: selectedIndustryId});

    if (res.success){
      setCompany({...company, industry_id: selectedIndustryId});
      setCompany({...company, industry: industryName});     
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await updateCompany({industry_id: selectedIndustryId});

      if (res.success){
        setCompany({...company, industry_id: selectedIndustryId});
        setCompany({...company, industry: industryName});     
        loadSaveBanner();
      }
    }
  }

  async function handleSaveCompanySize(){
    let selected = parseInt(refSize.current.value);

    let res = await updateCompany({company_size_id: selected});

    if (res.success){
      setSelectedCompanySize(selected);
      loadSaveBanner();
    }

    else if (res.status === 403){
      await refresh();
      let res = await updateCompany({company_size_id: selected});

      if (res.success){
        setSelectedCompanySize(selected);
        loadSaveBanner();
      }
    }
  }

  async function addUserCompany(){
    // save new company
    if (query.length > 0){
      let result = await createCompany(query);

      if (result.success){
          window.location = window.location;
      }
    }

    else{
      alert("Must enter a valid company name");
    }
  }

    return (
      <div className="companyedit">
        <Helmet>
          <meta name="description" content="" />
        </Helmet>
        <div className=""><Link className='btn' to="/account/home">Back</Link></div>
        <div className="account__header">Company Information</div>

        <p className='account__description'>
          Keep your company info up to date.
        </p>

        {loaded === false && (
          <>
            <Spinner />
          </>
        )}
  
        {loaded && hasCompany && (
          <>
        <div className='companyedit__form'>
        <div style={{textAlign: 'right', fontSize: '12px', fontStyle: 'italic', marginBottom: '10px', color: 'tomato'}}><FaAsterisk /> Required fields</div>

          <div className="accountinfo__form-group">
            <div>
              <div className="accountinfo__form-group-title">Company Name</div>
            </div>

            <div className="">
              <div className="">
                <input type="text" id="txtCompanyName" className="input" value={newCompanyName} onChange={event => { setNewCompanyName(event.target.value); }} />
              </div>

              {isSeller && hasSellerRole && (
              <div className="form-group">
                <button className="btn btn--primary" onClick={handleSaveCompanyName}>Save</button>
              </div>
              )}

              {!isSeller && (
                <div className="form-group">
                  <button className="btn btn--primary" onClick={handleSaveCompanyName}>Save</button>
                </div>
              )}
            </div>
          </div>

          <div className="accountinfo__form-group">
            <div>
              <div className="accountinfo__form-group-title">Company Size <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></div>

              <p className='account__description'>
                How many employees work in your organization?
              </p>

              <div className="accountinfo__edit">
              <div className="form-group">                
                <select
                    className="input-box dropdown-font"
                    type='text'
                    ref={refSize}
                    value={selectedCompanySize}
                    onChange={(e) => setSelectedCompanySize(e.target.value)}
                    name='companysize'
                    placeholder='Enter company size'
                    required
                >
                    <option>Select company size</option>
                    {companySizes.length > 0 && (
                        companySizes.map((item, index) => (
                            <option key={index} value={item.company_size_id}>
                                {item.value}
                            </option>
                        ))
                    )}
                </select>
              </div>

              {isSeller && hasSellerRole && (
              <div className="form-group">
                <button className="btn btn--primary" onClick={handleSaveCompanySize}>Save</button>
              </div>
              )}

              {!isSeller && (
                <div className="form-group">
                  <button className="btn btn--primary" onClick={handleSaveCompanySize}>Save</button>
                </div>
              )}
            </div>
            </div>
          </div>

          <div className="accountinfo__form-group">
            <div>
              <div className="accountinfo__form-group-title">Industry <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></div>
            </div>

            <p className='account__description'>
              How would you best categorize your particular industry?
            </p>

            <div className="account__edit">
              <div className="form-group">
                <select className='input select' value={selectedIndustryId} onChange={(e) => setSelectedIndustryId(e.target.value)}>
                  <option value="0">Select industry...</option>
                  {industries.map(item => (
                    <>
                      <option value={item.industry_id}>{item.name}</option>
                    </>
                  ))}
                </select>
              </div>

              {isSeller && hasSellerRole && (
              <div className="form-group">
                <button className="btn btn--primary" onClick={handleSaveCompanyIndustry}>Save</button>
                </div>
              )}

              {!isSeller && (
                <div className="form-group">
                <button className="btn btn--primary" onClick={handleSaveCompanyIndustry}>Save</button>
                </div>
              )}
            </div>
          </div>

          <div className="accountinfo__form-group" id='website'>
            <div>
              <div className="accountinfo__form-group-title">Company Website <span style={{fontSize: '10px', color: 'tomato'}}><FaAsterisk /></span></div>
            </div>

            <p className='account__description'>
              Enter a valid URL for your organization.
            </p>

            <div className="account__edit">
              <div className="form-group">
                <input type="text" id="txtCompanyWebsite" className="input" value={newCompanyWebsite} onChange={event => { setNewCompanyWebsite(event.target.value); }} placeholder="Your company's website" />
              </div>

              {isSeller && hasSellerRole && (
              <div className="form-group">
                <button className="btn btn--primary" onClick={handleSaveCompanyWebsite}>Save</button>
              </div>
              )}

              {!isSeller && (
                <div className="form-group">
                <button className="btn btn--primary" onClick={handleSaveCompanyWebsite}>Save</button>
              </div>
              )}
            </div>
          </div>

          {/* <div className="accountinfo__form-group">
            <div>
              <div className="accountinfo__form-group-title">Company Email</div>
              <div className="accountinfo__form-group-current">{email}</div>
            </div>
  
            <div className="account__edit-link" onClick={() => setShowEmail(!showEmail)}>
              {showEmail ? <span>Cancel</span> : <span><img src={iconEdit} alt="" className="account__iconedit" /> Edit</span>}
            </div>
          </div>

          {showEmail && (
            <div className="accountinfo__edit">
               <div className="form-group">
                <label className="label">Company email</label>
                <input type="text" id="txtFirstName" className="input" value={newEmail} onChange={event => { setNewEmail(event.target.value); }} />
              </div>

              <div className="form-group">
                <button className="btn btn--action btn__save" onClick={handleSaveEmail}>Save</button>
              </div>
            </div>
          )} */}
        </div>
        </>
        )}

        {loaded && hasCompany === false && (
          <>
          <h3>Enter your companies name or select from the dropdown</h3>
            <div>
              <div className='form-group'>
                <label className="label input-label" htmlFor='txtName'>Company name</label>
                <input type="text" id='txtName' className="input" onChange={handleNameChange} placeholder="Enter here" value={query} required></input>
              </div>

              {companies.length > 0 && showDropdown && (
              <div className="selectrole__company-dropdown-wrapper" style={{width: "40%"}}>
              <div className="selectrole__company-dropdown">
                  {companies.map((item, index) => (
                      <div key={index} onClick={() => handleNameClick(item.company_id, item.name)} className="selectrole__company-dropdown-item" id={"company_" + item.company_id}>{item.name}</div>
                  ))}
                  </div>
              </div>
              )}

              <div>
                <button className='btn btn--primary' onClick={() => addUserCompany()}>Save</button>
              </div>
            </div>
          </>
        )}

        {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
      </div>
  )
}

  export default CompanyInfo;