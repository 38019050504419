import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { addEmailCampaign, getFaqQuestions } from '../../utils/api';
import './HomepageHub_Software.css';
import medialogos from '../../assets/design/logos/medialogos.png';

import hub1 from '../../assets/design/homepagehub/hub1b.png';
import hub2 from '../../assets/design/homepagehub/softwarehub2.png';
import hub3 from '../../assets/design/homepagehub/hub3b.png';
import pattern1 from '../../assets/design/homepagehub/pattern_1.png';
import pattern2 from '../../assets/design/homepagehub/pattern_2.png';
import vendor1 from '../../assets/design/homepagehub/vendor1.png';
import vendor2 from '../../assets/design/homepagehub/vendor2.png';
import blog1 from '../../assets/design/homepagehub/blog1.png';
import blog2 from '../../assets/design/homepagehub/blog2.png';
import blog3 from '../../assets/design/homepagehub/blog3.png';
import footer from '../../assets/design/homepagehub/footer.png';
import herocards from '../../assets/design/homepage/logos2.png';
import herobg from '../../assets/design/homepagehub/software/herobg.png';
import heroicon from '../../assets/design/homepagehub/software/heroicon.png';
import paymentlogos from '../../assets/design/homepagehub/software/paymentlogos.png';
import relevance from '../../assets/design/homepagehub/software/softwarehub_relevance.png';
import logobg from '../../assets/design/homepagehub/software/logo_bg.png';
import booking from '../../assets/design/homepagehub/software/booking.png';
import relevant from '../../assets/design/homepagehub/software/rel.png';
import StartDiscovering from './StartDiscovering';
import ScrollToTop from '../../components/layout/ScrollToTop';

function HomepageHub_Software() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterDone, setNewsletterDone] = useState(false);
  const [faq,setFaq] = useState([]);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      
    }

    async function loadFaq(){
      let res = await getFaqQuestions('buyer');

      if (res.success){
        setFaq(res.data);
      }
    }

    loadData();
    loadFaq();
  }, []);

  async function handleFaqClick(item){
    setSelectedFaq(item);
  }

  return (
    <div className='homepagehubsoftware'>
      <ScrollToTop />
      <Helmet>
        <title>LeadrPro - Discover new software solutions</title>
      </Helmet>

      <div className='homepagehubsoftware__hero'>
        <div className='homepagehubsoftware__hero-left'>
          <div className='homepagehubsoftware__hero-icon'>
            <img src={heroicon} alt='' /> <span className='homepagehubsoftware__hero-icon-text'>Software Hub</span>
          </div>

          <div className='homepagehub__hero-h1'>
            Find tomorrow's<br/>
            solutions, today
          </div>

          <div className='homepagehub__hero-subheader'>
            Research hundreds of reviews and discussions and find what your business truly needs. Book a demo and get paid for your time.
          </div>

          <div className='homepagehub__hero-btns'>
            <button onClick={() => navigate('/signup')} className='homepagehub__hero-btn homepagehub__hero-btn--primary'>
              Get started today
            </button>

            <div className='mt-20 ml-5 pointer' onClick={() => navigate('/software')}>
              See all software solutions
            </div>
          </div>
        </div>

        <div className='homepagehubsoftware__hero-right'>
          <img src={herocards} alt='' />
        </div>

        <div className='homepagehubsoftware__hero-bg'>
          <img src={herobg} alt='' />
        </div>
      </div>

      <div className='homepagehubsoftware__payment-methods'>
        <div>
          Apply to paid demos. Discover new software.<br/>
          Cash out instantly. It's that simple!
        </div>

        <div className='homepagehubsoftware__payment-methods-logos'>
          <img src={paymentlogos} alt='' />
        </div>
      </div>

      <div>
        <StartDiscovering />
      </div>

      <div className='homepagehub__section homepagehub__hub-descriptions'>
        <div className='homepagehub__hub-descriptions-pattern1'>
          <img src={pattern1} alt='' />
        </div>

        <div className='homepagehub__hub-descriptions-pattern2'>
          <img src={pattern2} alt='' />
        </div>

        <div className='homepagehub__hub-description-item'>
          <div className='homepagehub__hub-description-info'>
            <div className='homepagehub__hub-description-info-label'>
              Software Hub
            </div>

            <div className='homepagehub__hub-description-info-header'>
              Get paid while you search for ways to upgrade your business
            </div>

            <div className='homepagehub__hub-description-info-copy'>
              We pay out $75 for every demo completed on LeadrPro!
              Once your demo is completed, you can cash out using PayPal, Venmo, CashApp, Zelle, Amazon Gift Card or Visa.
            </div>

            <div className='homepagehub__hub-description-info-btn'>
              <button className='btn' onClick={() => navigate('/software')}>Start today</button>
            </div>
          </div>

          <div className='homepagehub__hub-description-vector'>
            <div className='homepagehub__hub-description-vector-image'>
              <img src={booking} alt='' />
            </div>
          </div>
        </div>

        <div className='homepagehub__hub-description-item' style={{flexDirection: 'row-reverse'}}>
          <div className='homepagehub__hub-description-info'>
            <div className='homepagehub__hub-description-info-label'>
              Peace of mind with LeadrPro
            </div>

            <div className='homepagehub__hub-description-info-header'>
              Avoid the cold calls and emails by being in control of what your business needs.
            </div>

            <div className='homepagehub__hub-description-info-copy'>
              Tell us what solutions you're looking for and what your budget is and our recommendation engine will find solutions to match your needs.
            </div>

            <div className='homepagehub__hub-description-info-btn'>
              <button className='btn' onClick={() => navigate('/software')}>Start exploring</button>
            </div>
          </div>

          <div className='homepagehub__hub-description-vector'>
            <div>
              <img src={relevant} alt='' style={{maxHeight: '350px'}} />
            </div>
          </div>
        </div>

        <div className='homepagehub__hub-description-item'>
          <div className='homepagehub__hub-description-info'>
            <div className='homepagehub__hub-description-info-label'>
              Grow your business
            </div>

            <div className='homepagehub__hub-description-info-header'>
              Compare software and service providers and read verified reviews
            </div>

            <div className='homepagehub__hub-description-info-copy'>
              Research from hundreds of reviews and discussions for what your business truly needs. Then, book a demo and get in contact with a representative of that company through our video meeting feature.
            </div>

            <div className='homepagehub__hub-description-info-btn'>
              <button className='btn' onClick={() => navigate('/software')}>Start today</button>
            </div>
          </div>

          <div className='homepagehub__hub-description-vector'>
            <div>
              <img src={hub2} alt='' style={{maxHeight: '350px'}} />
            </div>
          </div>
        </div>
      </div>

      <div className='homepagehubsoftware__vendor-hub'>
        <div className='homepagehubsoftware__vendor-hub-bg'>
          <img src={logobg} alt='' />
        </div>
        <div className='homepagehubsoftware__vendor-hub-copy'>
          <div className='homepagehubsoftware__vendor-hub-h2'>Interested in becoming a vendor?</div>

          <div className='homepagehubsoftware__vendor-hub-text'>
            Do you have an innovative software solution you would like to share on LeadrPro? Sign up as a vendor and demo your software to hundreds of high profile buyers.
          </div>

          <div className='homepagehubsoftware__vendor-hub-text2'>
            Boost your sales with LeadrPro
          </div>
        </div>

        <div className='homepagehubsoftware__vendor-hub-cta'>
          <button onClick={() =>navigate('/sell-software')}>Visit our vendor hub</button>

          <div className='mt-30 center pointer' onClick={() => navigate('/signup/seller')}>
            Sign up
          </div>
        </div>
      </div>

      <div className='homepagehub__faq'>
        <div className='center homepagehub__faq-title'>Frequently Asked Questions</div>

        <div className='homepagehub__faq-items'>
          {faq.map((item, index) => (
            <div key={index} className='homepagehub__faq-item'>
              <div className='homepagehub__faq-item-question' onClick={() => handleFaqClick(item)}>
                {item.question}
              </div>

              {selectedFaq != null && selectedFaq.faq_question_id == item.faq_question_id && (
                <FadeIn delay={300} duration={300}>
                <div className='homepagehub__faq-item-answer'>
                  {item.answer}
                </div>
                </FadeIn>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default HomepageHub_Software;