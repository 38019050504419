import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { searchDemos, getAdminDemosPending, getDemosByDate } from '../../utils/api/admin';

function DemoBreakdown() {
  const [loaded, setLoaded] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [results, setResults] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [query, setQuery] = useState('');
  const [demos, setDemos] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      if (selectedDate != null){
        let res = await getDemosByDate(selectedDate);

        if (res.success){
          setDemos(res.data);
          setLoaded(true);
        }
      }
    }

    loadData();
  }, [selectedDate]);

  async function handleSearch(){
    setSubmitting(true);
    let id = null;

    if (Number.isInteger(parseInt(query)))
      id = parseInt(query);

    let res = await searchDemos(query, id);

    if (res.success){
      setResults(res.data);
      setSubmitting(false);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();
      let res = await searchDemos(query, id);

      if (res.success){
        setResults(res.data);
        setSubmitting(false);
      }
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      await handleSearch();
    }
  }

  // async function loadData(val){
  //   if (selectedDate != null){
  //     let res = await getDemosByDate(selectedDate);

  //     if (res.success){
  //       setDemos(res.data);
  //       setLoaded(true);
  //     }
  //   }
  // }

  async function handleMonthChange(val){
    setLoaded(false);
    setSelectedDate(val);
   // loadData(val);
  }

  return (
    <>
      <Helmet>
        <title>Admin - Demo Breakdown</title>
      </Helmet>

      <h1>Demo Breakdown {`(${results.length})`}</h1>

      <div>
      <select className='input select' onChange={(e) => handleMonthChange(e.target.value)}>
          <option>Select date...</option>
          <option value='1/1/2025'>January 2025</option>
          <option value='12/1/2024'>December 2024</option>
          <option value='11/1/2024'>November 2024</option>
          <option value='10/1/2024'>October 2024</option>

          <option value='9/1/2024'>September 2024</option>
          <option value='8/1/2024'>August 2024</option>
          <option value='7/1/2024'>July 2024</option>
          <option value='6/1/2024'>June 2024</option>
          <option value='5/1/2024'>May 2024</option>
          <option value='4/1/2024'>April 2024</option>
          <option value='3/1/2024'>March 2024</option>
          <option value='2/1/2024'>February 2024</option>
          <option value='1/1/2024'>January 2024</option>
          <option value='12/1/2023'>December 2023</option>
          <option value='11/1/2023'>November 2023</option>
          <option value='10/1/2023'>October 2023</option>
          <option value='9/1/2023'>September 2023</option>
          <option value='8/1/2023'>August 2023</option>
        </select>
      </div>

      <div className='admindashboard__cards'>
        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Total Approved</strong></div>
          <div className='admindashboard__card-value'>
          {demos.filter(x => x.seller_status == 'Approved' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length} / <span style={{fontSize: '20px', color: '#555'}}>{Math.ceil((demos.filter(x => x.seller_status == 'Approved' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length / demos.length) * 100)}%</span>
          </div>
        </div>

        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Total Declined</strong></div>
          <div className='admindashboard__card-value'>
          {demos.filter(x => x.seller_status == 'Declined' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length} /  <span style={{fontSize: '20px', color: '#555'}}>{Math.ceil((demos.filter(x => x.seller_status == 'Declined' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length / demos.length) * 100)}%</span>
          </div>
        </div>

        <div className='admindashboard__card'>
          <div className='admindashboard__card-title'><strong>Total Pending</strong></div>
          <div className='admindashboard__card-value'>
          {demos.filter(x => x.seller_status == 'Pending' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length} / <span style={{fontSize: '20px', color: '#555'}}>{Math.ceil((demos.filter(x => x.seller_status == 'Pending' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length / demos.length) * 100)}%</span>
          </div>
        </div>
      </div>

      {/* <div className='admindashboard__search-lookup'>
        <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} />

        <div className='admindashboard__search-lookup-btn'>
          <button className='btn' onClick={() => handleSearch()}>Search</button>
        </div>
      </div> */}

      {loaded === false && (
        <Spinner />
      )}

      {loaded && submitting === false && (
        <>
         <div>
          <h2>Demos Approved {`(${demos.filter(x => x.seller_status == 'Approved' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length})`}</h2>

          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Demo ID</th>
              <th>Buyer</th>
              <th>Seller</th>
              <th>Date Applied</th>
              <th>Buyer Status</th>
              <th>Seller Status</th>
              <th>Demo Status</th>
            </tr>

            {demos.filter(x => x.seller_status == 'Approved' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).map((item, index) => (
              <tr key={index}>
                <td><strong>#{index}</strong></td>
                <td>{item.demo_id}</td>

                <td>
                  <div className='flex'>
                  <div style={{display: 'flex', alignItems: 'center'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                      </div>
                    </div>  

                  <div className='ml-10'>
                    <strong>{item.buyer_company_name}
                      <br/>
                      {item.buyer_first_name}
                    </strong>
                  </div>
                                           
                  </div>
                  </td>

                  <td>
                    <div className='flex'>
                    <div className='admindashboard__logo mr-10'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                      </div>

                      <div><strong>{item.seller_company_name}
                        <br/>
                        {item.seller_first_name}</strong></div>

                    </div>
                  </td>

                  <td>
                    {new Date(item.date_applied).toDateString()}
                  </td>

                  <td>
                    {item.buyer_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.buyer_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.seller_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.seller_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.demo_status_id === 1 && (
                      <span className='admindashboard__yellow-flag'>Pending</span>
                    )}

                    {item.demo_status_id === 4 && (
                      <span className='admindashboard__green-flag'>Completed</span>
                    )}

                    {item.demo_status_id === 2 && (
                      <span className='admindashboard__red-flag'>Declined</span>
                    )}

                    {item.demo_status_id === 5 && (
                      <span className='admindashboard__red-flag'>Expired</span>
                    )}
                  </td>
              </tr>
            ))}
          </table>
       </div>

       <div className='mt-20'>
          <h2>Demos Declined {`(${demos.filter(x => x.seller_status == 'Declined' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length})`}</h2>

          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Demo ID</th>
              <th>Buyer</th>
              <th>Seller</th>
              <th>Date Applied</th>
              <th>Buyer Status</th>
              <th>Seller Status</th>
              <th>Demo Status</th>
            </tr>

            {demos.filter(x => x.seller_status == 'Declined').map((item, index) => (
              <tr key={index}>
                <td><strong>#{index}</strong></td>
                <td>{item.demo_id}</td>

                <td>
                  <div className='flex'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                      </div>
                    </div> 

                  <div className='ml-10'>
                    <strong>Buyer: {item.buyer_company_name}
                      <br/>
                      {item.buyer_first_name}
                    </strong>
                  </div>
                                        
                  </div>
                  </td>

                  <td>
                    <div className='flex'>
                      <div className='admindashboard__logo mr-10'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                      </div>

                      <div><strong>Seller: {item.seller_company_name}
                        <br/>
                        {item.seller_first_name}</strong></div>

                    </div>
                  </td>

                  <td>
                    {new Date(item.date_applied).toDateString()}
                  </td>

                  <td>
                    {item.buyer_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.buyer_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.seller_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.seller_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.demo_status_id === 1 && (
                      <span className='admindashboard__yellow-flag'>Pending</span>
                    )}

                    {item.demo_status_id === 4 && (
                      <span className='admindashboard__green-flag'>Completed</span>
                    )}

                    {item.demo_status_id === 2 && (
                      <span className='admindashboard__red-flag'>Declined</span>
                    )}

                    {item.demo_status_id === 5 && (
                      <span className='admindashboard__red-flag'>Expired</span>
                    )}
                  </td>
              </tr>
            ))}
          </table>
       </div>

       <div className='mt-20'>
          <h2>Demos Pending {`(${demos.filter(x => x.seller_status == 'Pending' && new Date(x.date_applied).getMonth() == new Date(selectedDate).getMonth() && new Date(x.date_applied).getFullYear() == new Date(selectedDate).getFullYear()).length})`}</h2>

          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Demo ID</th>
              <th>Buyer</th>
              <th>Seller</th>
              <th>Date Applied</th>
              <th>Buyer Status</th>
              <th>Seller Status</th>
              <th>Demo Status</th>
              <th>Reviewed</th>
            </tr>

            {demos.filter(x => x.seller_status == 'Pending').map((item, index) => (
              <tr key={index}>
                <td><strong>#{index}</strong></td>
                <td>{item.demo_id}</td>

                <td>
                  <div className='flex'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div className='admindashboard__logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.buyer_logo} />
                      </div>
                    </div> 

                  <div className='ml-10'>
                    <strong>{item.buyer_company_name}<br/>
                    {item.buyer_first_name}
                    </strong>
                  </div>            
                  </div>
                  </td>

                  <td>
                    <div className='flex'>
                    <div className='admindashboard__logo mr-10'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.seller_logo} />
                      </div>

                      <div><strong>{item.seller_company_name}
                        <br/>{item.seller_first_name}</strong></div>

                    </div>
                  </td>

                  <td>
                    {new Date(item.date_applied).toDateString()}
                  </td>

                  <td>
                    {item.buyer_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.buyer_status}
                      </span>
                    )}

                    {item.buyer_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.buyer_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.seller_status === 'Pending' && (
                      <span className='admindashboard__yellow-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Approved' && (
                      <span className='admindashboard__green-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Rescheduled' && (
                      <span className='admindashboard__blue-flag'>
                        {item.seller_status}
                      </span>
                    )}

                    {item.seller_status === 'Declined' && (
                      <span className='admindashboard__red-flag'>
                        {item.seller_status}
                      </span>
                    )}
                  </td>

                  <td>
                    {item.demo_status_id === 1 && (
                      <span className='admindashboard__yellow-flag'>Pending</span>
                    )}

                    {item.demo_status_id === 4 && (
                      <span className='admindashboard__green-flag'>Completed</span>
                    )}

                    {item.demo_status_id === 2 && (
                      <span className='admindashboard__red-flag'>Declined</span>
                    )}

                    {item.demo_status_id === 5 && (
                      <span className='admindashboard__red-flag'>Expired</span>
                    )}
                  </td>

                  <td>
                    {item.has_been_reviewed && (
                      <span className='admindashboard__green-flag'>Yes</span>
                    )}
                    
                    {item.has_been_reviewed == false && (
                      <span className='admindashboard__red-flag'>No</span>
                    )}
                  </td>
              </tr>
            ))}
          </table>
       </div>
       </>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default DemoBreakdown;