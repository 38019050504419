import './CompanyEmail.css';
import {useEffect, useState} from 'react';
import { getUserCompany, getUserProfile, validateWorkEmail, createWorkEmailValidation, checkWorkEmailValidation, refreshToken } from '../../utils/api';
import FadeIn from '../../utils/fadein';
import queryString from 'query-string';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';


const CompanyEmail = ({userType}) => {
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [companyLoaded, setCompanyLoaded] = useState(false);
  const [token, setToken] = useState('');
  const [hasToken, setHasToken] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [email, setEmail] = useState('');
  const [verification, setVerification] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    async function loadCompany(){
      let res = await getUserCompany();

      if (res.success){
        setCompany(res.data);
        setCompanyLoaded(true);
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getUserCompany();

          if (res.success){
            setCompany(res.data);
            setCompanyLoaded(true);
          }
        }
      }
    }

    async function loadUserProfile(){
      let res = await getUserProfile();

      if (res.success){
        setUserProfile(res.data);
        if (res.data.work_email_verified === true){
          setIsVerified(true);
        }

        else{
          setIsVerified(false);
        }
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await getUserProfile();

          if (res.success){
            setUserProfile(res.data);
            if (res.data.work_email_verified === true){
              setIsVerified(true);
            }

            else{
              setIsVerified(false);
            }
          }
        }
      }
    }

    async function checkToken(){
      const parsed = queryString.parse(window.location.search);
      let _token = '';

      if (parsed["token"]){
        _token = parsed["token"];
        setToken(_token);
        setHasToken(true);

        let res = await checkWorkEmailValidation(_token);

        if (res.success){
          if (res.data === true){
           setIsVerified(true);
          }

          else{
            alert('verification token is invalid');
          }
        }
      }
    }

    loadCompany();
    loadUserProfile();
    checkToken();
  }, []);

  async function handleSend(){
    if (email.length > 0){
      let res = await createWorkEmailValidation(company.website, email, company.company_id);

      if (res.success){
        if (res.data === true){
          setIsSent(true);
        }

        else{
          alert(res.message);
        }
      }

      else if (res.status === 403){
        let _refresh = await refreshToken();

        if (_refresh.success){
          localStorage.setItem('access_token', _refresh.data.accessToken);
          localStorage.setItem('refresh_token', _refresh.data.refreshToken);

          let res = await createWorkEmailValidation(company.website, email, company.company_id);

          if (res.success){
            if (res.data === true){
              setIsSent(true);
            }

            else{
              alert(res.message);
            }
          }
        }
      }
    }

    else{
      alert('Must enter a valid work email');
    }
  }

  return (
    <div className='companyemail'>
      <div className=""><button className='btn' onClick={() => navigate(-1)}>Back</button></div>
      <div className="account__header">Verify Work Email</div>

      {isSent === false && company.website && (
        <>
      <p>
        Your websites domain must match your work email's domain in order to be verified.
      </p>

      <div>
        {isVerified === false && (
          <>
        <div className='companyemail__domain'>
          <strong>Company Website</strong>: <span>{company.website}</span>
        </div>

        <div className='form-group'>
          <label htmlFor='txtEmail'>Enter Your Work Email</label>
          <div>
          <input type='email' className='input companyemail__input' id='txtEmail' placeholder='Your work email' onChange={(e) => setEmail(e.target.value)} />
          </div>
        </div>

        <div className='form-group'>
          <button className='btn' onClick={() => handleSend()}>Send verification code</button>
        </div>

        <div className='companyemail__disclaimer'>
          * If you can not verify your work email for any specific reason, please <a href='/leave-feedback' className='link'>reach out to our support team</a> and we will help to get you verified as quickly as possible!
        </div>
        </>
        )}

        {isVerified === true && (
          <>
            <div style={{marginTop: "30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{height: "15px", marginRight: "10px"}}><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"/></svg> Your work email is verified!</div>

            <div style={{marginTop: "30px"}}>
              <button className='btn btn--primary' onClick={() => navigate('/dashboard/buyer')}>Go to your Dashboard</button>
            </div>
          </>
        )}
      </div>
      </>
      )}

      {isSent === false && !company.website && companyLoaded && (
        <>
        <div>
          You must have a valid company website in order to verify your work email.
        </div>
        
        <div style={{marginTop: "20px"}}>
          <a className='btn btn--primary' href='/account/company#website'>Edit Company Profile</a>
        </div>
        </>
      )}

      {isSent === true && (
        <>
          <div className='buyerdashboard__withdraw'>
            <strong><FaRegEnvelopeOpen /> Validation email has been sent! Please check your inbox.</strong>
          </div>
        </>
      )}
    </div>
)
}

  export default CompanyEmail;