//import jwt from 'jsonwebtoken';
import {jwtDecode} from 'jwt-decode';
import { refreshToken } from '../utils/api';

function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  let _domain = 'domain=leadrpro.com';
  document.cookie =
    cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
}

export async function refresh(){
  let _refresh = await refreshToken();

  if (_refresh.success){
    localStorage.setItem('access_token', _refresh.data.accessToken);
    localStorage.setItem('refresh_token', _refresh.data.refreshToken);

    setCookie('access_token', _refresh.data.accessToken, 1); // 12.19.2022
  }
}

export function getCurrentUser() {
  let authtoken = localStorage.getItem('access_token');
  if (authtoken) {
    let decode1 = jwtDecode(authtoken);

    return decode1;
  }
  return null;
}

export function getRole(){
  return 1;
}

  export function isAuthed(){
    let authtoken = localStorage.getItem('access_token');
    if (authtoken){
      let decode1 = jwtDecode(authtoken);
      if (decode1)
      {
        return true;
      }

      else{
      }
    }
    return false;
  }

  // check roles for proper role_id
  export function isBuyer(){
    let authtoken = localStorage.getItem('access_token');
    if (authtoken){
      let decode1 = jwtDecode(authtoken);
      if (decode1)
      {
        let roles = decode1.roles;
        if (Array.isArray(roles)){
          let role = roles.find(item => item.role_type_id === 1);

          if (role){
            return true;
          }
        }
      }

      else{
      }
    }
    return false;
  }

  export function isSeller(){
    let authtoken = localStorage.getItem('access_token');
    if (authtoken){
      let decode1 = jwtDecode(authtoken);
      if (decode1)
      {
        let roles = decode1.roles;
        if (Array.isArray(roles)){
          let role = roles.find(item => item.role_type_id === 2);

          if (role){
            return true;
          }
        }

        return false;
      }

      else{
        return false;
      }
    }
    return false;
  }

  // check roles for proper role_id
  export function isEventHost(){
    let authtoken = localStorage.getItem('access_token');
    if (authtoken){
      let decode1 = jwtDecode(authtoken);
      if (decode1)
      {
        let roles = decode1.roles;
        if (Array.isArray(roles)){
          let role = roles.find(item => item.role_type_id === 4);

          if (role){
            return true;
          }
        }
      }

      else{
      }
    }
    return false;
  }

  // check roles for proper role_id
  export function isEventAttendee(){
    let authtoken = localStorage.getItem('access_token');
    if (authtoken){
      let decode1 = jwtDecode(authtoken);
      if (decode1)
      {
        let roles = decode1.roles;
        if (Array.isArray(roles)){
          let role = roles.find(item => item.role_type_id === 5);

          if (role){
            return true;
          }
        }
      }

      else{
      }
    }
    return false;
  }

  export function isAdmin(){
    let authtoken = localStorage.getItem('access_token');
    if (authtoken){
      let decode1 = jwtDecode(authtoken);
      if (decode1)
      {
        let roles = decode1.roles;
        if (Array.isArray(roles)){
          let role = roles.find(item => item.role_type_id === 3);

          if (role){
            return true;
          }
        }
      }

      else{
      }
    }
    return false;
  }

  export function logout(){
    localStorage.setItem('access_token', '');
  }