import './Team.css';
import {useEffect, useState} from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { companyInvite, getCompanyInvites, getCompanyTeam, refreshToken, deleteCompanyInvite, getSellerRoleTypes, updateSellerRole, removeSellerRole, getAdditionalTeamStatus, getSellerRole, getUserCompany, updateDefaultSellerId } from '../../utils/api';
import iconCompanyDefault from '../../assets/companylogos/default.png';
import React from 'react';
import { FaRegEdit, FaRegCalendarCheck, FaRegCalendarTimes, FaRegCircle, FaTrash, FaTrashAlt, FaShapes, FaRegTrashAlt, FaTimes, FaPlus } from "react-icons/fa";
import { refresh } from '../../services/auth.service';
import FadeIn from '../../utils/fadein';
import Spinner from '../../components/layout/Spinner';

const Team = () => {
  const [showModal, setShowModal] = useState(false);
  const [invites, setInvites] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [loadingTeam, setLoadingTeam] = useState(true);
  const [roleTypes, setRoleTypes] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showAddons, setShowAddons] = useState(false);
  const [showAddonsModal, setShowAddonsModal] = useState(false);
  const [additionalTeamEnabled, setAdditionalTeamEnabled] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showTeamLead, setShowTeamLead] = useState(false);
  const [company, setCompany] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  let refEmail = React.createRef();
  let refName = React.createRef();
  let refRole = React.createRef();
  let refEditRole = React.createRef();
  const navigate = useNavigate();

  async function loadCompany(){
    let res = await getUserCompany();

    if (res.success){
      setCompany(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getUserCompany();

      if (res.success){
        setCompany(res.data);
      }
    }
  }

  useEffect(() => {
    async function loadRoleTypes(){
      let res = await getSellerRoleTypes();

      if (res.success){
        setRoleTypes(res.data);
      }
    }

    loadRoleTypes();
    loadCompany();
  }, []);

  async function checkAdminStatus(){
    let res = await getSellerRole();

    if (res.success){
      if (res.data == '1'){
        setIsAdmin(true);
      }
    }

    else if (res.status === 403){
      await refresh();
      let res = await getSellerRole();

      if (res.success){
        if (res.data == '1'){
          setIsAdmin(true);
        }
      }
    }
  }

  async function loadInvites(){
    let res = await getCompanyInvites();

    if (res.success){
      setInvites(res.data);
    }

    else{
      if (res.status === 403){
        await refresh();
        let res = await getCompanyInvites();

        if (res.success){
          setInvites(res.data);
        }
      }
    }
   }
   
   async function loadTeam(){
    let res = await getCompanyTeam();

    if (res.success){
      setTeamMembers(res.data);
      setLoadingTeam(false);
      if (res.data.length > 3){
        setShowAddons(true);
      }
    }

    else if (res.status === 403){
        await refresh();

        let res = await getCompanyTeam();

        if (res.success){
          setTeamMembers(res.data);
          setLoadingTeam(false);

          if (res.data.length > 3){
            setShowAddons(true);
          }
        }
    }
   }

   async function loadStatus(){
    let res = await getAdditionalTeamStatus();

    if (res.success){
      if (res.data == 'Enabled')
        setAdditionalTeamEnabled(true);
      else
        setAdditionalTeamEnabled(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getAdditionalTeamStatus();

      if (res.success){
        if (res.data == 'Enabled')
          setAdditionalTeamEnabled(true);
        else
          setAdditionalTeamEnabled(false);
      }
    }

    else{
      setAdditionalTeamEnabled(false);
    }
   }

  useEffect(async () => {
    await checkAdminStatus();
    await loadStatus();
    await loadInvites();
    await loadTeam();
  }, []);

  async function handleSendInvite(){
    let name = refName.current.value;
    let email = refEmail.current.value;
    let role = refRole.current.value;

    if (email === ''){
      alert("Must enter a valid email address");
      return;
    }
    setSubmitting(true);

    let res = await companyInvite(name, email, role);

    if (res.success){
      setShowModal(false);
      loadInvites();
      setSubmitting(false);
      setShowConfirm(true);
    }

    else if (res.status === 403){
      await refresh();
      let res = await companyInvite(name, email, role);

      if (res.success){
        setShowModal(false);
        loadInvites();
        setSubmitting(false);
        setShowConfirm(true);
      }
    }
  }

  async function handleInvalidate(company_invite_id){
    let res = await deleteCompanyInvite(company_invite_id);

    if (res.success){
      await loadInvites();
    }
  }

  async function handleEditClick(item){
    setShowEdit(true);
    setCurrentUser(item);
  }

  async function handleUpdateClick(){
    let res = await updateSellerRole(currentUser.registered_user_id, refEditRole.current.value);
    loadTeam();
    setShowEdit(false);
  }

  async function handleDeleteClick(item){
    setShowDelete(true);
    setCurrentUser(item);
  }

  async function handleDeleteConfirm(){
    let res = await removeSellerRole(currentUser.registered_user_id);

    if (res.success){
      setShowDelete(false);
      setShowDeleteConfirm(true);

      let filtered = teamMembers.filter(item => item.registered_user_id !== currentUser.registered_user_id);
      setTeamMembers(filtered);
    }

    else if (res.status === 403){
      await refresh();

      let res = await removeSellerRole(currentUser.registered_user_id);

      if (res.success){
        setShowDelete(false);
        setShowDeleteConfirm(true);

        let filtered = teamMembers.filter(item => item.registered_user_id !== currentUser.registered_user_id);
        setTeamMembers(filtered);
      }
    }

    else{
      alert("Something went wrong! User could not be removed");
    }
  }

  async function handleTeamLead(item){
    setSelectedUserId(item.registered_user_id);
    setShowTeamLead(true);
  }

  async function confirmTeamLead(){
    setSubmitting(true);
    let res = await updateDefaultSellerId(selectedUserId);

    if (res.success){
      setShowTeamLead(false);
      loadCompany();
      setSubmitting(false);
    }
  }

  return (
    <>
    <div className="team">
      <div><span className='btn' onClick={() => navigate(-1)}>Back</span></div>
      <div className="account__header">Team Management <span className='team__member-count'>{`(${teamMembers.length} member${teamMembers.length > 1? 's': ''})`}</span></div>
      
      {loadingTeam == false && teamMembers && teamMembers.length < 4 && (
        <div style={{position: "absolute", right: "10px", top: "10px"}}>
          <button className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => setShowModal(true)}><FaPlus /> Add team member</button>
        </div>
      )}

      {(loadingTeam == false && teamMembers && teamMembers.length > 3 && additionalTeamEnabled == true) && (
        <div style={{position: "absolute", right: "10px", top: "10px"}}>
          <button className="px-3 py-2 text-xs font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" onClick={() => setShowModal(true)}><FaPlus /> Add team member</button>
        </div>
      )}

      {loadingTeam == false && teamMembers && teamMembers.length > 3 && additionalTeamEnabled === false && (
        <div style={{position: "absolute", right: "10px", top: "10px"}}>
          <button className="btn" onClick={() => setShowAddonsModal(true)}><FaPlus /> Add team member</button>
        </div>
      )}

      <p style={{fontSize: '14px'}}>
        Add members to your team and assign them demos. 
      </p>

      <div className="team__current team__users">
        {loadingTeam && (
          <div>
            <Spinner />
          </div>
        )}

        {teamMembers.length === 0 && loadingTeam === false && (
          <>
            <div>You have not invited anyone to join your company.</div>
          </>
        )}

        {teamMembers.length > 0 && loadingTeam === false && (
          <FadeIn delay={200} duration={200}>
        <table className="table">
          <tbody>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th></th>
          </tr>

          {teamMembers.map((item, index) => (
            <tr key={index}>
              <td>
                <div style={{display: "flex", alignItems: "center"}}>
                <div className='dashboarddemo__team-member-profile-image'>
                  {item.profile_image && item.profile_image != '' && (
                    <img src={imageUrl + 'profileimages/' + item.profile_image} alt="logo found" />
                  )}

                  {!item.profile_image && (
                    <img src={iconCompanyDefault} alt="logo not found" />
                  )}
                </div>

                {item.first_name && item.first_name.length > 0 && (
                <div className='ml-10 team__name'>{item.first_name} {item.last_name}</div>
                )}

                {(!item.first_name || item.first_name.length === 0) && (
                  <i className='ml-10' style={{color:'red'}}>Name not set</i>
                )}
                </div>
                </td>

              <td>{item.email}</td>

              <td>
              {item.role && (
                <div>
                  <span className={`team__role team__role--${item.role.toLowerCase()}`}>{item.role}</span>
                </div>
              )}
              </td>

              <td>
                {isAdmin && (
                  <span className='btn' onClick={() => handleEditClick(item)}><FaRegEdit /> Edit</span>
                )}

                {isAdmin && (
                  <span className='btn ml-10' onClick={() => handleDeleteClick(item)}>
                  <FaRegTrashAlt /> Remove
                  </span>
                )}

                {isAdmin && company && company.default_seller_id != item.registered_user_id && (
                  <span className='btn ml-10' onClick={() => handleTeamLead(item)}>
                    Make team lead
                  </span>
                )}

                {isAdmin && company && company.default_seller_id == item.registered_user_id && (
                  <span className='ml-10 team__team-lead-badge'>
                    Team lead
                  </span>
                )}
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        <div className='team__legend'>
          <div><span className={`team__role team__role--admin`}>Admin</span> - Can add new team members, update payment info, update company information.</div>

          <div className='mt-10'><span className={`team__role team__role--member`}>Member</span> - Can take demo's and view team demos.</div>

          <div className='mt-10'>
            <span className='team__team-lead-badge'>Team lead</span> <span>- All new demo requests will be assigned to this person first by default.</span>
          </div>
        </div>
        </FadeIn>
        )}
      </div>

      {invites.length > 0 && isAdmin && (
      <div className="team__users" style={{marginTop: '40px'}}>
        <h2>Pending Invitations</h2>
        <table className="table">
          <tbody>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th></th>
          </tr>

          {invites.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>{item.email}</td>
              <td><span className={`team__role team__role--${item.role_type == 1? 'admin': 'member'}`}>{item.role_type === "2" ? "Member" : "Admin"}</span></td>
              <td>{item.user_count !== "0" ? <span className="team__label--approved">Approved</span> : <span className="team__label--pending">Pending</span>}</td>
              <td><span className='link' onClick={() => handleInvalidate(item.company_invite_id)}>Invalidate</span></td>
            </tr>
          ))}
          </tbody>
        </table>
      </div>
      )}

      {showAddons && showAddonsModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowAddonsModal(false)}>
              <FaTimes />
            </div>

            <div>
              You have reached the max number of users in your account. You can increase your team size by enabling it as an add-on.
            </div>

            <div className='center' style={{marginTop: "20px"}}>
              <button className='btn btn--primary team__btn-enable' onClick={() => navigate('/account/addons')}>Go to add-ons</button>
            </div>
          </div>
        </>
      )}

      {showModal && (
      <div className="overlay" id="divModal">
      <div className="modal">
        <div className="modal__close" onClick={() => setShowModal(false)}>
          <FaTimes/>
        </div>

        <h2 className='modal__h2'>Invite team member</h2>

        <div>
          <div className="form-group">
            <label className="label">Full name</label>
            <input type="text" className="input" placeholder="Enter name" ref={refName} />
          </div>

          <div className="form-group">
            <label className="label">Email address</label>
            <input type="text" className="input" placeholder="Enter email address" ref={refEmail} />
          </div>

          <div className="form-group">
            <label className="label">Role</label>
            <select className="select input" ref={refRole}>
              {roleTypes.map((item, index) => (
                <option key={index} value={item.seller_role_type_id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className="form-group">
            {submitting && (
              <Spinner />
            )}

            {!submitting && (
            <button className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" onClick={() => handleSendInvite()}>
              Send invite
            </button>
            )}
          </div>

          <div className='team__legend mt-20'>
            <div><span className={`team__role team__role--admin`}>Admin</span> - Can add new team members, update payment info, update company information.</div>

            <div className='mt-10'><span className={`team__role team__role--member`}>Member</span> - Can take demo's and view team demos.</div>

          </div>
        </div>
      </div>
      </div>
      )}

      {showEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowEdit(false)}>
              <FaTimes />
            </div>
            <h2 className='modal__h2'>Update User Role</h2>

            <div className='mt-20'>
              <i>{currentUser.email}</i>
            </div>

            <div className="form-group">
            <label className="label"><strong>Role</strong></label>
            <select className="select input" ref={refEditRole}>
              {roleTypes.map(item => (
                <option value={item.seller_role_type_id}>{item.name}</option>
              ))}
            </select>
          </div>

          <div className='form-group center'>
            <button className='btn btn--primary' onClick={() => handleUpdateClick()}>Update</button>
          </div>

          <div className='center'>
            <span className='link link--skip link--cancel' onClick={() => setShowEdit(false)}>Cancel</span>
          </div>
          </div>
        </>
      )}

      {showDelete && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className="modal__close" onClick={() => setShowDelete(false)}>
              <FaTimes />
            </div>

            <div className='modal__h2'>Are you sure you want to remove this user from your team?</div>

            <p className='mt-20'>
              This person will no longer be able to book demos with prospects. Note that all demos assigned to this person will be removed as well. Please reassign any remaining demos to another team member before deleting this user.
            </p>

            <div className='center mt-20'>
              <button className='btn btn--primary btn--danger' onClick={() => handleDeleteConfirm()}>Confirm Removal</button>
            </div>
          </div>
        </>
      )}

      {showDeleteConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <p className='center'>
              Success! This user has been removed from your companies account.
            </p>

            <div className='center' style={{marginTop: "20px"}}>
              <button className='btn btn--primary' onClick={() => setShowDeleteConfirm(false)}>Close</button>
            </div>
          </div>
        </>
      )}
    </div>

    {showConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal'>
          <div className='modal__close' onClick={() => setShowConfirm(false)}>
            <FaTimes />
          </div>

          <div>
            <h2 className='modal__h2'>Success!</h2>

            <p>
              Your teammate should receive an invitation email shortly.
            </p>

            <p>
              Hang tight and they will join your team soon.
            </p>

            <p className='center'>
              <button className='py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700' onClick={() => setShowConfirm(false)}>Close</button>
            </p>
          </div>
        </div>
      </>
    )}

    {showTeamLead && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowTeamLead(false)}>
            <FaTimes />
          </div>

          <div className='modal__h2'>
            Assign as team lead
          </div>

          <p className='mt-10'>
            Make this person the team lead? All new demo requests will be assigned to this person first by default.
          </p>

          <p className='mt-10 center'>
            {submitting === false && (
              <button className='btn' onClick={() => confirmTeamLead()}>Confirm</button>
            )}

            {submitting && (
              <Spinner />
            )}
          </p>
        </div>
      </>
    )}
    </>
  );
}

export default Team;