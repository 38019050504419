import './SelectRole.css';
import { useState, useEffect } from "react";
import React from "react";
import { getPersonaTypes, getAllIndustries, getCompanySizes, createCompany, updateSellerProfile, searchCompaniesDropdown, updatePersonaType, getUserCompany, createCompanyUser, refreshToken } from '../../utils/api';
import classNames from "classnames";
import Spinner from '../../components/layout/Spinner';
import { FaArrowRight } from 'react-icons/fa';

const SelectRole = ({roleType, userId, setCompanyExists, showSelectRole, setShowPhoneNumber, setShowProfile, setCompanyId, setCurrentStep}) => {
    const [personaTypes, setTypes] = useState([]);
    const [selectedType, setSelectedType] = useState(1);
    const [companies, setCompanies] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [companySizes, setCompanySizes] = useState([]);
    const [selectedCompanySize, setSelectedCompanySize] = useState(0);
    const [selectedIndustry, setSelectedIndustry] = useState(0);
    const [query, setQuery] = useState("");
    const [selectedCompany, setSelectedCompany] = useState(0);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showCompanyDetails, setShowCompanyDetails] = useState(true);
    const [website, setWebsite] = useState('');
    const [description, setDescription] = useState('');
    const [error, setError] = useState('');
    const [hasError, setHasError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    let refSize = React.createRef();
    let refIndustry = React.createRef();
    const imageUrl = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {
      async function loadUserCompany(){
        if (userId === 0)
          return;

        let res = await getUserCompany();

        if (res.success){
          let _company = res.data;

          setQuery(_company.name);
          setSelectedCompany(_company.company_id);
        }
      }

      async function loadCompanySizes() {
        let data = await getCompanySizes();
        if (data.data){
            setCompanySizes(data.data);
        }
      }

      async function loadIndustries() {
        let data = await getAllIndustries();
        if (data.data){
            setIndustries(data.data);
        }
      }

      loadUserCompany();
      loadCompanySizes();
      loadIndustries();
    }, [userId]);

    useEffect(() => {
      let rt = "Buyer";

      if (roleType === "2"){
        rt = "Seller";
      }

      getPersonaTypes(rt).then((result) => {
          if (result.success){
              setTypes(result.body);
          }
      });
      }, [roleType]);

      async function companySearch(query){
        let data = await searchCompaniesDropdown(query);

        if (data.data){
          setCompanies(data.data);
        }
      }

      function selectRole(id){
        setSelectedType(id);
        setHasError(false);
        setError('');
      }

      // if no name selected, reactivate
      async function handleChange(e){
        let _name = e.target.value;

        if (_name.length > 0){
          setShowCompanyDetails(true);
          setShowDropdown(true);
          setCompanies([]);
          setSelectedCompany(0);
          setQuery(e.target.value);
          await companySearch(e.target.value);
        }

        else{
          setCompanies([]);
          setShowDropdown(false);
          setSelectedCompany(0);
          setQuery(e.target.value);
          setShowCompanyDetails(true);
        }
      }

      async function handleWebsiteChange(e){
        setWebsite(e.target.value);
      }

      async function handleDescriptionChange(e){
        setDescription(e.target.value);
      }

      // selecting company from dropdown
      async function handleClick(id, name){
        setSelectedCompany(id);
        setShowDropdown(false);
        setQuery(name);
        setShowCompanyDetails(false);
      }

      async function handleSubmit(e){
          e.preventDefault();

          if (selectedCompany !== 0){
            setSubmitting(true);

            // company exists
            setCompanyExists(true);
            setCompanyId(selectedCompany);

            let res1 = await createCompanyUser(selectedCompany);

            if (res1.status === 403){
              let _refresh = await refreshToken();

              if (_refresh.success){
                localStorage.setItem('access_token', _refresh.data.accessToken);
                localStorage.setItem('refresh_token', _refresh.data.refreshToken);

                let res1 = await createCompanyUser(selectedCompany);
              }
            }

            setSubmitting(false);
          }

          else if(query.trim().length > 0){
            setSubmitting(true);

            // new company
            setCompanyExists(false);
            let company_size_id = refSize.current.value;
            let industry_id = refIndustry.current.value;
            let result = await createCompany(query, company_size_id, industry_id, website, description);

            if (result.success){
              setCompanyId(result.data);
            }

            else if (result.status === 403){
              let _refresh = await refreshToken();

              if (_refresh.success){
                localStorage.setItem('access_token', _refresh.data.accessToken);
                localStorage.setItem('refresh_token', _refresh.data.refreshToken);

                let result2 = await createCompany(query, company_size_id, industry_id, website, description);

                if (result2.success){
                  setCompanyId(result2.data);
                }
              }
            }
          }

          else{
            setHasError(true);
            setError("Must select a valid company name");
            return;
          }

          if (roleType === 1)
          {
            await updatePersonaType(selectedType, roleType);
          }

          if (roleType !== "2"){
            // company exists
            if (selectedCompany !== 0){
              setShowPhoneNumber(false);
              setCurrentStep(4);
            }

            else{
              setCurrentStep(4);
            }
          }

          else{
            setCurrentStep(4);
          }
      }

      return (
        <>
        <div className="card__wrapper center" onClick={()=> setShowDropdown(false)}>
          <form onSubmit={(e) => handleSubmit(e)}>
          <div className="card">
            <div className="card__inner selectrole__left-side">
              <p className='selectrole__header'>
                <span style={{fontWeight: "bold", fontSize: "20px"}}>1. Tell us a little about where you work</span>
              </p>

              <div className='form-group'>
                <label className="label input-label" htmlFor='txtName'>Company name</label>
                <input type="text" id='txtName' className="input" onChange={handleChange} placeholder="Enter here" value={query} autoComplete='off' required></input>

                {companies.length > 0 && showDropdown && (
              <div className="selectrole__company-dropdown-wrapper">
              <div className="selectrole__company-dropdown">
                  {companies.map((item, index) => (
                      <div key={index} onClick={() => handleClick(item.company_id, item.name)} className="selectrole__company-dropdown-item" id={"company_" + item.company_id}>
                        <span style={{height: "30px", width: "30px", overflow: "none", borderRadius: "50%", display: "inline-block", verticalAlign: "middle"}}>
                          <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} style={{width: "100%"}} />
                        </span>
                        <span style={{marginLeft: "10px", verticalAlign: "middle", width: "80%"}}>{item.name}</span>
                      </div>
                  ))}
                  </div>
              </div>
              )}
              </div>

              {showCompanyDetails === true && (
              <>

          <div style={{display: 'flex'}}>
          <div className="form-group"> 
            <label className='label input-label' htmlFor='selectSize'>Company size</label>               
              <select
                  className="input"
                  type='text'
                  id='selectSize'
                  ref={refSize}
                  value={selectedCompanySize}
                  onChange={(e) => setSelectedCompanySize(e.target.value)}
                  name='companysize'
                  required>
                  {companySizes.length > 0 && (
                      companySizes.map((item, index) => (
                          <option key={index} value={item.company_size_id}>
                              {item.value}
                          </option>
                      ))
                  )}
              </select>
            </div>

            <div className="form-group" style={{marginLeft: '10px'}}> 
            <label className='label input-label' htmlFor='selectIndustry'>Industry</label>               
              <select className="input" type='text' id='selectIndustry' ref={refIndustry} value={selectedIndustry}
                  onChange={(e) => setSelectedIndustry(e.target.value)}
                  name='companysize'
                  required>
                    <option value="0">Select your industry...</option>
                  {industries.length > 0 && (
                      industries.map((item, index) => (
                          <option key={index} value={item.industry_id}>
                              {item.name}
                          </option>
                      ))
                  )}
              </select>
            </div>
            </div>

            <div className='form-group'>
              <label className='label input-label' htmlFor='website'>Company Website</label>
              <input type="url" id='txtWebsite' className="input" onChange={handleWebsiteChange} placeholder="Enter here" autoComplete='off' value={website} required></input>
            </div>

            <div className='form-group'>
              <label className='label input-label' htmlFor='txtDescription'>What does your company do? {'(Short sentence...)'}</label>
              <textarea type="text" id='txtDescription' className="input" onChange={handleDescriptionChange} placeholder="Enter here" value={description} required></textarea>
            </div>
            </>
              )}

                {/* <h2 className="selectroleseller__text--medium">How would you describe your role at your company</h2>
                <div className="selectrole__persona-types">
                    {personaTypes.map((item, index) => {
                        return (
                            <div key={index} className={classNames('selectrole__persona-type', {"selectrole__persona-type--selected": selectedType===item.persona_type_id})} id={`role_${item.persona_type_id}`} onClick={() => selectRole(item.persona_type_id)}>
                                <div className="icon-image role-icon">
                                    <img src={`/assets/personatypes/${item.icon_image}`} alt="" />
                                </div>
                                
                                <div className="persona-type-name">{item.name}</div>
                            </div>
                        )
                    })}
                </div> */}

                <div className="center" style={{marginTop: "29px"}}>
                  {submitting === false && (
                  <button className="btn btn--primary"><span style={{verticalAlign: 'middle'}}>Next</span> </button>
                  )}

                  {submitting && (
                    <Spinner />
                  )}
                </div>

                <div className="form-group">
                  <div className="label label--error">{error}</div>
                </div>
            </div>

            
        </div>
        </form>
        </div>
        </>
    )
}

export default SelectRole;