import './Addons.css';
import {useEffect, useState} from 'react';
import {refresh} from '../../services/auth.service';
import { getAddons, enableAddon, disableAddon, checkHasPaymentMethod } from '../../utils/api';
import FadeIn from '../../utils/fadein';
import {Link, Routes, Route, useNavigate} from 'react-router-dom';
import { FaComment, FaUser, FaChartPie, FaRegEnvelope, FaUserCog, FaRegComments, FaLink, FaShapes, FaRegCheckCircle, FaTimes, FaRegCreditCard } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import close from '../../assets/design/dashboard/close.png';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../components/layout/ScrollToTop';

const Addons = () => {
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmDisable, setShowConfirmDisable] = useState(false);
  const [showCongrats, setShowCongrats] = useState(false);
  const [showDisableCongrats, setShowDisableCongrats] = useState(false);
  const [currentAddonId, setCurrentAddonId] = useState(null);
  const [enabling, setEnabling] = useState(false);
  const [disabling, setDisabling] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentCheckLoaded, setPaymentCheckLoaded] = useState(false);
  const [showAddPaymentMethod, setShowAddPaymentMethod] = useState(false);
  const navigate = useNavigate();

  async function loadAddons(){
    let res = await getAddons();

    if (res.success){
      setAddons(res.data);
      setLoading(false);
    }

    else if (res.status === 403){
      await refresh();
      let res = await getAddons();

      if (res.success){
        setAddons(res.data);
        setLoading(false);
      }
    }

    else{

    }
  }

  useEffect(() => {
    async function loadHasPayment(){
      let res = await checkHasPaymentMethod();

      if (res.success){
        setHasPaymentMethod(res.data);
        setPaymentCheckLoaded(true);
      }
    }

    loadHasPayment();
    loadAddons();
  }, []);

  async function handleEnableClick(id){
    if (paymentCheckLoaded == true && hasPaymentMethod == false){
      setShowAddPaymentMethod(true);
    }

    else if (paymentCheckLoaded == true) {
      setCurrentAddonId(id);
      setShowConfirm(true);
    }
  }

  async function confirmAddon(){
    setEnabling(true);
    let res = await enableAddon(currentAddonId);

    if (res.success){
      setShowConfirm(false);
      setShowCongrats(true);

      loadAddons();
      setEnabling(false);
    }

    else if (res.status === 403){
        await refresh();

        let res = await enableAddon(currentAddonId);

        if (res.success){
          setShowConfirm(false);
          setShowCongrats(true);

          loadAddons();
          setEnabling(false);
        }
    }

    else{

    }
  }

  async function handleDisableClick(id){
    setCurrentAddonId(id);
    setShowConfirmDisable(true);
  }

  async function confirmDisable(){
    setDisabling(true);
    let res = await disableAddon(currentAddonId);

    if (res.success){
     setShowDisableCongrats(true);
      setShowConfirmDisable(false);

      loadAddons();
      setDisabling(false);
    }

    else if (res.status === 403){
        await refresh();
        let res = await disableAddon(currentAddonId);

        if (res.success){
          setShowDisableCongrats(true);
          setShowConfirmDisable(false);

          loadAddons();
          setDisabling(false);
        }
    }

    else{

    }
  }

  return (
    <>
    <ScrollToTop />
    <Helmet>
      <title>Enhance your account with Add-ons</title>
    </Helmet>
    <div className='addons'>
      <div><button className='btn' onClick={() => navigate(-1)}>Back</button></div>

      <div className="account__header">Add-ons <FaShapes />
      </div>

      <div className='addons__description'>
      Enhance your accounts functionality and take it to the next level with our exclusive add-ons.
      </div>

      {loading && (
        <Spinner />
      )}

      <div className='addons__list'>
        {addons.map(item => (
          <>
          <div className='addons__item'>
          <div className='addons__item-icon'>
            <img src={item.icon_url}/>
          </div>

          <div className='addons__item-info'>
            <div className='addons__item-name'>{item.name}</div>
            <div className='addons__item-description'>
              {item.description}

              {item.addon_id == 1 && (
                <span className='link ml-10' onClick={() => setShowMoreInfo(true)}>More Info</span>
              )}
            </div>
          </div>

          <div className='addons__item-price-wrapper'>
            {item.do_not_charge == false && (
            <div className='addons__item-price'>
              {item.vanity_price && (
                <span className='addons__item-original-price'>${item.vanity_price}</span>
              )}

              ${item.base_price} / {item.price_description}{' '}
               <span className='addon__per-month'>per month</span>
            </div>
            )}
            
            <div className='addons__item-enable'>
              {item.is_enabled === false && (
              <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={() => handleEnableClick(item.addon_id)}>Enable</button>
              )}

              {item.is_enabled && (
                <>
                  <div className='addons__enabled-label'><FaRegCheckCircle style={{fill: '#55df55', verticalAlign: "middle"}} /> <span style={{color: "#55df55", verticalAlign: "middle"}}>Enabled</span></div>
                  <span className='addons__link-disable' onClick={() => handleDisableClick(item.addon_id)}>Disable</span>
                </>
              )}
          </div>
          </div>
        </div>
          </>
        ))}
      </div>
    </div>

    {showConfirm && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowConfirm(false)}>
            <FaTimes />
          </div>

          <h2 className='modal__h2'>Enable this add-on?</h2>

          <p>
            You will be able to start using this feature right away.
          </p>

          <p className='addon__disclaimer'>
            * You will be billed separately for this feature at the end of each calendar month.
          </p>

          <p style={{marginTop: "20px"}}>
            {enabling === false && (
            <button className='btn btn--primary addons__btn-enable' onClick={() =>confirmAddon()}>Enable</button>
            )}

            {enabling && (
              <Spinner />
            )}
          </p>
        </div>
      </>
    )}

    {showConfirmDisable && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <div className='modal__close' onClick={() => setShowConfirmDisable(false)}><img src={close} /></div>

        <h2 className='modal__h2'>Disable this add-on?</h2>

        <p>
          You will lose access to this feature starting today.
        </p>

        <p className='addon__disclaimer'>
          * You billing cycle for this feature will end at the end of the current calendar month.
        </p>

        <p style={{marginTop: "20px"}}>
          {disabling === false && (
          <button className='btn btn--primary addons__btn-disable' onClick={() =>confirmDisable()}>Disable</button>
          )}

          {disabling && (
            <Spinner />
          )}
        </p>
      </div>
    </>
    )}

    {showCongrats && (
      <>
      <div className='overlay'></div>
      <div className='modal addon__modal-enabled'>
        <div className='modal__close' onClick={() => setShowCongrats(false)}><img src={close} /></div>

        <h2 className='modal__h2'>Add-on has been enabled! ✅</h2>

        {currentAddonId == 1 && (
        <div style={{marginTop: "15px"}}>
          <img src='https://ik.imagekit.io/ivgrhmd4k/custom-link.gif?updatedAt=1684880463091' />
        </div>
        )}

        <p style={{marginTop: "10px"}}>
          You now have access to this feature. Enjoy!
        </p>

        <p style={{marginTop: "20px"}}>
          <button className='btn btn--primary addons__btn-enable' onClick={() => setShowCongrats(false)}>Close</button>
        </p>
      </div>
    </>
    )}

{showDisableCongrats && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <div className='modal__close' onClick={() => setShowDisableCongrats(false)}><img src={close} /></div>

        <h2 className='modal__h2'>Add-on has been disabled</h2>

        <p style={{marginTop: "10px"}}>
          You will no longer be able to use this feature in your account.
        </p>

        <p style={{marginTop: "20px"}}>
          <button className='btn btn--primary addons__btn-enable' onClick={() => setShowDisableCongrats(false)}>Close</button>
        </p>
      </div>
    </>
    )}

    {showMoreInfo && (
      <>
        <div className='overlay'></div>
        <div className='modal addons__modal'>
          <div className='modal__close' onClick={() => setShowMoreInfo(false)}><img src={close} /></div>

          <h2 className='modal__h2'>Custom Meeting Links</h2>
          <div>
          <p>The custom links add-on allows you to insert a meeting url from a different host (googlemeet, zoom, etc) and run your demo there. This does not, however, change any of the other demo scheduling processes. You will still review requests, schedule the call, and join the meeting from within the platform.</p>

        <p>Option 1: When choosing a time for a call.
Toggle on “Use Custom Link” and paste the link.
<br/><br/>
<img src='https://ik.imagekit.io/ivgrhmd4k/gif1.gif?updatedAt=1687494740027' /></p>

        <p>Option 2: Add to a scheduled call.
Click the Meeting Link button on the Upcoming tab, Choose Use Custom Link, Paste, and Save!
<br/><br/>
<img src='https://ik.imagekit.io/ivgrhmd4k/gif2.gif?updatedAt=1687494739976' /></p>
          </div>
        </div>
      </>
    )}

    {showAddPaymentMethod && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowAddPaymentMethod(false)}>
            <FaTimes />
          </div>

          <div>
            <h2 className='modal__h2'>Please add a valid payment method</h2>

            <div>
              To enable and begin using this addon right away, please add a valid payment method. Note that you will <strong>not</strong> be billed today. Addon charges are billed at the end of each calendar month.
            </div>

            <div className='center mt-20'>
              <Link to='/account/payment-method' className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>Add Payment Method <FaRegCreditCard /></Link>
            </div>
          </div>
        </div>
      </>
    )}
    </>
)
}

  export default Addons;