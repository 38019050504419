import './SellerDashboard.css';
import './DashboardGlobal.css';
import './Dashboard.css';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getSellerUnreadMessages,
  checkHasPaymentMethod,
  getWaitlist,
  offTheWaitlist,
  isVerified,
  resendEmailVerification
} from '../../utils/api';
import FadeIn from '../../utils/fadein';
import {
  getUserCompany,
  getCompanyPreferenceDepartment,
  getCompanyPreferenceSeniority,
  getCompanyPreferenceSize,
  getUserProfile,
  updateUser,
  getSellerDemosRescheduleCount,
  addUserEventTracking,
  getPackageItemsRemainingCount,
  getSiteNotification,
  getSellerWorkspace,
  publishCompany
} from '../../utils/api';
import { getCurrentUser, refresh, isSeller } from '../../services/auth.service';
import { Link } from 'react-router-dom';
import close from '../../assets/design/dashboard/close.png';
import { FaClock, FaUser, FaEdit, FaListOl, FaCreditCard, FaRegEnvelopeOpen, FaUsers, FaRegCalendarAlt, FaRegEdit, FaRegCalendarCheck, FaVideo, FaYoutube, FaRegCalendarTimes, FaExclamationTriangle, FaRegCircle, FaQuestion, FaExclamationCircle, FaBuilding, FaCheck, FaAppleAlt, FaGooglePlay, FaHeadset, FaTimes, FaExclamation, FaCheckCircle, FaMagic, FaBars, FaRegUser, FaChartLine, FaRegQuestionCircle, FaSearchPlus, FaShapes } from "react-icons/fa";
import Spinner from '../../components/layout/Spinner';
import ScrollToTop from '../../components/layout/ScrollToTop';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import SellerDemosAvailable from './SellerDemos_Available';
import SellerDemosUpcoming from './SellerDemos_Upcoming';
import SellerDemosIncomplete from './SellerDemos_Incomplete';
import SellerDemosCompleted from './SellerDemos_Completed';
import SellerDemosRescheduled from './SellerDemos_Rescheduled';
import SellerDemosTeam from './SellerDemos_Team';
import SellerDemosExpired from './SellerDemos_Expired';
import SellerDemosInvite from './SellerDemos_Invite';
import Company2 from '../company/Company2';
import CompanyPreview from '../company/CompanyPreview';
import CompanyFeature from './CompanyFeature';

const SellerDashboardNew = () => {
  let user = getCurrentUser();
  const [demoStatus, setDemoStatus] = useState('available');
  const [incompleteDemosCount, setIncompleteDemosCount] = useState(0);
  const [upcomingDemosCount, setUpcomingDemosCount] = useState(0);
  const [availableDemosCount, setAvailableDemosCount] = useState(0);
  const [teamDemoCount, setTeamDemoCount] = useState(0);
  const [unreadMessages, setUnreadMessages] = useState([]);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [hasCompany, setHasCompany] = useState(false);
  const [companyLoaded, setCompanyLoaded] = useState(false);
  const [loadedProfile, setLoadedProfile] = useState(false);
  const [loadedWorkhours, setLoadedWorkhours] = useState(false);
  const [loadedQuestions, setLoadedQuestions] = useState(false);
  const [loadedIcp, setLoadedIcp] = useState(false);
  const [onboardingLoaded, setOnboardingLoaded] = useState(false);
  const [messagesLoaded, setMessagesLoaded] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [hasQuestions, setHasQuestions] = useState(false);
  const [hasWorkhours, setHasWorkhours] = useState(false);
  const [hasIcp, setHasIcp] = useState(false);
  const [hasProfile, setHasProfile] = useState(false);
  const [hasThumbnail, setHasThumbnail] = useState(false);
  const [hasCompanyLogo, setHasCompanyLogo] = useState(false);
  const [hasCompanyDescription, setHasCompanyDescription] = useState(false);
  const [company, setCompany] = useState({});
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [paymentMethodLoaded, setPaymentMethodLoaded] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
  const [showEventBanner, setShowEventBanner] = useState(false);
  const [waitlist, setWaitlist] = useState([]);
  const [showUnread, setShowUnread] = useState(false);
  const [showRescheduleBanner, setShowRescheduleBanner] = useState(false);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [showPh, setShowPh] = useState(false);
  const [remainingCount, setRemainingCount] = useState(0);
  const [remainingCountLoaded, setRemainingCountLoaded] = useState(false);
  const [showRemaining, setShowRemaining] = useState(false);
  const [showWelcomeBanner, setShowWelcomeBanner] = useState(false);
  const [showVerificationError, setShowVerificationError] = useState(true);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [workspace, setWorkspace] = useState(null);
  const [isPublishing, setIsPublishing] = useState(false);
  const [publishComplete, setPublishComplete] = useState(false);
  const [showEnterName, setShowEnterName] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseimageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    if (isSeller() == false){
      navigate('/manage-roles');
    }
      const parsed = queryString.parse(window.location.search);
      let tab = parsed['t'];

      if (tab && tab.length > 0)
        setDemoStatus(tab);
  }, []);

  async function checkSiteNotification(){
    let res = await getSiteNotification('seller_welcome');

    if (res.success){
      if (res.data){
        setShowWelcomeBanner(false);
      }

      else{
        setShowWelcomeBanner(true);
      }
    }

    else if (res.status === 403){
      await refresh();
      let res = await getSiteNotification('seller_welcome');

      if (res.success){
        if (res.data){
          setShowWelcomeBanner(false);
        }

        else{
          setShowWelcomeBanner(true);
        }
      }
    }
  }

  async function loadWorkspace(){
    let res = await getSellerWorkspace();

    if (res.success){
      setWorkspace(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getSellerWorkspace();

      if (res.success){
        setWorkspace(res.data);
      }
    }
  }

  async function loadRemainingCount(){
    let res = await getPackageItemsRemainingCount();

    if (res.success){
      setRemainingCount(res.data);
      setRemainingCountLoaded(true);
    }
  }
  
  useEffect(async () => {
    async function checkIsVerified() {
      let res = await isVerified();

      if (res.success) {
        if (res.data === true) setShowVerificationError(false);
        else setShowVerificationError(true);
        setLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await isVerified();

        if (res.success) {
          if (res.data === true) setShowVerificationError(false);
          else setShowVerificationError(true);
          setLoaded(true);
        }
      }
    }

    checkIsVerified();

    async function loadPaymentMethod() {
      let res = await checkHasPaymentMethod();

      if (res.success) {
        setHasPaymentMethod(res.data);
      }
      
      else if (res.status === 403) {
        await refresh();

        let res = await checkHasPaymentMethod();

        if (res.success) {
          setHasPaymentMethod(res.data);
        }
      }

      setPaymentMethodLoaded(true);
    }

    async function loadCompany() {
      let res = await getUserCompany();

      if (res.success) {
        setCompany(res.data);

        if (res.data.questions.length > 0) {
          setHasQuestions(true);
        }

        setShowWaitlist(res.data.on_waitlist);

        if (res.data.description === null || res.data.description === '') {
          setHasProfile(false);
        } else {
          setHasProfile(true);
        }

        if (parseInt(res.data.work_hours) > 0) {
          setHasWorkhours(true);
        } else {
          setHasWorkhours(false);
        }

        if (res.data.thumbnail_image && res.data.thumbnail_image.length > 0) {
          setHasThumbnail(true);
        }

        if (res.data.logo && res.data.logo.length > 0){
          setHasCompanyLogo(true);
        }

        if (res.data.description && res.data.description.length > 0){
          setHasCompanyDescription(true);
        }

        setLoadedProfile(true);
        setLoadedQuestions(true);
        setLoadedWorkhours(true);
      } else if (res.status === 403) {
        await refresh();

          let res = await getUserCompany();

          if (res.success) {
            setCompany(res.data);

            if (res.data.questions.length > 0) {
              setHasQuestions(true);
            }

            if (res.data.description === '') {
              setHasProfile(false);
            } else {
              setHasProfile(true);
            }

            if (parseInt(res.data.work_hours) > 0) {
              setHasWorkhours(true);
            } else {
              setHasWorkhours(false);
            }

            if (res.data.thumbnail_image && res.data.thumbnail_image.length > 0) {
              setHasThumbnail(true);
            }

            if (res.data.logo && res.data.logo.length > 0){
              setHasCompanyLogo(true);
            }
    
            if (res.data.description && res.data.description.length > 0){
              setHasCompanyDescription(true);
            }

            setLoadedProfile(true);
            setLoadedQuestions(true);
            setLoadedWorkhours(true);
          }
      }

      else{
        setHasCompany(false);
        window.location = '/account/company';
      }

      setCompanyLoaded(true);
    }

    async function checkTimezone(timezone){
      if (timezone == null || timezone == undefined || timezone == ''){
        let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (l_timezone){
          let res = await updateUser({timezone: l_timezone});

          if (res.status === 403){
            await refresh();
            let res = await updateUser({timezone: l_timezone});
          }
        }
      }
    }

    async function loadUserProfile() {
      let res = await getUserProfile();

      if (res.success) {
        setProfileLoaded(true);
        setUserProfile(res.data);
        await checkTimezone(res.data.tz);
        if (res.data.first_name === null){
          setShowEnterName(true);
        }
      }

      else if (res.status === 403){
        await refresh();
        let res = await getUserProfile();

        if (res.success) {
          setProfileLoaded(true);
          setUserProfile(res.data);
          await checkTimezone(res.data.tz);

          if (res.data.first_name === null){
            setShowEnterName(true);
          }
        }
      }
    }

    async function checkIcp() {
      let res1 = await getCompanyPreferenceDepartment();
      let res2 = await getCompanyPreferenceSeniority();
      let res3 = await getCompanyPreferenceSize();

      if (
        res1.data.length === 0 &&
        res2.data.length === 0 &&
        res3.data.length === 0
      ) {
        setHasIcp(false);
      } else {
        setHasIcp(true);
      }

      setLoadedIcp(true);
    }

    async function checkRescheduleCount(){
      let res = await getSellerDemosRescheduleCount();

      if (res.success){
        let count = parseInt(res.data);

        if (count > 0){
          setShowRescheduleBanner(true);
          setShowRescheduleModal(true);
        }
      }

      else if (res.status === 403){
        await refresh();

        let res = await getSellerDemosRescheduleCount();

        if (res.success){
          let count = parseInt(res.data);

          if (count > 0){
            setShowRescheduleBanner(true);
            setShowRescheduleModal(true);
          }
        }
      }
    }

    await loadCompany();
    await checkIcp();
    await loadUserProfile();
    await loadPaymentMethod();
    await checkRescheduleCount();
    await loadRemainingCount();
    await checkSiteNotification();
    await loadWorkspace();
  }, []);

  useEffect(() => {
    if (loadedProfile && loadedQuestions && loadedWorkhours && loadedIcp && paymentMethodLoaded) {
      if (
        hasProfile === false ||
        hasQuestions === false ||
        hasWorkhours === false ||
        hasIcp === false
      ) {
        setShowOnboarding(true);
      } else {
        setShowOnboarding(false);
      }

      setOnboardingLoaded(true);
    }
  }, [loadedProfile, loadedQuestions, loadedWorkhours, loadedIcp, paymentMethodLoaded]);

  useEffect(() => {
    // waitlist
    async function loadWaitlist(){
      let res = await getWaitlist();

      if (res.success){
        setWaitlist(res.data);
      }
    }

    loadWaitlist();
  }, [company]);

  useEffect(() => {
    async function loadUnreadMessages() {
      let res = await getSellerUnreadMessages();

      if (res.success) {
        if (res.data.length > 0) {
          setHasUnreadMessages(true);
          setUnreadMessages(res.data);
          setShowUnread(true);
        }

        setMessagesLoaded(true);
      }

      else if (res.status === 403){
        await refresh();

        let res = await getSellerUnreadMessages();

        if (res.success) {
          if (res.data.length > 0) {
            setHasUnreadMessages(true);
            setUnreadMessages(res.data);
            setShowUnread(true);
          }

          setMessagesLoaded(true);
        }
      }
      
      else {
        setMessagesLoaded(true);
      }
    }

    loadUnreadMessages();
  }, []);

  useEffect(async () => {
    await loadRemainingCount();
  }, [upcomingDemosCount]);

  async function handleVerificationClick() {
    let _res = await resendEmailVerification();

    if (_res.success) {
      setVerificationSuccess(true);
    }
  }

  function setStatus(status) {
    setDemoStatus(status);
    replaceQueryParam('t', status);
  }

  function replaceQueryParam(param, newValue) {
    // Create a URL object from the current page URL
    const url = new URL(window.location.href);
  
    // Access the URL's query string parameters
    const params = url.searchParams;
  
    // Set (or replace) the parameter
    params.set(param, newValue);
  
    // Update the URL in the address bar without reloading the page
    window.history.replaceState({}, '', `${url.pathname}?${params}`);
  }

  function handleWaitlistClick(){
    setShowWaitlistConfirm(true);
  }

  async function handleWaitlistConfirm(){
    let res = await offTheWaitlist();

    if (res.success){
      window.location = window.location;
    }
  }

  async function phClick(){
    let res = await addUserEventTracking('Seller Product Hunt Click');
  }

  async function handleRemainingClick(){
    setShowRemaining(true);
  }

  async function handlePublish(){
    setIsPublishing(true);

    let res = await publishCompany();

    if (res.success){
      setIsPublishing(false);
      setPublishComplete(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await publishCompany();

      if (res.success){
        setIsPublishing(false);
        setPublishComplete(true);
      }
    }
  }

  async function handleUpdateName(){
    if (firstName.length === 0){
      alert("Please enter a valid name");
      return;
    }

    let res = await updateUser({first_name: firstName, last_name: lastName});

    if (res.success){
      setShowEnterName(false);
    }

    else if (res.status === 403){
      await refresh();

      let res = await updateUser({first_name: firstName, last_name: lastName});

      if (res.success){
        setShowEnterName(false);
      }
    }
  }

  return (
    <div>
      <ScrollToTop />
      <Helmet>
        <title>LeadrPro - My Dashboard</title>
      </Helmet>
      
      {onboardingLoaded === false && (
        <Spinner />
      )}

      {onboardingLoaded && showOnboarding === false && (
        <>
            {showPh && (
              <FadeIn delay={500} duration={500}>
                <div className='account__product-hunt'>
                  <strong>LeadrPro will be launching our mobile app on Product Hunt on <a href='https://www.producthunt.com/products/leadrpro' target='_blank' onClick={() => phClick()}>February 14, 2024</a>. Please support us and the LeadrPro community by upvoting us <a href='https://www.producthunt.com/products/leadrpro' target='_blank' onClick={() => phClick()}>here</a>.</strong>

                  {/* <span className='account__product-hunt-close'><FaRegTimesCircle /></span> */}
                </div>
              </FadeIn>
            )}

          <div className='buyerdashboard__h1'>
            {remainingCountLoaded && remainingCount > 0 && company.payment_type === 'pay_now' && (
              <span onClick={() => handleRemainingClick()} className='sellerdashboard__demos-remaining inline-flex items-center px-5 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
  <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
</svg>


                <span className='ml-1'>Credit{remainingCount === 1? '': 's'} Remaining</span>
                
                <span className='inline-flex items-center justify-center w-5 h-5 ms-2 text-xs font-semibold text-blue-800 bg-blue-200 rounded-full'>{remainingCount}</span> </span>
            )}

            {remainingCountLoaded && remainingCount == 0 && company.payment_type === 'pay_now' && (
              <>
                <Link to={'/select-plan'} className='btn sellerdashboard__demos-remaining--none'><span> {remainingCount}</span> Credit{remainingCount === 1? '': 's'} Remaining</Link>
              </>
            )}
{/* 
            <button className="btn sellerdashboard__btn-links" onClick={() => setShowWelcomeBanner(true)}><FaBars /> Quick Links</button> */}

            <div className='sellerdashboard__title'>Seller Dashboard</div>
          </div>
        </>
      )}

      {showVerificationError && loaded === true && onboardingLoaded && showOnboarding === false && (
        <>
          <div className='dashboard__verification-card' onClick={() => handleVerificationClick()}>
              {verificationSuccess === false && (
                <>
                  <span>
                  <span style={{verticalAlign: "middle"}}><FaExclamationTriangle /></span>
                    <span style={{ verticalAlign: 'middle' }}>
                      Verify your email to unlock all features.
                    </span>
                  </span>{' '}
                  <span className='link'>
                    Resend verification email
                  </span>
                </>
              )}

              {verificationSuccess === true && (
                <>
                  Please check your inbox and follow instructions in
                  verification email.
                </>
              )}
            </div>
        </>
      )}

      {loaded == true && companyLoaded == true && (hasThumbnail == true && hasCompanyDescription == true && hasCompanyLogo == true && hasWorkhours == true) && company.is_seller == false && company.company_id != 375 && (
        <div className='sellerdashboard__complete-profile-wrapper done'>
        <h1 style={{fontWeight: 'bold', fontSize: '25px', marginBottom: '15px'}}>Almost there...</h1>

      {publishComplete === false && (
        <FadeIn delay={300} duration={300}>
          <h2>Your company profile is ready to go live. Publish it today and get instantly added to our software directory. Remember you only pay for demos once they have been approved and completed.</h2>
        </FadeIn>
      )}

      {publishComplete == true && (
        <FadeIn delay={300} duration={300}>
        <h2>✅ Your company profile is now live. You should start seeing interested buyers within the next 24-48hrs.</h2>
        
        <div>
          <Link className='btn btn--preview' to={`/company/${company.company_id}/company`}>View public profile</Link>
        </div>
        </FadeIn>
      )}

      {publishComplete == false && (
    <FadeIn delay={300} duration={300}>
  <div className='sellerdashboard__complete-profile'>
    <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
            
              {(company.logo == null || company.logo.length == 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon'>
                  <FaExclamationCircle />
                </div>
              )}

              {(company.logo != null && company.logo.length > 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon--selected'>
                  <FaCheckCircle />
                </div>
              )}

            <div className='sellerdashboard__welcome-quick-start-name'>Company Logo
            </div>

            {(company.logo == null || company.logo.length == 0) && (
              <div className="sellerdashboard__welcome-checkmark-pending">
                <FaExclamation />
              </div>
            )}

            {(company.logo != null && company.logo.length > 0) && (
              <div className="sellerdashboard__welcome-checkmark">
                <FaCheck />
              </div>
            )}
          </div>

          <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
            
              {(company.description == null || company.description.length == 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon'>
                  <FaExclamationCircle />
                </div>
              )}

              {(company.description != null && company.description.length > 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon--selected'>
                  <FaCheckCircle />
                </div>
              )}

            <div className='sellerdashboard__welcome-quick-start-name'>Company Description
            </div>

            {(company.description == null || company.description.length == 0) && (
              <div className="sellerdashboard__welcome-checkmark-pending">
                <FaExclamation />
              </div>
            )}

            {(company.description != null && company.description.length > 0) && (
              <div className="sellerdashboard__welcome-checkmark">
                <FaCheck />
              </div>
            )}
          </div>

          <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit#thumbnail')}>
            
              {(company.thumbnail_image == null || company.thumbnail_image.length == 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon'>
                  <FaExclamationCircle />
                </div>
              )}

              {(company.thumbnail_image != null && company.thumbnail_image.length > 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon--selected'>
                  <FaCheckCircle />
                </div>
              )}

            <div className='sellerdashboard__welcome-quick-start-name'>Thumbnail
            </div>

            {(company.thumbnail_image == null || company.thumbnail_image.length == 0) && (
              <div className="sellerdashboard__welcome-checkmark-pending">
                <FaExclamation />
              </div>
            )}

            {(company.thumbnail_image != null && company.thumbnail_image.length > 0) && (
              <div className="sellerdashboard__welcome-checkmark">
                <FaCheck />
              </div>
            )}
          </div>

          <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/workhours')}>
            
              {hasWorkhours == false && (
                <div className='sellerdashboard__welcome-quick-start-icon'>
                  <FaExclamationCircle />
                </div>
              )}

              {hasWorkhours && (
                <div className='sellerdashboard__welcome-quick-start-icon--selected'>
                  <FaCheckCircle />
                </div>
              )}

            <div className='sellerdashboard__welcome-quick-start-name'>Work Hours
            </div>

            {hasWorkhours == false && (
              <div className="sellerdashboard__welcome-checkmark-pending">
                <FaExclamation />
              </div>
            )}

            {hasWorkhours && (
              <div className="sellerdashboard__welcome-checkmark">
                <FaCheck />
              </div>
            )}
          </div>

          <div className='sellerdashboard__welcome-checklist-item' onClick={() => setShowPreview(true)}>
            <div className='sellerdashboard__welcome-quick-start-icon'>
              <FaSearchPlus />
            </div>

            <div className='sellerdashboard__welcome-quick-start-name'>Preview Profile
            </div>
          </div>
  </div>
  </FadeIn>
  )}

  <div style={{marginTop: '20px'}}>
    {isPublishing === false && publishComplete === false && (
    <button className='btn btn--publish' onClick={() => handlePublish()}>Publish <FaMagic /></button>
    )}

    {isPublishing && publishComplete == false && (
      <div>Publishing...</div>
    )}
  </div>
</div>
)}

{/* --------------------------------------------------- */}
      {loaded == true && companyLoaded == true && (hasThumbnail == false || hasCompanyDescription == false || hasCompanyLogo == false || hasWorkhours == false) && (
        <div className='sellerdashboard__complete-profile-wrapper'>
          <h1 style={{fontWeight: 'bold', fontSize: '30px'}}>Hang on...</h1>
          <h2>⚠️ Your company is not listed in our directory just yet. Complete the following items in order to go live. It's free to list and you only pay for any completed demos you approve.</h2>

          <div className='sellerdashboard__complete-profile'>
            <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
                    
            {(company.logo == null || company.logo.length == 0) && (
              <div className='sellerdashboard__welcome-quick-start-icon'>
                <FaExclamationCircle />
              </div>
            )}

            {(company.logo != null && company.logo.length > 0) && (
              <div className='sellerdashboard__welcome-quick-start-icon--selected'>
                <FaCheckCircle />
              </div>
            )}

            <div className='sellerdashboard__welcome-quick-start-name'>Company Logo
            </div>

            {(company.logo == null || company.logo.length == 0) && (
              <div className="sellerdashboard__welcome-checkmark-pending">
                <FaExclamation />
              </div>
            )}

            {(company.logo != null && company.logo.length > 0) && (
              <div className="sellerdashboard__welcome-checkmark">
                <FaCheck />
              </div>
            )}
          </div>

            <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit#thumbnail')}>
              
              {(company.thumbnail_image == null || company.thumbnail_image.length == 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon'>
                  <FaExclamationCircle />
                </div>
              )}

              {(company.thumbnail_image != null && company.thumbnail_image.length > 0) && (
                <div className='sellerdashboard__welcome-quick-start-icon'>
                  <FaCheckCircle />
                </div>
              )}

            <div className='sellerdashboard__welcome-quick-start-name'>Thumbnail
            </div>

            {(company.thumbnail_image == null || company.thumbnail_image.length == 0) && (
              <div className="sellerdashboard__welcome-checkmark-pending">
                <FaExclamation />
              </div>
            )}

            {(company.thumbnail_image != null && company.thumbnail_image.length > 0) && (
              <div className="sellerdashboard__welcome-checkmark">
                <FaCheck />
              </div>
            )}
          </div>

            <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
              
                {(company.description == null || company.description.length == 0) && (
                  <div className='sellerdashboard__welcome-quick-start-icon'>
                    <FaExclamationCircle />
                  </div>
                )}

                {(company.description != null && company.description.length > 0) && (
                  <div className='sellerdashboard__welcome-quick-start-icon--selected'>
                    <FaCheckCircle />
                  </div>
                )}

              <div className='sellerdashboard__welcome-quick-start-name'>Company Description
              </div>

              {(company.description == null || company.description.length == 0) && (
                <div className="sellerdashboard__welcome-checkmark-pending">
                  <FaExclamation />
                </div>
              )}

              {(company.description != null && company.description.length > 0) && (
                <div className="sellerdashboard__welcome-checkmark">
                  <FaCheck />
                </div>
              )}
            </div>

            <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/workhours')}>
              
                {hasWorkhours == false && (
                  <div className='sellerdashboard__welcome-quick-start-icon'>
                    <FaExclamationCircle />
                  </div>
                )}

                {hasWorkhours && (
                  <div className='sellerdashboard__welcome-quick-start-icon--selected'>
                    <FaCheckCircle />
                  </div>
                )}

              <div className='sellerdashboard__welcome-quick-start-name'>Work Hours
              </div>

              {hasWorkhours == false && (
                <div className="sellerdashboard__welcome-checkmark-pending">
                  <FaExclamation />
                </div>
              )}

              {hasWorkhours && (
                <div className="sellerdashboard__welcome-checkmark">
                  <FaCheck />
                </div>
              )}
            </div>

            <div className='sellerdashboard__welcome-checklist-item' onClick={() => setShowPreview(true)}>
              <div className='sellerdashboard__welcome-quick-start-icon'>
                <FaSearchPlus />
              </div>

              <div className='sellerdashboard__welcome-quick-start-name'>Preview Profile
              </div>
            </div>
          </div>
        </div>
      )}

      {/* ------------------------------------------------------ */}

        <div className='dashboard__notifications'>
          {loaded == true && onboardingLoaded == true && showOnboarding == false && workspace && workspace.available_demos != '0' && (
            <>
            <div className='dashboard__notification-card' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => {
                  setStatus('team')
                }}>
              <span className='dashboard__notification-card-count'>{workspace.available_demos}</span>

              <span style={{marginLeft: '20px', borderLeft: 'solid 1px #ccc', paddingLeft: '20px'}}>
                {`New demo request${parseInt(workspace.available_demos) > 1 ? 's': ''}`}
              </span>
            </div>
          </>
          )}

          {loaded == true && onboardingLoaded == true && showOnboarding == false && workspace && workspace.upcoming_demos != '0' && (
            <>
            <div className='dashboard__notification-card' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => {
                  setStatus('upcoming')
                }}>
              <span className='dashboard__notification-card-count'>{workspace.upcoming_demos}</span>

              <span style={{marginLeft: '20px', borderLeft: 'solid 1px #ccc', paddingLeft: '20px'}}>
                {`Scheduled demo${parseInt(workspace.upcoming_demos) > 1 ? 's': ''}`}
              </span>
            </div>
          </>
          )}

          {loaded == true && onboardingLoaded && showOnboarding == false && workspace && workspace.needs_feedback != '0' && (
                <div className='dashboard__notification-card' style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}} onClick={() => {
                  setStatus('incomplete')
                }}>
              <span className='dashboard__notification-card-count'>{workspace.needs_feedback}</span>

              <span style={{marginLeft: '20px', borderLeft: 'solid 1px #ccc', paddingLeft: '20px'}}>
                {`Completed (Needs feedback)`}
              </span>
            </div>
        )}

        {showRescheduleBanner && loaded === true && onboardingLoaded && showOnboarding === false && (
          <>
            <div className='dashboard__notification-card' onClick={() => {
                  setShowRescheduleModal(false);
                  setStatus('rescheduled')
                }}>
              <span>📅 Reschedule request pending.</span>

              <span>
                <span className='link' onClick={() => {
                  setShowRescheduleModal(false);
                  setStatus('rescheduled')
                }}> Confirm a new time</span>
              </span>
            </div>
          </>
        )}

        {/* {hasUnreadMessages && messagesLoaded && onboardingLoaded && (
          <>
            <div className='dashboard__notification-card'>
              <span><FaRegEnvelopeOpen /> You have unread messages in your inbox.</span>

              <span>
                <Link
                  to={'/dashboard/inbox'}
                  className='link'>
                  Go to your messages
                </Link>
              </span>
            </div>
          </>
        )} */}
      </div>

      <div className=''>
        {onboardingLoaded && showWaitlist && (
          <div style={{position: "relative"}}>
            <h1 className='buyerdashboard__h1'>You're on the waitlist!</h1>

            <p style={{marginBottom: "20px", marginTop: "20px"}}>
              The following companies have expressed interest in booking a demo with your company.
            </p>

            <div style={{position: "absolute", top: "0px", right: "10px"}}>
              <button className='sellerdashboard__waitlist-btn' onClick={() => handleWaitlistClick()}>Take me off the waitlist</button>
            </div>

            <div className='sellerdashboard__waitlist'>
            {waitlist.map(item => (
              <>
                <div className='dashboarddemo sellerdashboard__waitlist-item'>
                  <div className='dashboarddemo__company-logo'>
                    <img
                      src={
                        imageUrl +
                        'tr:di-@@companylogos@@default-buyer.png/' +
                        item.logo
                      }
                    />
                  </div>
                  
                  <div className='dashboarddemo__company-info'>
                    <div className='dashboarddemo__company-name'>
                      {item.name && item.name.length > 0 && (
                        <div className='dashboarddemo__name'>
                          {item.name}
                        </div>
                      )}

                    <span className=''>
                          {item.job_title && item.job_title.length > 0 && (
                            <span>{item.job_title}</span>
                          )}
                          
                          {!item.job_title && (
                            <span>{item.persona_type}</span>
                          )} at {item.name}
                        </span>
                    </div>

                    <div className='dashboarddemo__company-info-items'>
                      {item.seniority && item.seniority.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Seniority level:{' '}
                        </span>
                        <span>
                          {item.seniority}
                        </span>
                      </div>
                      )}

                      {item.department && item.department.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Department:{' '}
                        </span>
                        <span>
                          {item.department}
                        </span>
                      </div>
                      )}

                      {item.industry && item.industry.length > 0 && (
                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Industry:{' '}
                        </span>
                        <span>{item.industry}</span>
                      </div>
                      )}

                      <div className='dashboarddemo__company-info-item'>
                        <span className='dashboarddemo__company-info-label'>
                          Company size:{' '}
                        </span>
                        <span>{item.company_size}</span>
                      </div>

                      {item.website && (
                        <div className='dashboarddemo__company-info-item'>
                          <span className='dashboarddemo__company-info-label'>
                            Website:{' '}
                          </span>
                          <span>
                          <a href={item.website.startsWith('http') ? item.website : 'https://' + item.website} target="_blank" rel="nofollow" style={{textDecoration: "underline"}}>{item.website}</a>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
            </div>
          </div>
        )}

        {showOnboarding === false && showWaitlist === false && (
          <>
            {/* <div className='sellerdashboard__event-banner' onClick={() => setShowEventBanner(true)}>
              <div>
                <strong>Exclusive Workshop: </strong>
                <div>"Selling a Painkiller vs a Vitamin"</div>
                <div>Tuesday, April 4 @ 9am PT | 12pm ET</div>
              </div>

              <div>
                <button className='btn btn--primary' style={{backgroundColor: "mediumaquamarine"}}>Sign Up</button>
              </div>
            </div> */}

            {!userProfile.tz && (
              <h2 className='sellerdashboard__welcome'>
              <span className='buyerdashboard__timezone'>
                <a href='/account/profile#timezone' className='link'>
                  Set your timezone
                </a>
              </span>
              </h2>
            )}
          </>
        )}

        {showOnboarding === false && showWaitlist === false && (
          <div className='dashboard__demo-options-container'>
            <div className={demoStatus === 'available' ? 'dashboard__demo-option--active' : 'dashboard__demo-option'}
              onClick={() => setStatus('available')}>
              <span className='dashboard__demo-option-icon'><FaRegUser /></span> <span>Available</span>
            </div>

            <div className={demoStatus === 'team' ? 'dashboard__demo-option--active' : 'dashboard__demo-option'}
              onClick={() => setStatus('team')}>
              <span className='dashboard__demo-option-icon'><FaRegUser /></span> <span>Pending {'(Team)'}</span>

              {teamDemoCount > 0 && (
                <span className='dashboarddemo__flag-incomplete'></span>
              )}
            </div>

            <div className={demoStatus === 'pending' ? 'dashboard__demo-option--active': 'dashboard__demo-option'}
              onClick={() => setStatus('pending')}>
              <span className='dashboard__demo-option-icon'><FaUser /></span> <span>Pending {'(Me)'}</span>

              {availableDemosCount > 0 && (
                <span className='dashboarddemo__flag-incomplete'></span>
              )}
            </div>

            <div className={demoStatus === 'upcoming' ? 'dashboard__demo-option--active' : 'dashboard__demo-option'}
              onClick={() => setStatus('upcoming')}>
              <span className='dashboard__demo-option-icon'><FaRegCalendarAlt /></span> <span>Scheduled Demos</span>
            </div>

            <div className={demoStatus === 'rescheduled' ? 'dashboard__demo-option--active' : 'dashboard__demo-option'}
              onClick={() => setStatus('rescheduled')}>
              <span className='dashboard__demo-option-icon'><FaRegCalendarTimes /></span> <span>Reschedules</span>
            </div>

            <div className={demoStatus === 'incomplete' ? 'dashboard__demo-option--active' : 'dashboard__demo-option'}
              onClick={() => setStatus('incomplete')}>
              <span className='dashboard__demo-option-icon'><FaRegEdit /></span> <span>Needs Feedback</span>

              {incompleteDemosCount > 0 && (
                <span className='dashboarddemo__flag-incomplete'></span>
              )}
            </div>

            <div className={demoStatus === 'completed' ? 'dashboard__demo-option--active' : 'dashboard__demo-option'}
              onClick={() => setStatus('completed')}>
              <span className='dashboard__demo-option-icon'><FaRegCalendarCheck /></span> <span>Completed</span>
            </div>

            {/* <div className={demoStatus === 'expired' ? 'dashboard__demo-option--active' : 'dashboard__demo-option'}
              onClick={() => setStatus('expired')}>
              <span className='dashboard__demo-option-icon'><FaRegCalendarTimes /></span> <span>Expired</span>
            </div> */}
          </div>
        )}

        {showOnboarding === false && showWaitlist === false && (
          <div className='sellerdashboard__seller-demos-container'>
            <div>
              {demoStatus === 'available' && (
                <>
                {(company.thumbnail_image == null || company.thumbnail_image.length === 0 ||company.logo == null || company.logo.length === 0) && (
                  <FadeIn duration={300} delay={150} className='blurred'>
                    <SellerDemosInvite />
                  </FadeIn>
                )}

                {company.thumbnail_image && company.thumbnail_image.length > 0 && company.logo && company.logo.length > 0 && (
                  <FadeIn duration={300} delay={150}>
                    <SellerDemosInvite />
                  </FadeIn>
                )}
                </>
              )}

              {demoStatus === 'upcoming' && (
                <FadeIn duration={300} delay={150}>
                <SellerDemosUpcoming setUpcomingDemosCount={setUpcomingDemosCount} />
                </FadeIn>
              )}

                {demoStatus === 'pending' && (
                  <FadeIn duration={300} delay={150}>
                  <SellerDemosAvailable refreshRemaining={loadRemainingCount} />
                  </FadeIn>
                )}

                {demoStatus === 'team' && (
                  <FadeIn duration={300} delay={150}>
                  <SellerDemosTeam />
                  </FadeIn>
                )}

                  {demoStatus === 'incomplete' && (
                    <FadeIn duration={300} delay={150}>
                    <SellerDemosIncomplete  setIncompleteDemosCount={setIncompleteDemosCount} />
                    </FadeIn>
                  )}

                  {demoStatus === 'rescheduled' && (
                    <FadeIn duration={300} delay={150}>
                    <SellerDemosRescheduled setIncompleteDemosCount={setIncompleteDemosCount} />
                    </FadeIn>
                  )}

                {demoStatus === 'completed' && (
                  <FadeIn duration={300} delay={150}>
                  <SellerDemosCompleted />
                  </FadeIn>
                )}
            </div>
            {/* <SellerDemos
              status={demoStatus}
              setStatus={setStatus}
              setIncompleteDemosCount={setIncompleteDemosCount}
              setAvailableDemosCount={setAvailableDemosCount}
              setUpcomingDemosCount={setUpcomingDemosCount}
              setCompletedDemosCount={setCompletedDemosCount}
              setRescheduledDemosCount={setRescheduledDemosCount}
              refreshRemaining={loadRemainingCount}
            /> */}
          </div>
        )}
      </div>

      {showOnboarding && onboardingLoaded && showWaitlist === false && (
        <>
          <FadeIn delay={200} duration={200}>
            <div className='sellerdashboard__onboarding'>
              <h2>Finish your profile to access your Seller Dashboard</h2>

              <p className='sellerdashboard__onboarding-subtext'>
                This will help to ensure that we find you the best possible matches.
              </p>

              <div>
                <div className='sellerdashboard__onboarding-item'>
                  {hasProfile === false && (
                    <>
                      <div>
                        <div><FaEdit /> Complete your company profile <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>
                        <div className='sellerdashboard__onboarding-description'>
                          Your company description, problem you solve and your
                          unique value propositions.
                        </div>
                      </div>

                      <span>
                        <Link
                          to='/account/company/edit'
                          className='sellerdashboard__onboarding-btn'>
                          Start
                        </Link>
                      </span>
                    </>
                  )}

                  {hasProfile && (
                    <>
                      <div>
                        <span><FaEdit /> Complete your company profile <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></span>
                        <div className='sellerdashboard__onboarding-description'>
                          Your company description, problem you solve and your
                          unique value propositions.
                        </div>
                      </div>
                      <span className='sellerdashboard__onboarding-done'>
                        <Link to={'/account/company/edit'}>
                          Review
                        </Link>
                      </span>
                    </>
                  )}
                </div>

                {hasQuestions === false && (
                  <div className='sellerdashboard__onboarding-item'>
                    <div>
                      <div><FaListOl /> Setup your qualifier questions <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>
                      <div className='sellerdashboard__onboarding-description'>
                        Fill out the qualification questions that prospects must
                        answer before applying to a meeting.
                      </div>
                    </div>
                    <span>
                      <Link
                        to='/account/qualifiers'
                        className='sellerdashboard__onboarding-btn'>
                        Start
                      </Link>
                    </span>
                  </div>
                )}

                {hasQuestions && (
                  <div className='sellerdashboard__onboarding-item'>
                    <div>
                      <div><FaListOl /> Setup your qualifier questions <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>
                      <div className='sellerdashboard__onboarding-description'>
                      Fill out the qualification questions that prospects must
                        answer before applying to a meeting.
                      </div>
                    </div>
                    <span className='sellerdashboard__onboarding-done'>
                      <Link to={'/account/qualifiers'}>
                        Review
                      </Link>
                    </span>
                  </div>
                )}

                <div className='sellerdashboard__onboarding-item'>
                  {hasIcp === false && loadedIcp && (
                    <>
                      <div>
                        <div><FaUser /> Set your Ideal Customer Profile <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>
                        <div className='sellerdashboard__onboarding-description'>
                          Select the criteria that we use to match candidates
                          for a demo.
                        </div>
                      </div>
                      <span>
                        <Link
                          to='/account/icp'
                          className='sellerdashboard__onboarding-btn'>
                          Start
                        </Link>
                      </span>
                    </>
                  )}

                  {hasIcp && loadedIcp && (
                    <>
                      <div>
                        <div><FaUser /> Set your Ideal Customer Profile <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>

                        <div className='sellerdashboard__onboarding-description'>
                          Select the criteria that we use to match candidates
                          for a demo.
                        </div>
                      </div>
                      <span className='sellerdashboard__onboarding-done'>
                        <Link to={'/account/icp'}>
                          Review
                        </Link>
                      </span>
                    </>
                  )}
                </div>

                {/* <div className='sellerdashboard__onboarding-item'>
                  {hasPaymentMethod === false && paymentMethodLoaded && (
                    <>
                      <div>
                        <div><FaCreditCard /> Add a valid payment method <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>
                        <div className='sellerdashboard__onboarding-description'>
                        Add a valid payment method in order to start booking demos. You will only charged for demos that have successfully been completed.
                        </div>
                      </div>
                      <span>
                        <Link
                          to='/account/payment-method'
                          className='sellerdashboard__onboarding-btn'>
                          Start
                        </Link>
                      </span>
                    </>
                  )}

                  {hasPaymentMethod && paymentMethodLoaded && (
                    <>
                      <div>
                        <div><FaCreditCard /> Add a valid payment method <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>

                        <div className='sellerdashboard__onboarding-description'>
                        Add a valid payment method in order to book demos.
                        </div>
                      </div>
                      <span className='sellerdashboard__onboarding-done'>
                        Done
                      </span>
                    </>
                  )}
                </div> */}
         
                  {hasWorkhours === false && (
                    <div className='sellerdashboard__onboarding-item'>
                      <div>
                        <div><FaClock /> Setup your weekly work hours <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>
                        <div className='sellerdashboard__onboarding-description'>
                          Your available work hours during the week.
                        </div>
                      </div>
                      <span>
                        <a
                          href='/account/workhours'
                          className='sellerdashboard__onboarding-btn'>
                          Start
                        </a>
                      </span>
                    </div>
                  )}

                  {/* {hasWorkhours && (
                    <div className='sellerdashboard__onboarding-item'>
                      <div>
                        <div><FaClock /> Setup your weekly work hours <strong style={{fontSize: 'small'}}>{`(1-2 minutes)`}</strong></div>

                        <div className='sellerdashboard__onboarding-description'>
                          Your available work hours during the week.
                        </div>
                      </div>
                      <span className='sellerdashboard__onboarding-done'>
                        Done
                      </span>
                    </div>
                  )} */}

              </div>
            </div>
          </FadeIn>
        </>
      )}

      {showEventBanner && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: "80%", marginLeft: "-40%", maxHeight: "80%", padding: "0px", backgroundColor: "transparent"}}>
            <div className='modal__close' onClick={() => setShowEventBanner(false)}>
              <img src={close} />
            </div>

            <div>
              <a href="https://lu.ma/xwb7la4l" target="_blank">
                <img src="https://ik.imagekit.io/ivgrhmd4k/download.png?updatedAt=1680067529642" />
              </a>
            </div>
          </div>
        </>
      )}

      {onboardingLoaded && showOnboarding === false && showWelcomeBanner == true && (
        <FadeIn delay={100} duration={300}>
          <div className='overlay'></div>
          <div className='modal sellerdashboard__welcome-modal'>
            <div className='modal__close' onClick={() => setShowWelcomeBanner(false)}>
              <FaTimes />
            </div>
            <h2 className='modal__h2'>
              Welcome to Your Seller Dashboard
            </h2>

            <div className='sellerdashboard__welcome-modal-body'>
              <div className='mb-10'>
                This is where you'll manage all of your scheduled demos as well as:
              </div>

              <div>
                <ul>
                  <li>Send/Reply to messages</li>
                  <li>View post-demo feedback upon completing a demo</li>
                  <li>Reassign or reschedule your assigned demos to your teammates</li>
                  <li>Manage your company's profile</li>
                  <li><Link to={`/company/${company.company_id}/company`} className='link'>View your company profile</Link></li>
                </ul>
              </div>

              <div className='sellerdashboard__welcome-quick-start-wrapper'>
              <p className='sellerdashboard__welcome-modal-header'>Checklist - Keep everything up to date</p>

                <div className='sellerdashboard__welcome-checklist'>
                  <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
                    <div className='sellerdashboard__welcome-quick-start-icon'>
                    {(company.logo == null || company.logo.length == 0) && (
                      <FaExclamationCircle />
                    )}

                    {(company.logo != null && company.logo.length > 0) && (
                      <div className='sellerdashboard__welcome-company-logo'>
                        <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' +
                        company.logo } />
                      </div>
                    )}
                    </div>

                    <div className='sellerdashboard__welcome-quick-start-name'>Company Logo
                      <div className='sellerdashboard__welcome-quick-start-time'>Upload</div>
                    </div>

                    {(company.logo == null || company.logo.length == 0) && (
                      <div className="sellerdashboard__welcome-checkmark-pending">
                        <FaExclamation />
                      </div>
                    )}

                    {(company.logo != null && company.logo.length > 0) && (
                      <div className="sellerdashboard__welcome-checkmark">
                        <FaCheck />
                      </div>
                    )}
                  </div>

                  {/* <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/company/edit')}>
                    <div className='sellerdashboard__welcome-quick-start-icon'><FaBuilding /></div>
                    <div className='sellerdashboard__welcome-quick-start-name'>Company Created
                      <div className='sellerdashboard__welcome-quick-start-time'>Configure</div>
                    </div>

                    {hasProfile == false && (
                      <div className="sellerdashboard__welcome-checkmark-pending">
                        <FaCheck />
                      </div>
                    )}

                    {hasProfile == true && (
                      <div className="sellerdashboard__welcome-checkmark">
                        <FaCheck />
                      </div>
                    )}
                  </div> */}

                  <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/workhours')}>
                    <div className='sellerdashboard__welcome-quick-start-icon'><FaClock /></div>
                    <div className='sellerdashboard__welcome-quick-start-name'>Your Work Hours
                      <div className='sellerdashboard__welcome-quick-start-time'>Update</div>
                    </div>

                    {hasWorkhours && (
                      <div className='sellerdashboard__welcome-checkmark'>
                        <FaCheck />
                      </div>
                    )}
                  </div>

                  <div className='sellerdashboard__welcome-checklist-item' onClick={() => navigate('/account/qualifiers')}>
                    <div className='sellerdashboard__welcome-quick-start-icon'><FaRegQuestionCircle /></div>
                    <div className='sellerdashboard__welcome-quick-start-name'>Qualification Questions
                      <div className='sellerdashboard__welcome-quick-start-time'>Update</div>
                    </div>

                    {hasWorkhours && (
                      <div className='sellerdashboard__welcome-checkmark'>
                        <FaCheck />
                      </div>
                    )}
                  </div>

                  <div className='sellerdashboard__welcome-checklist-item' onClick={() => { setShowFeatures(true);
                  setShowWelcomeBanner(false);
                  }
                  }>
                    <div className='sellerdashboard__welcome-quick-start-icon'><FaShapes /></div>
                    <div className='sellerdashboard__welcome-quick-start-name'>Company Features
                      <div className='sellerdashboard__welcome-quick-start-time'>Update</div>
                    </div>

                      <div className='sellerdashboard__welcome-checkmark'>
                        <FaCheck />
                      </div>

                  </div>
                </div>
              </div>

              <div className='sellerdashboard__welcome-quick-start-wrapper'>
              <p className='sellerdashboard__welcome-modal-header'>Useful links</p>

              <div className='sellerdashboard__welcome-quick-start'>
                <a href="https://www.youtube.com/embed/jtYfrmvsGRs?si=fAj1gd39wzk6L0ob" target="_blank" className='sellerdashboard__welcome-quick-start-item'>
                  <div className='sellerdashboard__welcome-quick-start-icon'><FaYoutube /></div>
                  <div className='sellerdashboard__welcome-quick-start-name'>Product Overview
                    <div className='sellerdashboard__welcome-quick-start-time'>3:00 min</div>
                  </div>
                </a>

                <a href="https://play.google.com/store/apps/details?id=com.leadrpro.mobile" target="_blank" className='sellerdashboard__welcome-quick-start-item'>
                  <div className='sellerdashboard__welcome-quick-start-icon'><FaGooglePlay /></div>
                  <div className='sellerdashboard__welcome-quick-start-name'>Google Play Store
                    <div className='sellerdashboard__welcome-quick-start-time'>Download</div>
                  </div>
                </a>

                <a href="https://apps.apple.com/us/app/leadrpro/id6449265881" className='sellerdashboard__welcome-quick-start-item' target="_blank">
                  <div className='sellerdashboard__welcome-quick-start-icon'><FaAppleAlt /></div>
                  <div className='sellerdashboard__welcome-quick-start-name'>iOS Store
                    <div className='sellerdashboard__welcome-quick-start-time'>Download</div>
                  </div>
                </a>

                <Link to="/leave-feedback" className='sellerdashboard__welcome-quick-start-item'>
                  <div className='sellerdashboard__welcome-quick-start-icon'><FaHeadset /></div>
                  <div className='sellerdashboard__welcome-quick-start-name'>Support
                    <div className='sellerdashboard__welcome-quick-start-time'>Need assistance?</div>
                  </div>
                </Link>
              </div>
              </div>
            </div>
          </div>
        </FadeIn>
      )}

      {showRemaining && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowRemaining(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Demo Credits Remaining</h2>
            <div>
              You have <span style={{color: 'dodgerblue', fontWeight: 'bold'}}>{remainingCount}</span> demos remaining in your account.
            </div>

            <div style={{marginTop: '15px'}}>
              Once out, you will still be able to receive and review requests from buyers. However you will need to purchase more credits to approve and schedule.
            </div>

            <div style={{marginTop: '25px'}} className='center'>
              <Link to={'/select-plan'} className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800'>View Available Demo Packages</Link>
            </div>
          </div>
        </>
      )}

      {showFeatures && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: '80%', marginLeft: '-40%'}}>
            <div className='modal__close' onClick={() => setShowFeatures(false)}>
              <FaTimes />
            </div>
            <h2 className='modal__h2'>Company Features</h2>

            <CompanyFeature />
          </div>
        </>
      )}

      {showEnterName && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowEnterName(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Name is missing</h2>

            <p>
              <i>Please provide a valid name.</i>
            </p>

            <div>
              <div className='form-group'>
                <label><strong>First name</strong></label>
                <input type='text' placeholder='First name' value={firstName} onChange={(e) => setFirstName(e.target.value)} className='input' />
              </div>

              <div className='form-group'>
                <label><strong>Last name</strong></label>
                <input type='text' placeholder='Last name' value={lastName} onChange={(e)=> setLastName(e.target.value)} className='input' />
              </div>

              <div className='form-group center'>
                <button className='btn' onClick={() => handleUpdateName()}>Update Profile</button>
              </div>
            </div>
          </div>
        </>
      )}

{showWaitlistConfirm && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowWaitlistConfirm(false)}>
              <img src={close} />
            </div>
            <h2 className='modal__h2'>Get off the waitlist</h2>

            <p>
              By joining today you will get access to the following:
            </p>

            <ul style={{marginTop: "20px"}}>
              <li>Your company public profile</li>
              <li>Instant exposure to 1000's of qualified leads</li>
              <li>Access to qualification answers before you book a demo</li>
            </ul>

            <div className='center' style={{marginTop: "20px"}}>
              <button style={{backgroundColor: "mediumaquamarine"}} className='btn btn--primary' onClick={() => handleWaitlistConfirm()}>Take me off the waitlist</button>
            </div>
          </div>
        </>
      )}

      {showPreview && (
        <>
          <div className='overlay'></div>
          <div className='modal dashboard__company-preview'>
            <div className='modal__close' onClick={() => setShowPreview(false)}>
              <FaTimes />
            </div>

            <div>
              <CompanyPreview id={company.company_id} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SellerDashboardNew;
