import './AddPaymentMethod.css';
import {useEffect, useState} from 'react';
import { getCustomerId, createSetupIntent } from '../../utils/api';
import React from 'react';
import ReactDOM from 'react-dom';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
 import SetupForm from './SetupForm';
 import PaymentStatus from './PaymentStatus';
import { FaRegCreditCard } from 'react-icons/fa';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AddPaymentMethod = () => {
  const [intent, setIntent] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [options, setOptions] = useState({});

  async function handleCreateIntent(e){
    e.preventDefault();
    let res = await createSetupIntent();

    if (res.success){
      setIntent(res.data);

      let _obj = {
        clientSecret: res.data.client_secret
      }

      setOptions(_obj);
      setShowForm(true);
    }

    else{
     // alert('failed creating intent');
    }
  }

  return (
    <div className="addpaymentmethod">
      <p style={{fontSize: 'small', marginBottom: '30px'}}>
        * By adding your payment information, You agree to be charged a 1-time test fee of $1, which will be refunded to you within 24-48 hours.
      </p>
      {showForm === false && (
      <div className='center'>
        <button className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800' onClick={(e) => handleCreateIntent(e)}>Add payment method <FaRegCreditCard /></button>
      </div>
      )}

      {showForm && (
        <Elements stripe={stripePromise} options={options}>
          <SetupForm />
        </Elements>
      )}
    </div>
  );
}

export default AddPaymentMethod;