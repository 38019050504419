import './SearchResults.css';
import './SellerResults.css';
import './SellerSearch.css';
import './BrowseSellers.css';
import './CompanyCards.css';
import {useState, useEffect} from 'react';
import { searchCompanies, getAvailableSellersAll, saveCompany, getSavedCompanies, createSellerDemo, refreshToken, buyerCreatesSellerDemo, getIndustriesWithSellers, addBuyerRequest, getUserProfile, addToWaitlist, searchAvailableSellers, addEmailCampaign, checkCaptcha, hasAvailableApplications, addUserEventTracking, addBuyerRole, hRefreshToken, getComingSoonCompanies, searchComingSoonCompanies, addCompanyClick, getUpcomingEvents } from '../../utils/api';
import { getSeoName, checkSession } from '../../utils/utils';
import { isAuthed, refresh, isBuyer, isSeller } from '../../services/auth.service';
import Share from './Share';
import Spinner from '../../components/layout/Spinner';
import JoinModal from './JoinModal';
import BuyerBookingSeller from '../../components/demos/BuyerBookingSeller';
import QualifierQuestions from '../../components/qualifier/QualifierQuestions';
import AvailableTimes from '../../components/qualifier/AvailableTimes';
import SubmitTimes from '../../components/qualifier/SubmitTimes';
import FadeIn from '../../utils/fadein';
import {genTables} from '../../utils/pagination';
import {Helmet} from "react-helmet";
import { useNavigate } from 'react-router-dom';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaStar, FaRegStar, FaTimes, FaRegCheckCircle, FaCheckCircle, FaRegBell } from 'react-icons/fa';
import ScrollToTop from '../../components/layout/ScrollToTop';
import { getBlogHomeByCategory, getFoundersSpotlight, getLatestReviews, getBlogCategories } from '../../utils/api';

// import Recaptcha from "react-google-recaptcha";

const BrowseSellers = ({refreshPoints}) => {
  const [results, setResults] = useState([]);
  const [showBooking, setShowBooking] = useState(false);
  const [showConfirmation, setShowConfirmation]= useState(false);
  const [selectedSeller, setSelectedSeller] = useState({});
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const [completed, setCompleted] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const navigate = useNavigate();
  const [showConfirm, setShowConfirm] = useState(false);
  const [selectedSellers, setSelectedSellers] = useState([]);
  const [showQualifier, setShowQualifier] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [qualifierCompleted, setQualifierCompleted] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [currentDemoId, setCurrentDemoId] = useState(0);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState(0);
  const [buyerRequest, setBuyerRequest] = useState('');
  const [showRequest, setShowRequest] = useState(false);
  const [requestComplete, setRequestComplete] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [showUnverified, setShowUnverified] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [waitlist, setWaitlist] = useState([]);
  const [query, setQuery] = useState('');
  const [showSearching, setShowSearching] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [newsletterLoading, setNewsletterLoading] = useState(false);
  const [newsletterEmail, setNewsletterEmail] = useState('');
  const [newsletterDone, setNewsletterDone] = useState(false);
  const [showCaptcha, setShowCaptcha] = useState(true);
  const [isHuman, setIsHuman] = useState(false);
  const [hasAvailable, setHasAvailable] = useState(true);
  const [showMaxReached, setShowMaxReached] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [enablingBuyerRole, setEnablingBuyerRole] = useState(false);
  const [enableBuyerComplete, setEnableBuyerComplete] = useState(false);
  const [comingSoonCompanies, setComingSoonCompanies] = useState([]);
  const [loadingComingSoon, setLoadingCominSoon] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [currentCompanyId, setCurrentCompanyId] = useState(null);
  const [showHasApplied, setShowHasApplied] = useState(false);
  const [hasCompany, setHasCompany] = useState(false);
  const [showHasNoCompany, setShowHasNoCompany] = useState(false);
  const [events, setEvents] = useState([]);
  const [articles, setArticles] = useState([]);
  const [articlesLoaded, setArticlesLoaded] = useState(false);
  const [spotlight, setSpotlight] = useState([]);
  const [latestReviews, setLatestReviews] = useState([]);
  const captchaRef = useRef(null);
  let refQuery = React.createRef();

  function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  async function loadEvents(){
    let res = await getUpcomingEvents();

    if (res.success){
      setEvents(res.data);
    }
  }

  async function loadArticles(){
    let res = await getBlogHomeByCategory();

    if (res.success){
      setArticles(res.data.edges);
      setArticlesLoaded(true);
    }
  }

  async function loadSpotlight(){
    let res = await getFoundersSpotlight();

    if (res.success){
      setSpotlight(res.data.edges);

    }
  }

  async function loadReviews(){
    let res = await getLatestReviews();

    if (res.success){
      setLatestReviews(res.data);
    }
  }

  useEffect(() => {
    if (isAuthed()){
      setIsLoggedIn(true);
    }

    loadEvents();
    loadArticles();
    loadSpotlight();
    loadReviews();
  }, []);

  useEffect(() => {
    async function loadCheckStatus(){
      let _ishuman = getCookie('is_human');
      if (_ishuman === 'true' || isAuthed()){
 
      }

      else{
        navigate('/security-check');
      }
    }

    //loadCheckStatus();
    checkSession('Discover Software');
  }, []);

  useEffect(() => {
    if (showConfirmation == true){
      refreshPoints();
    }
  }, [showConfirmation])

  useEffect(async () => {
    if (showComingSoon === true){
      if (query.length === 0){
        // load all results
        await loadComingSoon(1, true);
      }
    }
  }, [query]);

  useEffect(async () => {
    async function loadAvailableSellers(){
      let _res = await getAvailableSellersAll();

      if (_res.success){
        setResults(_res.data);
        setCompleted(true);
        setLoaded(true);

        if (_res.enabled === false){
          setIsEnabled(false);
        }

        setWaitlist(_res.data.filter(x => x.on_waitlist === true));
      }

      else{
        if (_res.status === 403){
          await refresh();

          let _res = await getAvailableSellersAll();

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            setLoaded(true);

            if (_res.enabled === false){
              setIsEnabled(false);
            }

            setWaitlist(_res.data.filter(x => x.on_waitlist === true));
          }
        }

        else{
          let _res = await getAvailableSellersAll();

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            setLoaded(true);
            setWaitlist(_res.data.filter(x => x.on_waitlist === true));
          }
        }
      }

      setIsSearching(false);
    }

    async function loadUserProfile(){
      let res = await getUserProfile();

      if (res.success){
        let _user = res.data;

        if (_user.work_email_verified){
          setIsVerified(true);
        }

        else{
          setIsVerified(false);
        }

        if (_user.company_id != null){
          setHasCompany(true);
        }
      }

      else if (res.status === 403){
        await refresh();
        let res = await getUserProfile();

        if (res.success){
          let _user = res.data;
          if (_user.work_email_verified){
            setIsVerified(true);
          }
  
          else{
            setIsVerified(false);
          }

          if (_user.company_id != null){
            setHasCompany(true);
          }
        }
      }
    }

    async function loadIndustries(){
      let res = await getIndustriesWithSellers();

      if (res.success){
        setIndustries(res.data);
      }
    }

    await loadAvailableSellers();
    //await loadIndustries();
    await loadUserProfile();
  }, []);

  async function handleQualifierClick(demo_id, item){
    if (isLoggedIn===false){
      navigate('/join-now');
      setShowModal(true);
      setBookingLoading(false);
      return;
    }

    if (hasCompany === false){
      //alert("no company info found");
      setShowHasNoCompany(true);
      return;
    }

    // if (isVerified === false){
    //   setShowUnverified(true);
    //   setBookingLoading(false);
    //   return;
    // }

    setBookingLoading(true);
    setSelectedCompanyId(item.company_id);
    setCurrentCompanyId(item.company_id);

    let _check = await hasAvailableApplications();

    if (_check.success){
      if (_check.data === true){
        setShowMaxReached(false);
        setHasAvailable(true);
      }

      else{
        setHasAvailable(false);
        setShowMaxReached(true);
        setBookingLoading(false);
        return;
      }
    }

    if (demo_id === null){
      let data = {seller_id: item.seller_id};
      let _res = await createSellerDemo(data);

      if (_res.success){
        setCurrentDemo(item);
        setCurrentDemoId(_res.data);
        setShowQualifier(true);
      }

      else{
        if (_res.has_applied != null && _res.has_applied === true){
          setShowHasApplied(true);
          // alert("Looks like you have already applied to this company in the past. We only allow 1 application per company per user currently.")
        }

        else{
          setCurrentDemo(item);
          setCurrentDemoId(_res.demo_id);
          setShowQualifier(true);
        }
      }

      setBookingLoading(false);
    }

    else{
      setCurrentDemo(item);
      setCurrentDemoId(demo_id);
      setShowQualifier(true);
      setBookingLoading(false);
    }

    await addUserEventTracking('Book demo click', demo_id);
    await addCompanyClick(item.company_id, 'Discover software');
  }

  async function handleWaitlistClick(demo_id, item){
    setSelectedCompanyId(item.company_id);
    setCurrentDemo(item);
    setShowWaitlist(true);
  }

  async function handleWaitlistConfirmClick(){
    setSubmitting(true);
    let res = await addToWaitlist(selectedCompanyId);

    if (res.success){
      setShowWaitlistConfirm(true);
      setShowWaitlist(false);
      setSubmitting(false);

      const updatedItems = comingSoonCompanies.map(item => {
        if (item.company_id === selectedCompanyId) {
          // Return a new object with the updated property
          return { ...item, user_on_waitlist: true };
        }
        return item; // Return the item as is
      });

      setComingSoonCompanies(updatedItems);
    }

    else if (res.status === 403){
      await refresh();

      let res = await addToWaitlist(selectedCompanyId);

      if (res.success){
        setShowWaitlistConfirm(true);
        setShowWaitlist(false);
        setSubmitting(false);
        //setWaitlist([...waitlist, selectedCompanyId]);

        const updatedItems = waitlist.map(item => {
          if (item.company_id === selectedCompanyId) {
            // Return a new object with the updated property
            return { ...item, user_on_waitlist: '1' };
          }
          return item; // Return the item as is
        });

        setWaitlist(updatedItems);
      }
    }

    else{
      alert("Something went wrong");
    }
  }

  function demoAccepted(){
    let _filter = results.filter(item => item.company_id !== selectedCompanyId);
    setResults(_filter);
  }

  async function handleSubmitRequest(){
    if (buyerRequest && buyerRequest.length > 0){
      let res = await addBuyerRequest(buyerRequest);

      if (res.success){
        setRequestComplete(true);
      }

      else{
        setRequestComplete(true);
      }
    }
  }

  async function handleAvailableClick(){
    setShowComingSoon(false);
    setPage(1);
    setQuery('');
  }

  async function loadComingSoon(page, reset = false){
    setLoadingCominSoon(true);

    let res = await getComingSoonCompanies(page);

    if (res.success){
      setLoadingCominSoon(false);
      setLoadingMore(false);

      if (reset === false)
        setComingSoonCompanies(prevArray => [...prevArray, ...res.data]);

      else
        setComingSoonCompanies(res.data);
      setPage(prev => prev + 1);
      //setComingSoonCompanies(res.data);
    }

    else if (res.status === 403){
      await refresh();

      let res = await getComingSoonCompanies(page);

      if (res.success){
        setLoadingCominSoon(false);
        setLoadingMore(false);
        // setComingSoonCompanies(prevArray => [...prevArray, ...res.data]);
        if (reset === false)
        setComingSoonCompanies(prevArray => [...prevArray, ...res.data]);

      else
        setComingSoonCompanies(res.data);
        setPage(prev => prev + 1);
        // setComingSoonCompanies(res.data);
      }
    }
  }

  async function handleComingSoonClick(){
    setQuery('');
    setPage(1);
    setShowComingSoon(true);
    await loadComingSoon(1, true);
  }

  async function loadAvailableSellers(){
    let _res = await searchAvailableSellers(query, selectedIndustry);

    if (_res.success){
      setResults(_res.data);
      setCompleted(true);
      setLoaded(true);
      setIsSearching(false);
    }

    else{
      if (_res.status === 403){
        await refresh();
        let _res = await searchAvailableSellers(query, selectedIndustry);

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          genTables();
          setLoaded(true);
        }
      }

      else{
        let _res = await getAvailableSellersAll();

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          //genTables();
          setLoaded(true);
        }
      }

      setIsSearching(false);
    }
  }

  async function loadComingSoonSellers(){
    if (query.length === 0){
      let _res = await getComingSoonCompanies();

      if (_res.success){
        setComingSoonCompanies(_res.data);
        setCompleted(true);
        //genTables();
        setLoaded(true);
        setIsSearching(false);
      }
      return;
    }

    let _res = await searchComingSoonCompanies(query, selectedIndustry);

    if (_res.success){
      setComingSoonCompanies(_res.data);
      setCompleted(true);
      setLoaded(true);
      setIsSearching(false);
    }

    else{
      if (_res.status === 403){
        await refresh();
        let _res = await searchComingSoonCompanies(query, selectedIndustry);

        if (_res.success){
          setComingSoonCompanies(_res.data);
          setCompleted(true);
          genTables();
          setLoaded(true);
        }
      }

      else{
        let _res = await getComingSoonCompanies();

        if (_res.success){
          setComingSoonCompanies(_res.data);
          setCompleted(true);
          //genTables();
          setLoaded(true);
        }
      }

      setIsSearching(false);
    }
  }

  async function search(q){
    if (showComingSoon){
      handleSearch();
      return;
    }

    setIsSearching(true);
    let _res = await searchAvailableSellers(q, selectedIndustry);

    if (_res.success){
      setResults(_res.data);
      setCompleted(true);
      genTables();
      setLoaded(true);
      setIsSearching(false);
    }

    else{
      if (_res.status === 403){
        await refreshToken();

        let _res = await searchAvailableSellers(q, selectedIndustry);

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          genTables();
          setLoaded(true);
        }
      }

      else{
        let _res = await getAvailableSellersAll();

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          genTables();
          setLoaded(true);
        }
      }

      setIsSearching(false);
    }

    setIsSearching(false);
  }

  async function searchAll(q, industry_id){
    setIsSearching(true);
    let _res = await searchAvailableSellers(q, industry_id);

    if (_res.success){
      setResults(_res.data);
      setCompleted(true);
      genTables();
      setLoaded(true);
      setIsSearching(false);
    }

    else{
      if (_res.status === 403){
        await refresh();

          let _res = await searchAvailableSellers(q, industry_id);

          if (_res.success){
            setResults(_res.data);
            setCompleted(true);
            //genTables();
            setLoaded(true);
          }
      }

      else{
        let _res = await getAvailableSellersAll();

        if (_res.success){
          setResults(_res.data);
          setCompleted(true);
          //genTables();
          setLoaded(true);
        }
      }

      setIsSearching(false);
    }

    setIsSearching(false);
  }

  async function handleSearch(){
    if (showComingSoon === false){
      setIsSearching(true);
      setShowDropdown(false);
      loadAvailableSellers();
    }

    else if (showComingSoon === true){
      console.log('handle search called');
      setIsSearching(true);
      setShowDropdown(false);
      loadComingSoonSellers();
    }
  }

  async function handleIndustryClick(id){
    setIsSearching(true);
    setSelectedIndustry(id);
    setShowDropdown(false);

    await searchAll('', id);
  }

  async function handleQueryChange(q){
    setQuery(q);
    setShowDropdown(false);

    // if (q.length === 0){
    //   alert('query is empty');
    //   search('');
    // }
  }

  async function handleClearClick(){
    setShowDropdown(false);
    setQuery('');
    setSelectedIndustry(0);
    searchAll('', 0);
  }

  async function handleKeyUp(k){
    if (k.key === 'Enter'){
      setIsSearching(true);
      setShowDropdown(false);
      search(refQuery.current.value);
    }
  }

  async function handleNewsletterSubmit(){
    setNewsletterLoading(true);

    if (newsletterEmail.length === 0){
      alert('Must enter a valid email');
      setNewsletterLoading(false);
      return;
    }

    let res = await addEmailCampaign(newsletterEmail, 'Discover');

    if (res.success){
      setNewsletterDone(true);
      setNewsletterLoading(false);
      setNewsletterEmail('');
    }
  }

  async function handleRecaptcha(e){
    e.preventDefault();

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    let res = await checkCaptcha(token);

    if (res.success){
      alert('human');
    }

    else{
      alert('not human');
    }
  }

  function deepCloneArray(arr) {
    return arr.map(item => {
        if (Array.isArray(item)) {
            return deepCloneArray(item);
        } else if (typeof item === 'object' && item !== null) {
            return deepCloneObject(item);
        } else {
            return item;
        }
    });
}

function deepCloneObject(obj) {
    const clone = {};
    for (const key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            clone[key] = Array.isArray(obj[key]) ? deepCloneArray(obj[key]) : deepCloneObject(obj[key]);
        } else {
            clone[key] = obj[key];
        }
    }
    return clone;
}

  async function handleSortChange(e){
    let index = e.target.selectedIndex;
    let tmp = deepCloneArray(results);

    if (index == 1){
      // popular
      tmp.sort((a, b) => {
        let a1 = 0;
        let b1 = 0;

        if (a.average_rating != null)
          a1 = parseFloat(a.average_rating);

        if (b.average_rating != null)
          b1 = parseFloat(b.average_rating);

        return b1 - a1;
      });
    }

    else if (index == 2){
      // number of reviews
      tmp.sort((a, b) => {
        return parseInt(b.review_count) - parseInt(a.review_count)
      });
    }

    else if (index == 3){
      tmp.sort((a, b) => {
        let a1 = a.is_new == true ? 1: 0;
        let b1 = b.is_new == true? 1: 0;
        return b1 - a1;
      });
    }

    setResults(tmp);
  }

  async function handleEnableBuyer(){
    setEnablingBuyerRole(true);

    let res = await addBuyerRole();

    if (res.success){
      setEnableBuyerComplete(true);
      setEnablingBuyerRole(false);
      await hRefreshToken();
      navigate('/dashboard/buyer');
    }

    else if (res.status == 403){
      await refresh();

      let res = await addBuyerRole();

      if (res.success){
        setEnableBuyerComplete(true);
        setEnablingBuyerRole(false);
        await hRefreshToken();
        navigate('/dashboard/buyer');
      }
    }
  }

  function getStars(number){
    let mainindex = 0;
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++){
      el.push(<span key={mainindex} className="review__star"><FaStar /></span>);
      mainindex++;
    }

    for (let i = 0; i < 5 - number; i++){
      el.push(<span key={mainindex} className="review__star--unselected"><FaRegStar /></span>);
      mainindex++;
    }

    return (
      <div className='companycards__stars'>
        {el}
      </div>
    )
  }

  async function handleComingSoonLoadMore(){
    setLoadingMore(true);
    await loadComingSoon(page + 1);
  }

  function removeHTMLTags(text) {
    // Create a temporary DOM element
    let tempElement = document.createElement('div');
    
    // Set the innerHTML of the temporary element to the provided text
    tempElement.innerHTML = text;
    
    // Retrieve the text content of the temporary element, which excludes HTML tags
    return tempElement.textContent || tempElement.innerText || '';
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover the latest innovative software</title>
        <meta name="description" content="Search for and discover the latest and greatest in software solutions catered to your specific needs." />
        <link rel="canonical" href={`https://www.leadrpro.com/software`} />
      </Helmet>

      <ScrollToTop />
    {/* {showCaptcha && (
      <>
        <form onSubmit={(e) => handleRecaptcha(e)}>
          <div className='overlay'></div>
          <div className='modal'>
            <Recaptcha sitekey='6LdRXFcmAAAAAFhP5pzvew6TPahqjbvdVgSjarom' ref={captchaRef} />

            <div>
              <button className='btn btn--primary'>Submit</button>
            </div>
          </div>
        </form>
      </>
    )} */}
    <div className='sellersearch__wrapper'>
      <div className="companycards" style={{width: "90%", maxWidth: "100%", margin: 'auto'}}>
      
      {/* <h1 className='browsesellers__h1'>
        Discover the latest innovative software
      </h1> */}

      {loaded == true && isAuthed() && isBuyer() == false && (
        <FadeIn duration={100} delay={100}>
          <div className='browsesellers__become-buyer'>
            <div className='browsesellers__become-buyer-copy'>Interested in participating in paid demos as well? Enable the <span>Buyer</span> role and start booking today. </div>
            
            <div>
              {enablingBuyerRole == false && (
                <button className='btn' onClick={() => handleEnableBuyer()}>Enable</button>
              )}

              {enablingBuyerRole == true && (
                <Spinner />
              )}
              </div>
          </div>
        </FadeIn>
      )}

      {/* <div className='browsesellers__input-wrapper'>
        <input type="text" value={query} placeholder='Search for software' onFocus={() => setShowDropdown(false)} onChange={(e) => handleQueryChange(e.target.value)} onKeyUp={(e) => handleKeyUp(e)} ref={refQuery} />

        <button onClick={() => handleSearch()} className='browsesellers__search-btn'>
          {isSearching && (
            <span>
              ...
            </span>
          )}

          {isSearching === false && (
            <span>Search</span>
          )}
        </button>
      </div> */}

        <FadeIn delay={200} duration={200}>
        <div className='browsesellers__dropdown' style={{display: showDropdown ? 'block': 'none'}}>
          <div style={{position: "absolute", top: "8px", right: "8px", fontWeight: "bold", fontSize: "12px", cursor: "pointer", color: "#ccc"}} onClick={() => handleClearClick()}>Clear</div>
          <strong>Popular Industries:</strong>
          <div className='browsesellers__dropdown-links'>
            {industries.map(i => (
              <span className={`link ${selectedIndustry == i.industry_id ? ' selected': ''}`} onClick={() => handleIndustryClick(i.industry_id)}>
                &bull; {i.name}
              </span>
            ))}
          </div>
        </div>
        </FadeIn>

      {/* <div className='browsesellers__options'>
          <div className={`browsesellers__option ${showComingSoon ? '' : ' browsesellers__option--selected'}`} onClick={() => handleAvailableClick()}>Available Now</div>
          <div className={`browsesellers__option ${showComingSoon === false ? '' : ' browsesellers__option--selected'}`} onClick={() => handleComingSoonClick()}>Coming Soon</div>
        </div> */}

      {isSearching && (
        <FadeIn delay={100} duration={100}>
          <div className='browsesellers__placeholders'>
            <div className='browsesellers__placeholder'></div>
            <div className='browsesellers__placeholder'></div>
            <div className='browsesellers__placeholder'></div>
            <div className='browsesellers__placeholder'></div>
            <div className='browsesellers__placeholder'></div>
            <div className='browsesellers__placeholder'></div>
          </div>
        </FadeIn>
      )}

      {results.length === 0 && isSearching === false && loaded && (
        <div className='sellersearch__no-results'>
          No results found.
        </div>
      )}

      {results.length > 0 && isSearching === false && (
      <div>
        {loaded && showComingSoon===false && (
          <FadeIn delay={100} duration={100}>
            <div>
              {/* <div className='browsesellers__sort-by'>
                <select className='input' onChange={(e) => handleSortChange(e)}>
                  <option>Sort by...</option>
                  <option value="1">Popular</option>
                  <option value="2">Number of reviews</option>
                  <option value="3">Newest first</option>
                </select>
              </div> */}
            </div>

        <div className='companycards__main-wrapper'>
          
          <div className='companycards__left-side'>
            <section>
              <h2>Latest News</h2>

              <div className='companycards__articles'>
                {articles.slice(0, 5).map(item => (
                  <div className='companycards__article'>
                  <div className='companycards__article-thumbnail'>
                  <img src={item.node.featuredImage?.node.sourceUrl} alt="Image of post" />
                  </div>

                  <div className='companycards__article-body'>
                    <div className='companycards__article-title'>
                      <Link to={`/blog/${item.node.slug}`}>
                        {item.node.title}
                      </Link>
                    </div>

                    <div className='companycards__article-description'>
                      {removeHTMLTags(item.node.excerpt)}
                      <div>
                        <Link className='link' to={`/blog/${item.node.slug}`}>Read More</Link>
                      </div>
                    </div>
                  </div>
                </div>
                ))}
              </div>
            </section>

            <section>
            <h2>Latest products</h2>
            <div className='companycards__companies'>
        {results.slice(0, 6).filter(x => x.on_waitlist === false).map(item => (
          <div className="companycards__item">

            <div className='flex' style={{alignItems: 'center'}}>
              <div className='companycards__item-logo'>
                {item.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                  )}
                  
                  {!item.logo && (
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                  )}
              </div>

              <div className='companycards__item-name-wrapper'>
                <div className='companycards__item-name'>
                  <Link to={'/company/' + item.company_id + '/' + item.url_slug}> {item.name}
                </Link>
                </div>

                <div className='mt-10 companycards__item-tagline'>
                  {item.tagline}
                </div>

                {item.average_rating && (
                  <div className='companycards__ratings'>
                      {getStars(Math.ceil(item.average_rating))} 
                      
                      <span>{Math.round(item.average_rating * 10) / 10}</span>

                      {parseInt(item.review_count) > 0 && (
                        <span style={{fontSize: '12px', marginLeft: '10px'}}>{`(${item.review_count} reviews)`}</span>
                      )}
                  </div>
                  )}

                <div className='companycards__stats'>
                  <div>
                    {item.industry_name}
                  </div>

                {item.is_featured == true && (
                    <div className='companycards__new-label'>
                      <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/></svg></span>{' '}<span>Featured</span>
                    </div>
                  )}

                  {item.is_new == true && (
                    <div className='companycards__new-label'>
                      <span><svg style={{height: '12px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M349.4 44.6c5.9-13.7 1.5-29.7-10.6-38.5s-28.6-8-39.9 1.8l-256 224c-10 8.8-13.6 22.9-8.9 35.3S50.7 288 64 288l111.5 0L98.6 467.4c-5.9 13.7-1.5 29.7 10.6 38.5s28.6 8 39.9-1.8l256-224c10-8.8 13.6-22.9 8.9-35.3s-16.6-20.7-30-20.7l-111.5 0L349.4 44.6z"/></svg></span>{' '}<span>New</span>
                    </div>
                  )}
                </div>
              </div>

              <div className='companycards__more-details'>
                  <Link to={`/company/${item.company_id}/details`} className='btn'>More Details</Link>
              </div>
            </div>

            <div className='companycards__btns'>
              <>
                {currentCompanyId === item.company_id && bookingLoading && (
                  <Spinner />
                )}

                {bookingLoading === false && isEnabled === true && isAuthed() && isBuyer() && (
                  <button className='btn btn--primary browsesellers__btn-apply' onClick={() => handleQualifierClick(item.demo_id, item)}>
                    <span>
                      Book a demo
                    </span>
                  </button>
                )}

                {bookingLoading === false && isEnabled === true && isAuthed() == false && (
                  <button className='btn btn--primary browsesellers__btn-apply' onClick={() => handleQualifierClick(item.demo_id, item)}>
                    <span>
                      Book a demo
                    </span>
                  </button>
                )}
              </>

              <Link to={`/company/${item.company_id}/${item.url_slug}`} className='btn browsesellers__btn-info'>More Info</Link>
            </div>
        </div>
        ))}
        </div>
        </section>

        <section>
          <h2>Latest Feedback</h2>

          <div className='mt-10'>
            See what other professionals are saying about these companies.
          </div>

          <div className='mt-10'>
          <div className='companycards__companies'>
            {latestReviews.slice(0, 6).map(item =>(
              <div className="companycards__item">
              <div className='flex' style={{alignItems: 'center'}}>
                <div className='companycards__item-logo'>
                  {item.logo && (
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                    )}
                    
                    {!item.logo && (
                      <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} alt="" />
                    )}
                </div>
  
                <div className='companycards__item-name-wrapper'>
                  <div className='companycards__item-name'>
                    <Link to={'/company/' + item.company_id + '/' + item.url_slug}> {item.name}
                  </Link>
                  </div>
  
                  <div className='mt-10 companycards__item-tagline'>
                    <div><strong>What did you find the most appealing?</strong></div>
                    <i>"{item.feedback1}" - {item.job_title}</i>
                  </div>

                </div>
              </div>
          </div>
            ))}

        </div>
          </div>
        </section>
        </div>

        <div className='companycards__right-side'>
          <section style={{marginTop: '25px'}}>
            <h3>Founder Spotlight</h3>
            
            <div className='companycards__spotlight'>
              {spotlight.map(item => (
                <div className='companycards__spotlight-item'>
                  <div className='companycards__spotlight-item-name'>
                    <Link to={`/blog/${item.node.slug}`}>
                      {item.node.title}
                    </Link>
                  </div>

                  <div className='companycards__spotlight-item-description'>
                    {removeHTMLTags(item.node.excerpt)}
                  </div>
                </div>
              ))}

              {/* <div className='companycards__spotlight-item'>
                <div className='companycards__spotlight-item-name'>
                  Best SEO software
                </div>

                <div className='companycards__spotlight-item-description'>
                  slkj afj asl falf jalfjasf
                </div>
              </div>

              <div className='companycards__spotlight-item'>
                <div className='companycards__spotlight-item-name'>
                  HR recruitment tools
                </div>

                <div className='companycards__spotlight-item-description'>
                  slkj afj asl falf jalfjasf
                </div>
              </div> */}

              {/* <div className='companycards__spotlight-item'>
                <div className='companycards__spotlight-item-name'>
                  Lead Gen
                </div>

                <div className='companycards__spotlight-item-description'>
                  slkj afj asl falf jalfjasf
                </div>
              </div> */}
            </div>
          </section>

          <section className='mt-30'>
          <h3><strong>Upcoming Webinars</strong></h3>

          <div className='mt-10'>
            {events.map(item => (
              <div className='companycards__event'>
                {item.image_url && (
                  <div className='dashboard__event-thumbnail'>
                    <Link to={`/event/${item.event_id}/${item.canonical_url_slug}`}><img src={item.image_url} />
                    </Link>
                    </div>
                )}

                {(item.image_url == null || item.image_url.length === 0) && (
                  <div className="dashboard__event-thumbnail">
                    <Link to={`/event/${item.event_id}/${item.canonical_url_slug}`}>
                      <img src="https://ik.imagekit.io/ivgrhmd4k/Events/city_default.png?updatedAt=1704216697675" />
                    </Link>
                  </div>
                )}

                {item.start_date && (
                  <>
                    <div>
                      {new Date(item.start_date).toLocaleDateString()}
                    </div>
                  </>
                )}

                <div className='companycards__event-name'>
                  <Link to={`/event/${item.event_id}/${item.canonical_url_slug}`}>
                    {item.name}
                  </Link>
                </div>

                <div className='companycards__event-btn'>
                  <Link to={`/event/${item.event_id}/${item.canonical_url_slug}`} className='btn'>
                    Register
                  </Link>
                </div> 
              </div>
            ))}
          </div>
          </section>
        </div>
        </div>
        </FadeIn>
        )}

        {loaded && showComingSoon === true && (
          <FadeIn delay={100} duration={100}>
            <div className='browsesellers__coming-soon-description'>
              These companies are not available to book currently but they will be <strong>soon</strong>, be the first to know when they go live.
            </div>

            {loadingComingSoon === true && (
              <div className='center'>
                <Spinner />
              </div>
            )}

{loadingComingSoon === false && comingSoonCompanies.length === 0 && (
              <div className='center'>
                No items found currently.
              </div>
            )}

          <div style={{display: "flex", flexWrap: "wrap"}}>
            {comingSoonCompanies.map(item => (
            <div className="browsesellers__item">
              <div className="browsesellers__company-thumbnail">
              <a href={'/company/' + item.company_id + '/' + item.url_slug}>
                  {item.thumbnail_image && (
                    <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt="" />
                    )}
                </a>
              </div>

              <div>
                <div>
                  <div className='sellerresults__company-info'>
                    <div className='browsesellers__item-industry'>{item.industry_name}</div>
                    <div className="browsesellers__company-name">
                    <a href={'/company/' + item.company_id + '/' + item.url_slug}>    {item.name}
                    </a>
                      </div>

                    <div className="sellerresults__description">
                      <div className='browsesellers__company-description-text'>
                        {item.description ? (item.description.length > 180 ? item.description.substring(0, 180) + "..." : item.description) : 'No description found'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className='browsesellers__btns mt-30'>
                  {item.user_on_waitlist === false && (
                    <button className='btn btn--primary browsesellers__btn-notify' onClick={() => handleWaitlistClick(item.demo_id, item)} ><FaRegBell /> Notify me when available</button>
                  )}

                  {
                    (item.user_on_waitlist === true) && (
                      <span className='browsesellers__on-waitlist'><FaCheckCircle style={{color: 'mediumseagreen'}} /> You're on the list</span>
                    )
                  }
                </div>
              </div>
          </div>
        ))}
        </div>

        <div className='center mt-30'>
          {loadingMore === false && (
            <button className='btn' onClick={() => handleComingSoonLoadMore()}>Load More</button>
          )}

          {loadingMore && (
            <Spinner />
          )}
        </div>
        </FadeIn>
        )}
        </div>
        )}

        {showSelectedTime && (
          <div className='overlay'>
              <div className='modal modal--pad0 modal--wide' style={{overflow: 'auto'}}>
                {currentDemo.booking_style === 'daily_view' && (
                  <AvailableTimes demo={currentDemo} demo_id={currentDemoId} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
                )}

                {currentDemo.booking_style === 'list_view' && (
                  <SubmitTimes demo={currentDemo} demo_id={currentDemoId} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
                )}
              </div>
          </div>
        )}

        {showShare && (
          <div className="overlay">
          <FadeIn duration={250} delay={250}>
            <Share setShow={setShowShare} />
          </FadeIn>
          </div>
        )}

        {showConfirmation && (
          <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowConfirmation(false)}>
              <FaTimes />
            </div>
            
            <div className='center' style={{fontSize: "50px", marginBottom: "20px", color: 'mediumseagreen'}}>
              <FaCheckCircle />
            </div>

            <div>Your request is now being reviewed. You should hear back within 24 hours.</div>

            <div>In the meantime, keep your profile up to date and check back daily for more demo opportunities!</div>

            <div className='center mt-20'>
              <button className='btn' onClick={() => setShowConfirmation(false)}>Close</button>
            </div>
          </div>
          </>
        )}

        {showQualifier && (
          <>
            <div className='overlay'></div>
            <div className='modal modal--pad0 modal__qq'>
              <QualifierQuestions currentDemo={currentDemo} demo_id={currentDemoId} setIsCompleted={setQualifierCompleted} setShowQualifier={setShowQualifier} setShowSelectedTime={setShowSelectedTime}></QualifierQuestions>
            </div>
          </>
        )}
    </div>

    {showRequest && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowRequest(false)}>X</div>

          {requestComplete === false && (
            <>
                <h2>Submit a software request</h2>

          <p style={{marginTop: "20px"}}>
            Have a specific software need in mind? Let us know and we will comb through our extensive database and find you the best solutions.
          </p>

          <div style={{marginTop: "10px"}}>
            <textarea className='input textarea' placeholder='Ex: I am looking for content marketing software in the healthcare industry...' value={buyerRequest} onChange={(e) => setBuyerRequest(e.target.value)} style={{height: "100px"}}>

            </textarea>
          </div>

          <div style={{marginTop: "10px"}}>
            <button className='btn btn--primary' onClick={() => handleSubmitRequest()}>Submit request</button>
          </div>
          </>
          )}

          {requestComplete && (
            <>
              <h2>Request submitted!</h2>

            <p>
              Your request has been submitted! We normally take 24-48 hours to find you the best possible results. Hang tight and we will notify you when available!
            </p>
            </>
          )}
        </div>
      </>
    )}

    {showUnverified === true && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <h2 className='modal__h2'>
            ⚠️ Complete setting up your account!
          </h2>
          <p style={{marginTop:"20px"}}>
            You must verify your work email before you can apply for a demo.
          </p>

          <p>
            <a href='/account/work-email' className='link'>Verify work email</a>
          </p>
          <div className='center' style={{marginTop: "30px"}}>
            <button className='btn btn--secondary' onClick={() => setShowUnverified(false)}>Close</button>
          </div>
        </div>
      </>
    )}
    </div>

    {showWaitlist && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowWaitlist(false)}>
            <FaTimes />
          </div>

          {/* <div className='sellersearch__waitlist-company-info'>
            <div className="sellersearch__company-logo">
              {currentDemo.logo && (
                <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} alt="" />
                )}
                
                {!currentDemo.logo && (
                  <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + currentDemo.logo} alt="" />
                )}
            </div>

            <div className='sellersearch__waitlist-company-name'>
              {currentDemo.name}
            </div>
          </div> */}

          <h2 className='modal__h2' style={{lineHeight:'1.1'}}>Get notified when <span style={{color: 'mediumpurple', textDecoration:'underline'}}>{currentDemo.name}</span> becomes available!</h2>

          <div className='mt-20'>You will be added to the waitlist and will be notified as soon as this company is ready to host demos.</div>

          <div className='center mt-20'>
            {submitting === false && (
            <button className='btn btn--primary btn--full' style={{padding: '10px'}} onClick={() => handleWaitlistConfirmClick()}><FaRegBell /> Sign me up</button>
            )}

            {submitting && (
              <Spinner />
            )}
          </div>
        </div>
      </>
    )}

    {showWaitlistConfirm && (
      <>
        <div className='overlay'></div>

        <div className='modal'>
          <div className='modal__close' onClick={() => setShowWaitlistConfirm(false)}>
            <FaTimes />
          </div>

          <div className='center' style={{fontSize: '30px', color: 'mediumseagreen'}}><FaCheckCircle /></div>

          <h3 className='modal__h2'>
            You have been added to the waitlist.
          </h3>

          <p className='mt-20'>
          You will be notified once this company is ready to host demos.
          </p>
        </div>
      </>
    )}

    {showMaxReached && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowMaxReached(false)}>
            <FaTimes />
          </div>

          <p>
            You have reached your maximum applications per day. Please come back tomorrow to apply for more.
          </p>
        </div>
      </>
    )}

      <div className='browsesellers__footer'>
        <div className='browsesellers__footer-left'>
          <div className='browsesellers__footer-left-h2'>
            Stay updated with the latest software solutions directly in your inbox ✉️
          </div>

          <div className='mt-30'>
            <input type='email' className='input' value={newsletterEmail} onChange={(e) => setNewsletterEmail(e.target.value)} placeholder='Email address' style={{color: "black"}} />
          </div>

          <div className='mt-10'>
            {!newsletterLoading && !newsletterDone && (
              <button className='btn btn--primary' onClick={() => handleNewsletterSubmit()} style={{backgroundColor: "mediumseagreen"}}>Sign up</button>
            )}

            {newsletterLoading && (
              <Spinner />
            )}

            {newsletterDone && !newsletterLoading && (
              <i>Success! You are now signed up</i>
            )}
          </div>
        </div>
      </div>

      {showBooking && (
        <>
          <div className="overlay"></div>
          <BuyerBookingSeller company={selectedSeller} selectedBuyer={selectedSeller} setSelectedSeller={setSelectedSeller} setShowBooking={setShowBooking} setShowConfirm={setShowConfirm} selectedSellers={selectedSellers} setSelectedSellers={setSelectedSellers} />
        </>
      )}

      {showModal && (
        <FadeIn delay={100} duration={100}>
          <JoinModal setShowModal={setShowModal} />
        </FadeIn>
      )}

      {showHasApplied && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={(e) => setShowHasApplied(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              You have previously applied
            </h2>

            <p className='mt-10'>
              It looks like you have previously applied for a demo with this company. We currently only allow 1 application per user per company.
            </p>

            <p className='mt-20 center'>
              <button className='btn' onClick={() => setShowHasApplied(false)}>Back</button>
            </p>
          </div>
        </>
      )}

      {showHasNoCompany && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowHasNoCompany(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>
              Your profile is incomplete.
            </h2>

            <div>
              Please visit your dashboard in order to complete your public profile.
            </div>

            <div className='mt-10 center'>
              <Link className='link' to={'/dashboard/buyer'}>Visit dashboard</Link>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default BrowseSellers;