import React from 'react';
import {
  Box,
  chakra,
  Text,
  Flex,
  Link,
  Heading,
  Image,
  Center,
  VStack,
} from '@chakra-ui/react';

const AppMobile = () => {
  return (
    <>
      <Box px='30px' py='50px'>
        <Center>
          <Heading fontSize='40px' mb='50px' textAlign='center'>
            INTRODUCING THE LEADRPRO MOBILE APP
          </Heading>
        </Center>

        <VStack mb='50px'>
          <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/6.5-inch_Screenshot_2.png?updatedAt=1682703081413' />
          <Text textAlign={'center'} fontSize={'3xl'}>
            For Vendors
          </Text>
          <Text textAlign={'center'} fontSize='25px'>
            An SDR in your pocket!
          </Text>
        </VStack>

        <VStack mb='30px'>
          <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/6.5-inch_Screenshot_1.png?updatedAt=1682703083150' />
          <Text textAlign={'center'} fontSize={'3xl'}>
            For Buyers
          </Text>
          <Text textAlign={'center'} fontSize='25px'>
            Apply to paid demos!
          </Text>
        </VStack>

        <Box mt='80px' mb='50px'>
          <VStack>
            <a
              href='https://apps.apple.com/us/app/leadrpro/id6449265881'
              target='_blank'>
              <Image
                maxW='170px'
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/appsotre.png?updatedAt=1682699351000'
              />
            </a>

            <a
              href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile'
              target='_blank'>
              <Image
                maxW='205px'
                src='https://ik.imagekit.io/slk77uytr/LeadrPro/google-play-badge__3_.png?updatedAt=1682699483535'
              />
            </a>
          </VStack>
        </Box>
      </Box>

      {/* <Box mt={10} mb={32} mx={20}>
        <Center>
          <Flex flexDirection={'row'}>
            <Box>
              <Flex p={50} w='full' alignItems='center' justifyContent='center'>
                <Box
                  w='full'
                  maxW='md'
                  // mx='auto'
                  px={4}
                  py={3}
                  bg='white'
                  shadow='xl'
                  rounded='md'>
                  <Box>
                    <Link href='https://www.leadrpro.com/software'>
                      {' '}
                      <chakra.h1
                        fontSize='lg'
                        fontWeight='bold'
                        mt={2}
                        color='gray.800'>
                        Browse our Software Solutions
                      </chakra.h1>
                    </Link>

                    <chakra.p
                      fontSize='sm'
                      mt={2}
                      color='gray.600'
                      _dark={{
                        color: 'gray.300',
                      }}>
                      Discover the latest innovative software solutions for your
                      business and get paid to participate in live software
                      demos. New vendors added almost every day!
                    </chakra.p>
                  </Box>

                  <Box mt={4}>
                    <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/Screen_Shot_2023-04-28_at_2.20.58_PM.png?updatedAt=1682706090138' />
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex p={50} w='full' alignItems='center' justifyContent='center'>
                <Box
                  w='full'
                  maxW='md'
                  // mx='auto'
                  px={4}
                  py={3}
                  bg='white'
                  shadow='xl'
                  rounded='md'>
                  <Box>
                    <Link href='https://www.leadrpro.com/blog'>
                      {' '}
                      <chakra.h1
                        fontSize='lg'
                        fontWeight='bold'
                        mt={2}
                        color='gray.800'>
                        Check out our Blog
                      </chakra.h1>
                    </Link>

                    <chakra.p
                      fontSize='sm'
                      mt={2}
                      color='gray.600'
                      _dark={{
                        color: 'gray.300',
                      }}>
                      Check out the LeadrPro blog to stay up to date on the
                      latest trends in tech and learn industry secrets!
                    </chakra.p>
                  </Box>

                  <Box mt={4}>
                    <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/Screen_Shot_2023-04-28_at_2.31.30_PM.png?updatedAt=1682707041435' />
                  </Box>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Flex p={50} w='full' alignItems='center' justifyContent='center'>
                <Box
                  w='full'
                  maxW='md'
                  // mx='auto'
                  px={4}
                  py={3}
                  bg='white'
                  shadow='xl'
                  rounded='md'>
                  <Box>
                    <Link href='https://www.leadrpro.com/signup'>
                      {' '}
                      <chakra.h1
                        fontSize='lg'
                        fontWeight='bold'
                        mt={2}
                        color='gray.800'>
                        Create your free account today
                      </chakra.h1>
                    </Link>

                    <chakra.p
                      fontSize='sm'
                      mt={2}
                      color='gray.600'
                      _dark={{
                        color: 'gray.300',
                      }}>
                      Sign up today and start booking demos! Whether you have an
                      innovative software solution you'd like to sell or if
                      you'd like to get paid to discover new software for your
                      business... signing up is fast, free and easy!
                    </chakra.p>
                  </Box>

                  <Box mt={6}>
                    <Image src='https://ik.imagekit.io/slk77uytr/LeadrPro/Screen_Shot_2023-04-28_at_2.34.31_PM.png?updatedAt=1682706914400' />
                  </Box>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Center>
      </Box> */}
    </>
  );
};

export default AppMobile;
