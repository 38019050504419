import './JoinNow.css';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { getUserWaitlist, getSeniorityLevels, createUserJoinNow, searchCompaniesDropdown, 
  createCompanyUser, createCompany, getDepartments, refreshToken, getCompanySizes, saveUserProfile, updateUser
} from '../../utils/api';
import { isAuthed } from '../../services/auth.service';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaCheckCircle, FaRegCheckCircle, FaEllipsisV, FaRegBell, FaTimes } from 'react-icons/fa';
import imgAirbnb from '../../assets/rewards/Airbnb.png';
import imgAmazon from '../../assets/rewards/Amazon.png';
import imgApple from '../../assets/rewards/Apple.png';
import imgBestBuy from '../../assets/rewards/Bestbuy.png';
import imgUberEats from '../../assets/rewards/ubereats.png';
import imgVenmo from '../../assets/rewards/Venmo.png';
import imgZelle from '../../assets/rewards/Zelle.png';
import imgMariott from '../../assets/rewards/Marriott.png';
import imgLpLogo from '../../assets/rewards/lp_logo.png';
import ScrollToTop from '../../components/layout/ScrollToTop';
import imgCoins from '../../assets/rewards/lpcoins.jpg';

function JoinNow() {
  const [loading, setLoading] = useState(false);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  const [userSource, setUserSource] = useState('');
  const [showReferral, setShowReferral] = useState(false);
  const [referrer, setReferrer] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [promoCode, setPromoCode] = useState('');
  const [email, setEmail] = useState("");
  const [currentStep, setCurrentStep] = useState(1);
  const [password, setPassword] = useState('');
    const [optIn, setOptIn] = useState(false);
    const [agree, setAgree] = useState(false);
    const [seniority, setSeniority] = useState([]);
    const [showDropdown, setShowDropdown]= useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [query, setQuery] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [jobTitle, setJobTitle] = useState('');
    const [seniorityId, setSeniorityId] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [companyExists, setCompanyExists] = useState(false);
    const [companyId, setCompanyId] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);
    const [selectedCompanySize, setSelectedCompanySize] = useState(null);
    const [companySizes, setCompanySizes] = useState([]);
    const [showSuccess, setShowSuccess] = useState(false);
    
    const navigate = useNavigate();

    function setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = 'expires=' + d.toUTCString();
      let _domain = 'domain=leadrpro.com';
      document.cookie = cname + '=' + cvalue + ';' + _domain + ';' + expires + ';path=/';
    }

  async function handleSourceChange(val){
    setUserSource(val);

    if (val === 'Referral'){
        setShowReferral(true);
    }
}

function getCookie(cname) {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  useEffect(() => {
    if (isAuthed()){
      navigate('/dashboard/buyer')
    }

    async function loadSeniority(){
      let res = await getSeniorityLevels();

      if (res.success){
        setSeniority(res.data);
      }
    }

    async function loadDepartments() {
      let data = await getDepartments();
      if (data.data) {
        setDepartments(data.data);
      }
    }

    async function loadCompanySizes() {
      let data = await getCompanySizes();
      if (data.data){
          setCompanySizes(data.data);
      }
    }

    loadSeniority();
    loadDepartments();
    loadCompanySizes();
  }, []);

  async function handleSubmit(e){
    e.preventDefault();

    setLoading(true);
    setSubmitting(true);

    let el1 = e.target.elements.email.value;
    let el2 = e.target.elements.optIn.value;

    let channel_token = getCookie('channel_token');
    let partner_key = getCookie('ps_partner_key');
    let click_id = getCookie('ps_xid');
    let l_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (el1.length === 0){
        setHasError(true);
        setSubmitting(false);
        return;
    }

    let data= {email: el1, password: password, optIn: el2, promoCode: promoCode, source: userSource, channel_token, timezone: l_timezone, referrer, partner_key, click_id};
    let res  = await createUserJoinNow(data);

    if (res.success){
      localStorage.setItem('access_token', res.data.accessToken);
      localStorage.setItem('refresh_token', res.data.refreshToken);

      // used for meetings.leadrpro
      setCookie('access_token', res.data.accessToken, 1);
      setCurrentStep(2);
    }
    else{
        setLoading(false);
        setHasError(true);
        setErrorMessage(res.message);
    }

    setSubmitting(false);
}

async function companySearch(query){
  let data = await searchCompaniesDropdown(query);

  if (data.data){
    setCompanies(data.data);
  }
}

      // if no name selected, reactivate
      async function handleChange(e){
        let _name = e.target.value;

        if (_name.length > 0){
          setShowDropdown(true);
          setCompanies([]);
          setSelectedCompany(0);
          setQuery(e.target.value);
          await companySearch(e.target.value);
        }

        else{
          setCompanies([]);
          setShowDropdown(false);
          setSelectedCompany(0);
          setQuery(e.target.value);
        }
      }

      // selecting company from dropdown
      async function handleCompanyClick(id, name){
        setSelectedCompany(id);
        setShowDropdown(false);
        setQuery(name);
        //setShowCompanyDetails(false);
      }

      async function handleSubmit2(e){
        e.preventDefault();

        if (selectedCompany !== 0){
          setSubmitting(true);

          // company exists
          setCompanyExists(true);
          setCompanyId(selectedCompany);

          let res1 = await createCompanyUser(selectedCompany);

          if (res1.status === 403){
            let _refresh = await refreshToken();

            if (_refresh.success){
              localStorage.setItem('access_token', _refresh.data.accessToken);
              localStorage.setItem('refresh_token', _refresh.data.refreshToken);

              let res1 = await createCompanyUser(selectedCompany);
            }
          }

          await updateUserInfo();

          setSubmitting(false);
          setShowSuccess(true);
        }

        else if(query.trim().length > 0){
          setSubmitting(true);

          // new company
          setCompanyExists(false);
          let company_size_id = selectedCompanySize
          let industry_id = 159;
          let result = await createCompany(query, company_size_id, industry_id, '', '');

          if (result.success){
            setCompanyId(result.data);
          }

          else if (result.status === 403){
            let _refresh = await refreshToken();

            if (_refresh.success){
              localStorage.setItem('access_token', _refresh.data.accessToken);
              localStorage.setItem('refresh_token', _refresh.data.refreshToken);

              let result2 = await createCompany(query, company_size_id, industry_id, '', '');

              if (result2.success){
                setCompanyId(result2.data);
              }
            }
          }

          await updateUserInfo();
          setShowSuccess(true);
        }

        else{
          setSubmitting(false);
          setHasError(true);
          setErrorMessage("Must select a valid company name");
          return;
        }
    }

    async function updateUserInfo(){

      let res = await saveUserProfile(jobTitle, seniorityId , selectedDepartment, '');

      if (res.success){
        await updateUser({first_name: firstName, last_name: lastName});

        setSubmitting(false);
      }
    }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Join Now</title>
      </Helmet>

      <ScrollToTop />

    {currentStep == 1 && (
      <div className='joinnow__card'>
        <div className='joinnow__left'>
          <div className='joinnow__left-logo'>
            <img src={imgLpLogo} alt='aa' />
          </div>

          <div className='joinnow__left-header'>
            Welcome to LeadrPro
          </div>

          <div>
            Discover the latest software innovations.
          </div>

          <form onSubmit={handleSubmit}>
          <div className='joinnow__form'>
            <div className='joinnow__form-group'>
              <label>Email</label>
              <div>
                <input name='email' type='email' className='input' required placeholder='Email address' />
              </div>
            </div>

            <div className='joinnow__form-group'>
              <label>Password</label>
              <div>
                <input name='password' type='password' className='input' required placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} />
              </div>
            </div>

            <div className='joinnow__form-password-help'>
              <div>
                Use 6 or more characters
              </div>

              <div>
                One special character
              </div>

              <div>
                One number
              </div>
            </div>

            <div className='form-group'>
                <label htmlFor='txtPromo'>Promo code (optional)</label>
                <input type='text' className='input' id='txtPromo' value={promoCode} onChange={(e) => setPromoCode(e.target.value)} placeholder='Code' />
            </div>

            <div className='form-group'>
                <label htmlFor='selectSource'>How did you hear about us?</label>
                <select className='input select' id='selectSource' onChange={(e) => handleSourceChange(e.target.value)}>
                    <option value='0'>Select option...</option>
                    <option value='ProductHunt'>Product Hunt</option>
                    <option value='LinkedIn'>LinkedIn</option>
                    <option value='Facebook'>Facebook</option>
                    <option value='Google'>Google</option>
                    <option value='Email'>Email</option>
                    <option value='Ad'>Ad</option>
                    <option value='LeadrProBlog'>LeadrPro Blog</option>
                    <option value='News Article'>Article</option>
                    <option value='Webinar'>Attended Webinar</option>
                    <option value='Referral'>Referral</option>
                    <option value='Other'>Other</option>
                </select>
            </div>

            {showReferral && (
                <>
                    <div className='form-group'>
                        <label>Name and company of peson who referred you</label>
                        <input type='text' className='input' placeholder='Referrer' value={referrer} onChange={(e) => setReferrer(e.target.value)}/>
                    </div>
                </>
            )}

            <div className='form-group'>
                <input type='checkbox' id="radioOptIn" name="optIn" checked={optIn} value={optIn} onChange={() => setOptIn(!optIn)} />

                <label className='createaccount__radio-label label' htmlFor='radioOptIn'>Yes, please keep me updated on news, events and offers
                </label>
            </div>

            <div className='joinnow__form-disclaimer'>
              By creating an account, you agree to the <Link to='/terms' className='link'>Terms of Use</Link> and <Link to='/privacy' className='link'>Privacy Policy</Link>.
            </div>

            {hasError === true && (
                <div className="form-group">
                    <span className="label--error">{errorMessage}</span>
                </div>
            )}

            <div className='joinnow__form-btn'>
              {submitting == false && (
                <button className='btn btn--primary'>Create account</button>
              )}

              {submitting && (
                <Spinner />
              )}
            </div>

            <div className='mt-10'>
              Already have an account? <Link to='/login' className='link'>Sign in</Link>
            </div>

            <div className='mt-10'>
              Need to join as a software vendor? <Link className='link' to='/signup/seller'>Sign up here</Link>
            </div>
          </div>
          </form>
        </div>

        <div className='joinnow__right'>
          <div className='joinnow__right-header'>
            Meet Vendors. <span>Earn Rewards.</span>
          </div>

          <div className='mt-20 joinnow__features'>
            <div>
              <FaRegCheckCircle /><span className='ml-10'>Discover new solutions for your business</span>
            </div>

            <div>
              <FaRegCheckCircle /><span className='ml-10'>Apply for a quick 30-minute demo.</span>
            </div>

            <div>
              <FaRegCheckCircle /><span className='ml-10'>Get approved and earn rewards.</span>
            </div>
          </div>

          <div className='joinnow__right-images mt-30'>
            <div className='joinnow__right-image'>
              <img src={imgAmazon} alt='' />
            </div>

            <div className='joinnow__right-image'>
              <img src={imgUberEats} alt='' />
            </div>

            <div className='joinnow__right-image'>
              <img src={imgAirbnb} alt='' />
            </div>

            <div className='joinnow__right-image'>
              <img src={imgBestBuy} alt='' />
            </div>

            <div className='joinnow__right-image'>
              <img src={imgMariott} alt='' />
            </div>

            <div className='joinnow__right-image'>
              <img src={imgVenmo} alt='' />
            </div>

            <div className='joinnow__right-image'>
              <img src={imgApple} alt='' />
            </div>

            <div className='joinnow__right-image'>
              <img src={imgZelle} alt='' />
            </div>
          </div>
        </div>
      </div>
    )}

      {currentStep == 2 && (
        <FadeIn delay={300} duration={300}>
      <div className='joinnow__card'>
        <div className='joinnow__left'>
          <div className='joinnow__left-header'>
            Personal Profile
          </div>

          <form onSubmit={handleSubmit2}>
          <div className='joinnow__form'>
            <div class='joinnow__form-row'>
              <div>
                <label>First name</label>
                <input type='text' className='input' required placeholder='First name' value={firstName} onChange={(e) => setFirstName(e.target.value)} />
              </div>

              <div>
                <label>Last name</label>
                <input type='text' className='input' required placeholder='Last name' value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </div>
            </div>

            <div className='joinnow__form-row'>
              <div>
                <label>Job Title</label>
                <input type='text' className='input' required placeholder='Job title' value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} />
              </div>

              <div>
                <label>Seniority</label>
                <div>
                  <select className='input' value={seniorityId} required onChange={(e) => setSeniorityId(e.target.value)}>
                    <option>Choose one</option>

                    {seniority.map(item => (
                      <option value={item.seniority_level_id}>{item.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className='joinnow__form-row'>
              <div>
                <label>Department</label>
                <select id='selectDepartment' className='input' value={selectedDepartment} required onChange={(e) => setSelectedDepartment(e.target.value)}>
                  {departments.map((item, index) => (
                    <>
                      <option key={index} value={item.department_id}>{item.name}</option>
                    </>
                  ))}
              </select>
              </div>

              <div>
                <label>Company Size</label>
                <select
                  className="input"
                  type='text'
                  id='selectSize'
                  value={selectedCompanySize}
                  onChange={(e) => setSelectedCompanySize(e.target.value)}
                  name='companysize'
                  required>
                  {companySizes.length > 0 && (
                      companySizes.map((item, index) => (
                          <option key={index} value={item.company_size_id}>
                              {item.value}
                          </option>
                      ))
                  )}
              </select>
              </div>
            </div>

            <div>
            <div style={{position: 'relative'}}>
                <label>Company Name</label>
                <div className=''>
                <input type="text" id='txtName' className="input" onChange={handleChange} placeholder="Enter here" value={query} autoComplete='off' required></input>

                {companies.length > 0 && showDropdown && (
              <div className="selectrole__company-dropdown-wrapper">
              <div className="selectrole__company-dropdown">
                  {companies.map((item, index) => (
                      <div key={index} onClick={() => handleCompanyClick(item.company_id, item.name)} className="selectrole__company-dropdown-item" id={"company_" + item.company_id}>
                        <span style={{height: "30px", width: "30px", overflow: "none", borderRadius: "50%", display: "inline-block", verticalAlign: "middle"}}>
                          <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} style={{width: "100%"}} />
                        </span>
                        <span style={{marginLeft: "10px", verticalAlign: "middle", width: "80%"}}>{item.name}</span>
                      </div>
                  ))}
                  </div>
              </div>
              )}
              </div>
              </div>
            </div>

            <div className='joinnow__form-btn mt-20'>
              {submitting == false && showSuccess == false && (
                <button className='btn btn--primary'>Submit</button>
              )}

              {submitting && (
                <Spinner />
              )}
            </div>
          </div>
          </form>
        </div>

        <div className='joinnow__right'>
          <div className='joinnow__right-header'>
            Enjoy premium access with your membership benefits: 
          </div>

          <div className='mt-30'>
            <div>
              <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12.5" r="11" stroke="#A238DA" stroke-width="2"/>
              <path d="M16.6663 9L10.2497 15.4167L7.33301 12.5" stroke="#A238DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span> <span className='ml-10'>Access to latest technologies</span>
            </div>

            <div className='mt-10'>
              <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12.5" r="11" stroke="#A238DA" stroke-width="2"/>
              <path d="M16.6663 9L10.2497 15.4167L7.33301 12.5" stroke="#A238DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span> <span className='ml-10'>Attend private networking events and dinners</span>
            </div>

            <div className='mt-10'>
              <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12.5" r="11" stroke="#A238DA" stroke-width="2"/>
              <path d="M16.6663 9L10.2497 15.4167L7.33301 12.5" stroke="#A238DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span> <span className='ml-10'>Thought leadership content</span>
            </div>

            <div className='mt-10'>
              <span><svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="12" cy="12.5" r="11" stroke="#A238DA" stroke-width="2"/>
              <path d="M16.6663 9L10.2497 15.4167L7.33301 12.5" stroke="#A238DA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              </span> <span className='ml-10'>Network with industry experts</span>
            </div>
          </div>
        </div>
        </div>
        </FadeIn>
      )}

      {showSuccess && (
        <FadeIn delay={300} duration={300}>
          <>
            <div className='overlay'></div>
            <div className='modal center joinnow__modal-success'>
              <div className='center'>
                <img src={imgCoins} alt='' style={{height: '160px', borderRadius: '10px'}} />
              </div>

              <div className='joinnow__modal-success-header mt-20'>
                Congratulations!<br/>
                You have earned <span>+1 points!</span>
              </div>

              <div className='mt-10'>
                Start exploring new software solutions and earn more points today!
              </div>

              <div className='mt-20'>
                <button className='btn btn--primary' onClick={() => window.location = '/dashboard/buyer'}>
                  Start exploring
                </button>
              </div>
            </div>
          </>
        </FadeIn>
      )}
    </>
  );
}

export default JoinNow;